html.site--wvhg {
  --siteheader-search-borderradius: 8px;
  --menubar-level1-openbyhover: true;
  --menubar-level1-font: bold 20px/26px "TypoPRO Dosis Menu", "Dosis", Arial;
  --menubar-level1-padding-left: 15px;
  --menubar-level1-padding-right: 15px;
  --menubar-level1-spacing: 0px;
  --menubar-level1-spacing-min: 0px;
  --menubar-bar-item-color: #FFFFFF;
  --menubar-bar-item-decoration: none;
  --menubar-bar-item-border-radius: 8px;
  --menubar-bar-item-hover-background: #FFFFFF;
  --menubar-bar-item-hover-color: var(--color-purple);
  --menubar-bar-item-hover-decoration: none;
  --menubar-bar-item-selected-color: #FFFFFF;
  --menubar-bar-item-selected-hover-color: var(--color-purple);
  --menubar-bar-toggler-width: auto;
  --menubar-bar-toggler-margin: 6px;
  --menubar-bar-toggler-extrahitarea: 9px;
  --menubar-bar-toggler-shifty: 6px;
  --menubar-bar-toggler-font: var(--fontawesome-solid);
  --menubar-bar-toggler-size: 12px;
  --menubar-bar-toggler-content: var(--fa-chevron-down);
  --menubar-pulldown-zindex: 10;
  --menubar-pulldown-minwidth: 200px;
  --menubar-pulldown-maxwidth: 250px;
  --menubar-pulldown-border: none;
  --menubar-pulldown-border-radius: 8px;
  --menubar-pulldown-padding: 5px 0 12px 0;
  --menubar-pulldown-bgcolor: #FFFFFF;
  --menubar-pulldown-box-shadow: 1px 3px 3px rgb(0 0 0 / 25%);
  --menubar-pulldown-itemhover-bgcolor: #F1F1F1;
  --menubar-pulldown-itemhover-textcolor: var(--color-purple);
  --menubar-pulldown-itemhover-textdecoration: none;
  --menubar-level2-color: var(--color-purple);
  --menubar-level2-font: 600 18px/26px "TypoPRO Dosis", "Dosis", Arial;
  --menubar-level2-padding-left: 15px;
  --menubar-level2-padding-right: 15px;
  --menubar-level2-padding-top: 6px;
  --menubar-level2-padding-bottom: 6px;
  --menubar-level2-expandicon: var(--fa-chevron-right);
  --menubar-level2-collapseicon: var(--fa-chevron-down);
  --menubar-level2-toggle-font: 900 13px "Font Awesome 5 Pro"; /* solid */
  --menubar-level3-color: var(--color-purple);
  --menubar-level3-font: 600 17px/20px "TypoPRO Dosis", "Dosis", Arial;
  --menubar-level3-padding-left: 30px;
  --menubar-level3-padding-right: 15px;
  --menubar-level3-padding-top: 5px;
  --menubar-level3-padding-bottom: 5px;
  --menubar-level3-expandicon: var(--fa-plus);
  --menubar-level3-collapseicon: var(--fa-minus);
  --menubar-level3-toggle-font: 400 13px "Font Awesome 5 Pro"; /* regular */
  --menubar-level4-color: var(--color-purple);
  --menubar-level4-font: 400 17px/20px "TypoPRO Dosis", "Dosis", Arial;
  --menubar-level4-padding-left: 35px;
  --menubar-level4-padding-right: 15px;
  --menubar-level4-padding-top: 5px;
  --menubar-level4-padding-bottom: 5px;
}
html.site--wvhg .spc-menubar-branch--horizontal {
  height: 100%;
}
html.site--wvhg .spc-menubar-branch--horizontal > li > .spc-menubar__item {
  margin-top: 10px;
  height: calc(100% - 20px);
}