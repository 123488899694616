.tippy-content {
  padding: 15px;
  word-break: break-word; /* in case of very long words/urls or stray non-breaking spaces make sure the line still wraps instead of overflowing the tooltip */
}

.tippy-content.tippy-content p.normal,
.tippy-content.tippy-content ul.unordered,
.tippy-content.tippy-content ol.unordered {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 150%;
}

.tippy-content.tippy-content ul.unordered,
.tippy-content.tippy-content ol.unordered {
  margin-top: 0;
}

.tippy-content.tippy-content ul.unordered > li + li,
.tippy-content.tippy-content ol.unordered > li + li {
  margin-top: 0;
}