/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
.widget--lesmateriaalzoeken .checkboxlist__option > input {
  margin-right: 10px;
}

.widget--lesmateriaalzoeken .checkboxlist__option > input + label {
  padding-top: 8px;
  padding-bottom: 8px;
}

.widget--lesmateriaalzoeken {
  margin-top: 30px;
  background: var(--widget-highlight-background);
  border: 1px solid var(--widget-border-color);
  border-radius: var(--widget-border-radius);
  box-shadow: var(--widget-box-shadow);
  --formcontrol-checkradio-bordercolor: #898989; /* ensure good contrast against #F0F9F1 (3.3:1) */
  padding: 32px 20px;
}

.widget--lesmateriaalzoeken .widget__title {
  font: bold 32px var(--rtd-heading-fontfamily);
}

.widget--lesmateriaalzoeken h3 {
  margin-bottom: 10px;
}

@media (min-width: 450px) {
  .lesmzoeken__options {
    display: flex;
  }
  .lesmzoeken__options__column2 {
    margin-left: 48px;
  }
}
.lesmzoeken__filterbar__querycontainer {
  margin-top: 25px;
  position: relative;
}

.lesmzoeken__filterbar__submit {
  display: inline-flex;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: var(--button-height);
  padding: 5px 20px 5px 20px;
  font: var(--button-font);
  border-radius: var(--button-borderradius);
  cursor: pointer;
  background-color: var(--button-color);
  color: #FFFFFF;
  align-self: stretch;
  outline-offset: var(--actionbutton-outline-offset);
}
.lesmzoeken__filterbar__submit:hover {
  background-color: var(--button-color-hover);
}

.lesmzoeken__filterbar__submit {
  flex: none;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: var(--button-height);
  padding: 5px 20px 5px 20px;
  font: var(--button-font);
  border-radius: var(--button-borderradius);
  cursor: pointer;
  background-color: var(--button-color);
  color: #FFFFFF;
}
.lesmzoeken__filterbar__submit:hover {
  background-color: var(--button-color-hover);
}

.lesmzoeken__filterbar__querycontainer {
  flex: 1 0 auto;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #9E9E9E;
  border-radius: 4px;
}
.lesmzoeken__filterbar__querycontainer .wh-autocomplete-container {
  width: 100%;
}

.lesmzoeken__filterbar__query.lesmzoeken__filterbar__query {
  flex: 1 0 0px;
  min-width: 0;
  padding-left: 15px;
  height: 41px;
  border: 0;
  font: 16px var(--rtd-alternative-fontfamily);
  color: #333333;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0px !important;
}

.lesmzoeken__filterbar__submit {
  padding-left: 17px;
  padding-right: 17px;
  height: 41px;
  border-radius: 0;
  cursor: pointer;
}

.lesmzoeken__filterbar__submit .fa-search {
  margin-left: 14px;
  font-size: 20px;
}

@media (max-width: 450px) {
  .lesmzoeken__filterbar__query.lesmzoeken__filterbar__query {
    padding-left: 12px;
    padding-right: 0px;
  }
  .lesmzoeken__filterbar__query::placeholder {
    font-size: 14px;
  }
  .lesmzoeken__filterbar__submit.lesmzoeken__filterbar__submit {
    padding: 0 10px;
  }
}
@media (max-width: 350px) {
  .lesmzoeken__filterbar__submit .buttonlabel {
    display: none;
  }
  .lesmzoeken__filterbar__submit .fa-search {
    margin-left: 0;
  }
}