html
{
  --formcontrol-buttonbar-border-radius: 5px;

  --formcontrol-buttonbar-item-height:     35px;
  /*--formcontrol-buttonbar-item-height:     var(--formcontrols-height);*/
  --formcontrol-buttonbar-divider-color:               #CCCCCC;
  --formcontrol-buttonbar-divider-selecteditems-color: #FFFFFF;

  --formcontrol-buttonbar-item-background:          #FFFFFF;
  --formcontrol-buttonbar-item-color:               #000000;

  --formcontrol-buttonbar-item-selected-background: var(--color-green);
  --formcontrol-buttonbar-item-selected-color:      #FFFFFF;
}
