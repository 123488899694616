/*********************************************

Solid (green or purple) widgets grid

*********************************************/

/* widgets in the (masonry) grid below the main content
   with a background color (green or purple)
*/
.widgetsgrid--solid
{
/* @include shadowbox;*/
  border-radius: 8px;
  padding: 30px 15px 25px 15px;
  position: relative;

/*   --widget-box-shadow: 0px 1px 3px rgba(0,0,0,0.60);*/
  --widget-box-shadow: none;

  color: #FFFFFF;
}

.widgetsgrid--green
{
  background-color: var(--color-green-large);
}

.widgetsgrid--purple
{
  background-color: var(--color-purple);
}

.widgetsgrid--solid::before
{
  content:  "";

  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -27px;
  width: 0;
  height: 0;
  border-top: 14px solid #fff;
  border-right: 27px solid transparent;
  border-left: 27px solid transparent;
}

.widgetsgrid--solid .widget--widgetsgroup__title
{
  color: #FFFFFF;
}

/* override definitions such as .tc-border-magenta.tc-border-magenta */
.widgetsgrid--solid .widget--colorborder
{
  border: 0;
}

/* Prevent the used border colors being the same as the widgetsgrid's background color.
   If they are the same whe'll use a white border/outline.
*/
  .widgetsgrid--green .widget--image.widget--theme-green .widget--image__image
, .widgetsgrid--green .widget--image.widget--theme-green .widget--image__title
/*, .widgetsgrid--green  .widget--image__title.widget--image__title*/
, html.site--wvhg .widget--image.widget--theme-currenttheme .widget--image__title
, .widgetsgrid--purple .widget--image.widget--theme-purple .widget--image__image
, .widgetsgrid--purple .widget--image.widget--theme-purple .widget--image__title
{
  border: 1px solid rgba(255,255,255,0.5);
}
