.embeddedobject.widget--highlight {
  display: block;
  color: var(--widget-highlight-textcolor);
  background-color: var(--widget-highlight-background);
  padding: var(--widget-highlight-pad-v) var(--widget-highlight-pad-h) var(--widget-highlight-pad-v) var(--widget-highlight-pad-h);
  border: 2px solid var(--widget-highlight-border-color);
  border-radius: var(--widget-border-radius);
}

.widget--highlight__title.widget--highlight__title {
  display: flex;
  align-items: center;
  color: var(--widget-highlight-title-color);
  margin-top: 0;
  margin-bottom: 15px;
}

.widget--highlight__title::before {
  font: var(--fontawesome-regular);
  content: var(--fa-info-circle);
  font-size: 30px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
}