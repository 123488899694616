/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
html.styling2025 .sitefooter--gl .sitefooter__part2 {
  padding-top: 30px;
  padding-bottom: 30px;
}
html.styling2025 .sitefooter--gl .sitefooter__social {
  width: 100%;
  max-width: var(--pagegrid-contentarea-width-full);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  --footer-heading-color: var(--themecolor);
}
html.styling2025 .sitefooter--gl .sitefooter__social .sitefooter__heading {
  margin-top: 0;
  margin-bottom: 0;
}
html.styling2025 .sitefooter--gl .sitefooter__socialitem .fab.fa-twitter {
  font-size: 15px;
}
html.styling2025 .sitefooter--gl .sitefooter__socialitem .fab.fa-facebook {
  font-size: 16px;
}
html.styling2025 .sitefooter--gl .sitefooter__socialitem .fab.fa-linkedin-in {
  font-size: 16px;
}
html.styling2025 .sitefooter--gl .sitefooter__socialitem .fab.fa-youtube {
  font-size: 18px;
}
html.styling2025 .sitefooter--gl .sitefooter__socialitem .fab.fa-instagram {
  font-size: 21px;
}
html.styling2025 .sitefooter--gl .sitefooter__bottombar {
  margin-top: 25px;
  width: 100%;
  max-width: var(--pagegrid-contentarea-width-full);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
}
html.styling2025 .sitefooter--gl .sitefooter__bottombar {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  column-gap: 15px;
}
html.styling2025 .sitefooter--gl .sitefooter__bottombar__copyright {
  color: var(--color-aagrey);
  white-space: nowrap;
}
html.styling2025 .sitefooter--gl .sitefooter__bottomlinks {
  position: relative; /* so we can absolute position the seperator (::before) */
  content: "-";
}
html.styling2025 .sitefooter--gl .sitefooter__bottombar__copyright, html.styling2025 .sitefooter--gl .sitefooter__bottomlinks__items {
  font: 13px var(--rtd-text-fontfamily);
}
html.styling2025 .sitefooter--gl .sitefooter__bottomlinks__items a {
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  white-space: nowrap;
}
html.styling2025 .sitefooter--gl .sitefooter__bottomlinks a {
  padding-left: 6px;
  padding-right: 6px;
  color: var(--color-aagrey);
  text-decoration: none;
}
html.styling2025 .sitefooter--gl .sitefooter__bottomlinks a:hover {
  text-decoration: underline;
  text-underline-offset: 3px;
  color: var(--themecolor);
}
html.styling2025 .sitefooter--gl .sitefooter__bottomlinks .seperator {
  display: none;
}