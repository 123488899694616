.widget--highlightbig
{
  display: flex;

  position: relative;
  border-radius: var(--widget-border-radius);
}

.widget--highlightbig--filled
{
  --rtd-text-color: #FFFFFF;
  --rtd-link-color: #FFFFFF;

  --widget-title-color: #FFFFFF;
}

.widget--highlightbig--filled .ctalink
{
  color: #FFFFFF;
}

.widget--highlightbig .ctalink::before
{
  font: var(--fontawesome-regular);
  content: var(--fa-chevron-right);
  margin-right: 8px;
}

.widget--highlightbig--outline
{
  border-width: 2px;
  border-style: solid;
}

.widget--highlightbig .widget__title
{
  margin-bottom: 10px;
}

.widget--highlightbig__imagecontainer
{
  overflow: hidden;
  max-width: 40%;
}

.widget--highlightbig--overscan .widget--highlightbig__image
{
  margin-top: -20px;
  margin-bottom: -20px;
}

.widget--highlightbig--overscan .widget--highlightbig__imagecontainer
{
  /* allow some vertical overflow,
     because we must prevent page overflow by cutting of overflow at the right.
     (but that'll also cut vertically)
  */
  margin-top: -20px;
  margin-bottom: -20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.widget--highlightbig__body
{
  flex: 1;
  padding: var(--widget-padding-top) var(--widget-padding-h) var(--widget-padding-bottom) var(--widget-padding-h);
}
