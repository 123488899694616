.widget--newlinks
{
  margin-top: 30px;
}


/*
.widget--newlinks--ctalist
{
*/
  .widget--newlinks--ctalist ul
  {
    margin: 0;
    padding: 0;
    list-style: none;
    border-top: 1px solid #DADADA;
  }

  .widget--newlinks--ctalist li
  {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #DADADA;

    padding-top: 14px;
    padding-bottom: 14px;

    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content min-content;
  }

  .widget--newlinks--ctalist li::before
  {
    grid-column: 1;
    grid-row: 1 / span 2;

    // @include fontawesome-solid;
    font: var(--fontawesome-solid);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    font-size: 32px;
    content: var(--fa-arrow-circle-right);
    color: var(--widget-links-icon-color);

    margin-right: 10px;
  }

  .widget--newlinks--ctalist .widget--newlinks__item
  {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  .widget--newlinks--ctalist .widget--newlinks__item__title
  {
    grid-column: 2;
    grid-row: 1;

    display: table;
    text-decoration: none;
    font: bold 20px/26px var(--rtd-heading-fontfamily);
    color: var(--widget-links-title-color);
  }

  .widget--newlinks--ctalist .widget--newlinks__item__description.rtdcontent
  {
    grid-column: 2;
    grid-row: 2;
  }
/*
}
*/


/*
.widget--newlinks--flatlist
{
*/
  .widget--newlinks--flatlist ul
  {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .widget--newlinks--flatlist li
  {
    margin: 0;
    padding: 2px 0;

    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content min-content;
  }

  .widget--newlinks--flatlist li::before
  {
    grid-column: 1;
    grid-row: 1 / span 2;
    align-self: baseline;

    /* @include fontawesome-solid; */
    font: var(--fontawesome-solid);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    content: var(--fa-chevron-right);
    font-size: 13px;
    color: var(--widget-links-icon-color);
    margin-right: 7px;
  }

  .widget--newlinks--flatlist .widget--newlinks__item__title
  {
    grid-column: 2;
    grid-row: 1;
    align-self: baseline;
  }

  .widget--newlinks--flatlist .widget--newlinks__item__description.rtdcontent
  {
    grid-column: 2;
    grid-row: 2;
  }
/*
}
*/
