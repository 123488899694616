html
{
  --theme-color: var(--wig-green-dark);
  --themecolor: var(--wig-green-dark);
  --themecolor-hover: #004400;
  --themecolor-transparent: var(--wig-green-normal-transparent);

  --themecolor-highlight-background: var(--wig-green-tint);
  --themecolor-on-highlightbg: var(--wig-green-dark);
}

html.pagetheme-wig
{
  --color-primary: var(--mbo-purple-dark);
/*  --color-secondary: var(--wig-green-);*/
}

/* Recalculate CSS variables after pagetheme is applied */
  html.pagetheme--mbo
, html.pagetheme--po
, html.pagetheme--vo
, html.page--eventsoverview
, html.page--newsoverview
, html.page--event
, html.page--news
, .sitefooter
/*, html.pagetheme--wig*/
, [class^="widgettheme--"]
, [class*="widgettheme--"]
{
  --rtd-heading-color: var(--themecolor);
  --rtd-link-color: var(--themecolor);
  --rtd-link-hover-color: var(--themecolor-hover);
  --component-navpath-link-color: var(--rtd-link-color);
  --webreader-themecolor: var(--rtd-link-color);

  --feedbackbar-tag-border: 1px solid var(--themecolor);
  --feedbackbar-tag-hover-border: 1px solid var(--themecolor);
  --feedbackbar-tag-hover-text-color: var(--themecolor);

  --formcontrols-border-color-focus: var(--themecolor);
  --formcontrols-focus-outline:      2px solid var(--themecolor);



  /* Heading colors - purple default */
  --widget-title-color: var(--themecolor); /* purple */
  --widget-tableofcontents-header-color: var(--themecolor); /* green-large-on-light-background */

  /* Widget with border
     - Highlight widget
     - Lesmateriaal zoeken
     - Downloads / Rapportage
  */
  --widget-border-highlighted-color: var(--themecolor); /* widget to attract attention (or some focus effect?) */
  /*
  --color-green:            #1E8527;
  --color-green-large:      #1E8527; // 4.7:1
  --color-green-small:      #218729; // 4.6:1 - WIG/GL/WVHG - For use with small fonts on white or a dark background with white text
  */
  /* Item colors - green-small by default */
  --widget-download-title-color: var(--themecolor); /* green-small */
  --widget-download-icon-color: var(--themecolor); /* green-small */
  --widget-checklist-number-color: var(--themecolor); /* green-small */
  --widget-checklist-item-color: var(--themecolor); /* purple */


  --widget-highlight-background: var(--themecolor-highlight-background);
/*  --widget-highlight-border-color: var(--widget-border-highlighted-color);*/
  --widget-highlight-border-color: var(--wig-green-tint-border);

  --widget-highlight-textcolor: #333333;
  --widget-highlight-title-color: var(--themecolor-on-highlightbg);

  --widget-links-title-color: var(--themecolor);
  --widget-links-icon-color: var(--themecolor);

  --widget-tableofcontents-background: var(--themecolor-highlight-background);

  --widget-quote-name-color: var(--themecolor);
}

/* override telefoonnummer/email links naar groen bij agenda- en nieuws-overzicht pagina */
  .page--eventsoverview .personwidget__contactinfo
, .page--newsoverview .personwidget__contactinfo
{
  --themecolor: var(--wig-green-button-color);
  --rtd-link-color: var(--wig-green-button-color);
  --rtd-link-hover-color: var(--wig-green-button-hover-color);
}

/* Brown/yellow */
html.pagetheme--po
{
  --themecolor: var(--po-brown);
  --themecolor-hover: var(--po-brown-button-hover-color);
  --themecolor-highlight-background: var(--po-yellow-tint);
  --themecolor-on-highlightbg: var(--po-brown);

  --widget-highlight-border-color: var(--po-yellow-tint-border);

  --themecolor-transparent: var(--po-brown-transparent);

  --button-color: var(--po-brown-button-color);
  --button-color-hover: var(--po-brown-button-hover-color);
  --widget-ctabutton-background: var(--themecolor);
  --widget-ctabutton-hover-background: var(--po-brown-dark);
}

/* Blauw */
html.pagetheme--vo
{
  --themecolor: var(--vo-blue-dark);
  --themecolor-hover: var(--vo-blue-button-hover-color); /* FIXME: only used for pagination buttons */
  --themecolor-highlight-background: var(--vo-blue-tint);
  --themecolor-on-highlightbg: var(--vo-blue-dark);

  --widget-highlight-border-color: var(--vo-blue-tint-border);

  --themecolor-transparent: var(--vo-blue-dark-transparent);

  --button-color: var(--vo-blue-button-color);
  --button-color-hover: var(--vo-blue-button-hover-color);
  --widget-ctabutton-background: var(--vo-blue-button-color);
  --widget-ctabutton-hover-background: var(--vo-blue-button-hover-color);
}

/* NOTE: places where we assign the themecolor to another CSS variable
         must also match on elements which define these .widgettheme classes.
         (also see /webdesigns/shared/components/widgets/widget-itemslist.scss for an example)

Paars
*/
  html.pagetheme--mbo
, html.page--eventsoverview
, html.site--geldlessen.page--event
, html.site--geldlessen.page--news
, html.page--newsoverview
, .widgettheme--purple
{
  --widget-title-color: var(--mbo-purple-dark);

  --themecolor: var(--mbo-purple-button-color);
  --themecolor-hover: var(--mbo-purple-button-hover-color);
  --themecolor-highlight-background: var(--mbo-purple-tint);
  --themecolor-on-highlightbg: var(--mbo-purple-dark);

  --widget-highlight-border-color: var(--mbo-purple-tint-border);

  --themecolor-transparent: var(--mbo-purple-dark-transparent);

  --button-color: var(--mbo-purple-button-color);
  --button-color-hover: var(--mbo-purple-button-hover-color);
  --widget-ctabutton-background: var(--mbo-purple-button-color);
  --widget-ctabutton-hover-background: var(--mbo-purple-button-hover-color);
}

html.page--newsoverview .pageheader__content
{
  --rtd-heading-color: var(--wig-green-normal);

  --button-color: var(--wig-green-button-color);
  --button-color-hover: var(--wig-green-button-hover-color);

  --themecolor: var(--wig-green-normal);
}

/*html.pagetheme--wig*/
/* Green */
  .sitefooter
, .widgettheme--green
{
  --widget-title-color: var(--wig-green-dark);

  --themecolor: var(--wig-green-dark);
  --themecolor-hover: var(--wig-green-button-hover-color);

  --themecolor-highlight-background: var(--wig-green-tint);
  --themecolor-on-highlightbg: var(--wig-green-dark);

  --widget-highlight-border-color: var(--wig-green-tint-border);

  --themecolor-transparent: var(--wig-green-dark-transparent);

  --button-color: var(--wig-green-button-color);
  --button-color-hover: var(--wig-green-button-hover-color);
  --widget-ctabutton-background: var(--wig-green-button-color);
  --widget-ctabutton-hover-background: var(--wig-green-button-hover-color);
}



.widget--theme-green
{
  --widget-themecolor-bg: var(--wig-green-dark); /* was --color-green */
  --widget-themecolor-text-color: #FFFFFF;
}

.widget--theme-magenta
{
  --widget-themecolor-bg: var(--color-magenta);
  --widget-themecolor-text-color: #FFFFFF;
}

.widget--theme-purple
{
  --widget-themecolor-bg: #671e77;
  --widget-themecolor-text-color: #FFFFFF;
}

.widget--theme-yellow
{
  --widget-themecolor-bg: #fec62e;
  --widget-themecolor-text-color: #111111;
}

/*.widget--theme-currenttheme*/
html
{
  --widget-themecolor-bg: var(--themecolor);
  --widget-themecolor-text-color: #FFFFFF;
}

/* Because we cannot use
   - CSS variables  (The RTD cannot handle these and will strip them +  Edge < 15(?) don't support these yet)
   - SASS variables (The RTD doesn't understand SASS)
   - SASS mixins    (The RTD doesn't understand SASS)
   We will have to use CSS classes to set specific themecolors.
*/

/* ThemeColor - ForeGround */
/* double class to be more specific than .widget__title (without directly resorting to !important) */
.tc-fg-green.tc-fg-green     { color: var(--color-green); }
.tc-fg-magenta.tc-fg-magenta { color: var(--color-magenta) }
.tc-fg-purple.tc-fg-purple   { color: #671e77; }
.tc-fg-yellow.tc-fg-yellow   { color: #fec62e; }

/* ThemeColor - BackGround */
.tc-bg-green.tc-bg-green     { background: var(--color-green-large); }
.tc-bg-magenta.tc-bg-magenta { background: var(--color-magenta) }
.tc-bg-purple.tc-bg-purple   { background: #671e77; }
.tc-bg-yellow.tc-bg-yellow   { background: #fec62e; }

/* double class to be more specific than the widget's default border */
.tc-border-green.tc-border-green     { border-color: var(--color-green); }
.tc-border-magenta.tc-border-magenta { border-color: var(--color-magenta) }
.tc-border-purple.tc-border-purple   { border-color: #671e77; }
.tc-border-yellow.tc-border-yellow   { border-color: #fec62e; }
