.fsslideshow__button > svg,
.fsslideshow__button > svg {
  fill: var(--fsslideshow-icon-color) !important;
}

.fsslideshow__button:hover > svg {
  fill: #FFFFFF !important;
}

.fsslideshow__title {
  color: var(--fsslideshow-text-color) !important;
}