/*
z-index:
4   #slidemenu-container
3   .siteheader-identity
2   .siteheader-menubar
1   .pageheader__box
*/
@media print {
  .sitefooter {
    display: none;
  }
}