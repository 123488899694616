.widget--downloads
{
  border-radius: var(--widget-border-radius);
}

.widget--downloads__items
{
  container-name: minfinLargeListing minfinLargeListingLine;
  container-type: inline-size;
}
