/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
.filteredoverview__teaser__button {
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
}

.filteredoverview__teaser {
  display: none;
  position: -webkit-sticky;
  position: sticky;
  margin-top: -35px;
  z-index: var(--filteredoverview-teaserbar-zindex);
  bottom: 11px;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
  cursor: pointer;
}

a.filteredoverview__teaser {
  text-decoration: none;
}

.filteredoverview__teaser__content {
  width: 100%;
  max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  margin-left: auto;
  margin-right: auto;
  height: 71px;
  border-radius: 8px;
  background: var(--color-theme-background);
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.32);
  position: relative;
  width: 100%;
  max-width: min(100% - var(--sidepadding) * 2, 940px);
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  display: flex;
  align-items: center;
}

.filteredoverview__teaser__content:hover {
  background: linear-gradient(180deg, #0B923E 0%, #0B923E 100%);
}

html.filteredoverview--showfilters .filteredoverview__teaser {
  display: block;
  opacity: 1;
  pointer-events: auto;
}

/*
.filteredoverview__teaser--aboveresults
{
  opacity: 1;
  pointer-events: auto;
}
*/
.filteredoverview__teaser__label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  transition: background-color 0.25s;
}
.filteredoverview__teaser__label:focus {
  box-shadow: 0 0 0 2px #000;
  outline: 0;
}
@supports (outline: -webkit-focus-ring-color auto 5px) {
  .filteredoverview__teaser__label:focus {
    box-shadow: none;
    outline: -webkit-focus-ring-color auto 5px;
  }
}
.filteredoverview__teaser__label:active:focus {
  box-shadow: none;
  outline: none;
}

.filteredoverview__teaser__label b {
  margin-left: 7px;
  margin-right: 5px;
}

.filteredoverview__teaser__label::after {
  margin-left: 18px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: var(--fa-arrow-circle-down);
  content: var(--fa-arrow-circle-down)/"";
}

.filteredoverview__teaser__content {
  height: clamp(50px, 10vw, 70px);
}

.page--huishoudboekjes .filteredoverview__teaser__content,
.page--publications .filteredoverview__teaser__content {
  height: clamp(40px, 10vw, 60px);
}

.filteredoverview__teaser__label {
  font: 18px var(--rtd-heading-fontfamily);
  font: clamp(18px, 3.5vw, 24px) var(--rtd-heading-fontfamily);
}

.filteredoverview__teaser__label::after {
  font-size: 27px;
  font-size: clamp(26px, 4.5vw, 32px);
}

/*
NOTE: dit wordt alleen nog door WVHG/GL gebruikt.
*/
html {
  --filtertag-gap-h: 10px;
  --filtertag-gap-v: 10px;
}

/*
.content--singlecolumn .filteredoverview__feedbackandviewbar
{
  //@include spc-contentgrid;
  @include layout--default;
  margin-top: 20px;
}
*/
.filteredoverview__feedbackandviewbar.filteredoverview__feedbackandviewbar {
  font: 18px/30px var(--rtd-text-fontfamily);
  color: #767676;
}

.filteredoverview__feedback-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}

.filteredoverview__feedbackandviewbar__content {
  font: 18px/28px var(--rtd-heading-fontfamily);
  color: #333333;
}

.feedbackbar_submitlink {
  margin-left: auto;
}

.filtertags__items {
  margin-top: 12px;
}

@media (max-width: 600px) {
  .filteredoverview__feedbackandviewbar__content {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: min-content min-content;
  }
  .filteredoverview__feedback-wrapper {
    display: contents;
  }
  .filteredoverview__feedback {
    grid-column: 1;
    grid-row: 1;
  }
  .filtertags__items {
    grid-column: 1/span 2;
    grid-row: 2;
  }
  .feedbackbar_submitlink {
    grid-column: 2;
    grid-row: 1;
  }
  .filtertags__items {
    margin-top: 18px;
  }
  .filtertags__divider {
    display: none; /* don't split the tags into seperate groups on mobile */
  }
}
/*
.filteredoverview__feedbackandviewbar__content
{
  display: flex;
  flex-wrap: wrap; // if the tags don't fix on a single line, show the below the label
  align-items: baseline;
}
*/
.filteredoverview__filtertags {
  display: none;
}

.filteredoverview--filtersactive .filteredoverview__filtertags {
  display: block;
}

.filteredoverview-action--resetfilters {
  display: none;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: #656565;
  padding: 4px 8px;
  margin-left: 2px;
  text-decoration: underline;
  text-underline-offset: 3px;
  cursor: pointer;
}
html.filteredoverview--filtersactive .filteredoverview-action--resetfilters {
  display: block;
}

.filtertags__title {
  flex: none;
}

.filtertags__items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: var(--filtertag-gap-h);
  row-gap: var(--filtertag-gap-v);
}

.filtertags__item {
  display: inline-flex;
  align-items: center;
  min-height: 29px;
  padding-top: 4px;
  padding-bottom: 4px;
  font: 14px Arial;
  background-color: #FFFFFF;
  border: 1px solid var(--color-green);
  border-radius: 16px;
  color: #3F3F3F;
  padding-left: 14px;
  padding-right: 14px;
  cursor: pointer;
  text-decoration: none;
  max-width: 100%;
}

.filtertags__item:hover {
  color: var(--color-green);
  border-color: var(--color-green);
}

.filteredoverview__feedbackandviewbar .filtertags__item::after {
  margin-left: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00d";
  font-size: 12px;
  color: #333333;
}

.filtertags__item__remove {
  align-self: center;
  padding: 0 8px 0 8px;
  font-size: 9px;
}

.filtertags__item--group {
  background-color: var(--color-green-small);
  color: #FFFFFF;
}

@media (max-width: 640px) {
  .filtertags__item--group--withshorttitle .filtertags__item__title--long {
    display: none;
  }
}
@media (min-width: 641px) {
  .filtertags__item--group--withshorttitle .filtertags__item__title--short {
    display: none;
  }
}

.filteredoverview__feedbackandviewbar .filtertags__item--group::after {
  color: #FFFFFF;
}

.filtertags__item--group.filtertags__item--group:hover {
  background-color: #008000;
  color: #FFFFFF;
}

@media (min-width: 640px) {
  .filtertags__divider {
    width: 100%;
  }
}
.infobutton {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-left: auto;
  outline-offset: 3px;
}

.infobutton::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: var(--fa-info-circle);
  font-size: 18px;
  color: var(--color-green);
}

.filteredoverview__feedbackandviewbar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 15px;
  font: 18px/28px var(--rtd-heading-fontfamily);
  color: #333333;
  --filtertag-gap-v: 5px;
}

.filteredoverview__feedbackandviewbar__readspeakerbutton {
  flex: none;
  align-self: flex-start;
}

.filteredoverview__feedbackandviewbar hr {
  width: 100%;
  margin-top: 0px; /* 15px - row-gap of our flex parent */
  border: 0;
  border-bottom: 1px solid #D7D7D7;
  margin-bottom: 0;
}

.page--lesmateriaaloverview .filteredoverview__feedbackandviewbar hr {
  margin-bottom: 35px;
}

.pagecontent__topbar + .filteredoverview__feedbackandviewbar {
  margin-top: 20px;
}

.projects__filters label {
  cursor: pointer;
}

.genericfilters__panel {
  color: #3F3F3F;
  font: 15px/19px var(--rtd-text-fontfamily);
}

.genericfilters__item + .genericfilters__item {
  margin-top: 30px;
}

.tagcategory__title {
  color: var(--tagpanel-heading-color);
  font: var(--tagpanel-heading-font);
  margin-bottom: var(--tagpanel-heading-margin);
  padding-bottom: 0px;
}

.tagcategory__options {
  color: #3F3F3F;
}

.tagcategory__title {
  margin-top: 0;
  margin-bottom: 10px;
  font: bold 22px var(--rtd-heading-fontfamily);
}

.checkboxlist__option + .checkboxlist__option {
  margin-top: 1px;
}

.filterbar__option + .filterbar__option,
.multiselect__panel__item + .multiselect__panel__item {
  margin-top: 15px;
}

.checkboxlist__option,
.filterbar__option {
  display: flex;
}

.checkboxlist__option input + label,
.multiselect__panel__item input + label {
  flex: none;
  margin-right: 10px;
}

.checkboxlist__option,
.filterbar__option {
  display: flex;
}

.checkboxlist__option input + label {
  flex: none;
  margin-right: 10px;
}

.checkboxlist__option {
  display: flex;
  align-items: baseline;
}

.checkboxlist__option > input + label {
  margin-right: 10px;
}

.checkboxlist__option > input + label + label {
  padding-top: 8px;
  padding-bottom: 8px;
}

.checkboxlist__option {
  display: flex;
  align-items: baseline;
}

.checkboxlist__option > input + label {
  margin-right: 10px;
}

.checkboxlist__option > input + label + label {
  padding-top: 8px;
  padding-bottom: 8px;
}

@media (max-width: 640px) {
  .tagcategory {
    padding-top: 22px;
    padding-bottom: 25px;
  }
  .tagcategory + .tagcategory {
    margin-top: 0 !important;
  }
}