.spc-pagination
{
  display: flex;
  width: 100%;

  column-gap: var(--pagination-prevnext-spacing);

  font: var(--pagination-font);

  margin-top: 15px;
  padding-top: 15px;
  border-top: var(--pagination-border);
}

/* When there's only a single page (or no results) the hidden attribute is used on .spc-pagination */
.spc-pagination[hidden]
{
  display: none;
}

.spc-pagination button
{
  border: 0;
  padding: 0;
  background: none;
  font: inherit;
  cursor: pointer;

  user-select: none;
}

.spc-pagination button[disabled]
{
  cursor: default;
}

  .spc-pagination__previous.spc-pagination__previous
, .spc-pagination__next.spc-pagination__next
{
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;

  border: var(--pagination-prevnext-border);
  color: var(--pagination-prevnext-color);
  text-decoration: none;
  cursor: pointer;

  width:  var(--pagination-page-size);
  height: var(--pagination-page-size);
  background: var(--pagination-prevnext-background);
  border-radius: 50%;

  font: var(--pagination-iconfont);
  font-size: 16px;
}


.spc-pagination__previous::before
{
  color: var(--pagination-prevnext-icon-color);
  content: var(--pagination-previous-icon-content);
  font-size: var(--pagination-previous-icon-size);
}

.spc-pagination__next::before
{
  content: var(--pagination-next-icon-content);
  font-size: var(--pagination-next-icon-size);
}

  .spc-pagination__previous:hover
, .spc-pagination__next:hover
{
  background: var(--pagination-prevnext-hover-background);
  border:     var(--pagination-prevnext-hover-border);
  color:      var(--pagination-prevnext-hover-color);
}

  .spc-pagination__previous:hover .text
, .spc-pagination__next:hover .text
{
  text-decoration: underline;
  text-underline-offset: 3px;
}

  .spc-pagination__previous:hover::before
, .spc-pagination__next:hover::after
{
  color:      var(--pagination-prevnext-hover-icon-color);
}

  .spc-pagination__previous[disabled]
, .spc-pagination__next[disabled]
{
  visibility: hidden;
  /*
  background: var(--pagination-prevnext-disabled-background);
  border: var(--pagination-prevnext-disabled-border);
  color: var(--pagination-prevnext-disabled-icon-color);
  cursor: default;
  */
}

  .spc-pagination__previous[disabled]::before
, .spc-pagination__next[disabled]::after
{
/*  color: #777777;*/
}

/* To prevent page layout blowup (and then using that new width for more buttons instead of shrinking):
   - .acoifilterpage__content.acoifilterpage__content { overflow: hidden; } -> don't stretch grid
   - .spc-pagination__pages { contain: size; } -> don't ever ask parent container to grow
*/

.spc-pagination__pages
{
  flex: 1 1 0px;
  contain:  size; /* child content isn't allowed to stretch this container (iOS 16+) */

  display: flex;
  justify-content: center;
  column-gap: var(--pagination-page-spacing);
}

.spc-pagination__ellipsis::before
{
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;

  width:  var(--pagination-page-size);
  height: var(--pagination-page-size);

  font: var(--pagination-ellipsis-icon-font);
  content: var(--pagination-ellipsis-icon-content);
  font-size: var(--pagination-ellipsis-icon-size);
/*
  padding-bottom: 14px;
*/
}

.spc-pagination__page
{
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;

  width:  var(--pagination-page-size);
  height: var(--pagination-page-size);
  border-radius: var(--pagination-page-radius);

  color: var(--pagination-page-link-color);
  border: var(--pagination-page-link-border);
}

button.spc-pagination__page:hover
{
  border: var(--pagination-page-current-border);
  background: var(--pagination-page-hover-background);
}

.spc-pagination__page.current
{
  background: var(--pagination-page-current-background);
  color: var(--pagination-page-current-color);
  border: var(--pagination-page-current-border);
}
