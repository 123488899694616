/*
Keep this PLAIN CSS !!
This file is used by the richtext editor, which doesn't support SASS
*/



.widget--padding { padding: 30px 21px 20px 21px; }
.widget--fancycta__body.widget--padding { padding: 9px 15px 15px 11px; } /* 15px, compensate 6px at the top and 4px at the bottom */
.widget--highlightbig__body.widget--padding { padding: 25px 20px 25px 20px; }
.page--activity .widget--padding { padding: 25px 15px 20px 15px; }

  .widget--buttoncta
, .widget--poll
{
  background: var(--color-bggradient-to-green);
}

.widget--buttoncta:hover
{
  background: var(--color-bggradient-to-green-hover);
}



  .searchresult:hover
, .widget--custom-partnerlogo:hover
{
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.25);
}






/** Widget preview ********************************************************************/

html.wh-widgetpreview body
{
  display: inline-block;
  padding: 20px;
  background-image: none;
}

/* File widgets */
  html.wh-widgetpreview .widget--buttoncta
, html.wh-widgetpreview .widget--default
, html.wh-widgetpreview .widget--downloads
, html.wh-widgetpreview .widget--fancycta
, html.wh-widgetpreview .widget--image
, html.wh-widgetpreview .widget--links
/*, html.wh-widgetpreview .widget--newssearch*/
, html.wh-widgetpreview .widget--newsletter
, html.wh-widgetpreview .widget--person
, html.wh-widgetpreview .widget--poll
, html.wh-widgetpreview .widget--slideshare
, html.wh-widgetpreview .widget--video

, html.wh-widgetpreview .widget-events
, html.wh-widgetpreview .widget--news
{
  width: 340px;
}

/* Inline widgets should
   - use the width of the page content area (small or wide) */
  html.wh-widgetpreview .widget--button
, html.wh-widgetpreview .widget--embedwidgetsgroup
, html.wh-widgetpreview .widget--widgetsgroup
, html.wh-widgetpreview .widget--highlightbig
{
  width: 940px;
}

html.wh-widgetpreview .widgetsgrid
{
  width: 1000px;
}



/** Shared widget styling *************************************************************/

.widget--fancycta
{
  border-radius: var(--widget-border-radius);
  background-color: #FFFFFF;
}
a.widget--fancycta
{
  text-decoration: none;
}
.widget--colorborder
{
  border-width: 1px; /* widgets width a color border have a simple 1px border */
}



/* certain widgets when used directly in a richdoc (not in a widgetgrid) get a special green border */
  .widget--ctaborder
, .richdoc > .widget--links
, .richdoc > .widget--downloads
, .richdoc > .widget-downloadsandlinks
/*, .widget--neoitem-highlight*/
, .widget--widgetsgroup .widget--downloadpdf /* actionsbar */
, .widget--widgetsgroup .widget--share /* actionsbar */
{
  border: 1px solid var(--widget-border-color);
  border-radius: var(--widget-border-radius);
  box-shadow: var(--widget-box-shadow);
}





.widget__row + .widget__row
{
  /*
  Used by
  - newssearch widget
  - activitiessearch widget
  - activitydetail
  */
  margin-top: 15px;
}





.widget--title
{
  margin-bottom: 20px;
}


.content__widgetscolumn
{
  position: relative;
}



/** Widget 'Anchor' *******************************************************************/

.widget--anchor
{
  display: block;

  /* offset to make sure anchor's within the page
     scroll a little further so the indended startposition
     doesn't start under the fixed header
  */
  position: relative;
  top: -75px;

  height: 0;
  margin: 0;
  padding: 0;

  /* border: 1px solid rgba(255, 0, 0, 0.25); */
}

.widget--anchor--preview
{
  font-size: 20px;
  color: #000000;
  padding: 0 20px;
  text-align: center;
}



/** Widget 'Button CTA' ***************************************************************/

a.widget--buttoncta
{
  display: inline-block; /* when used within RTD content or custom HTML we shrink-to-fit */
  text-decoration: none;
  cursor: pointer;

  position: relative;
  padding-left: 46px; /* room for the arrow */

  border-width: 2px;
  border-style: solid;
  border-radius: var(--widget-border-radius);

  box-shadow: -4px 4px 6px rgba(0, 0, 0, 0.15);
}
a.widget--buttoncta.widget--padding
{
  padding-top: 15px;
  padding-bottom: 15px;
}

.widget--widgetsgroup a.widget--buttoncta
{
  display: block; /* fallback */

  /* vertical align content */
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.widgetsgrid .widget--buttoncta
{
  display: block; /* when used as widget within a widgetgrid we grow to fit our container/column */
}


.widget--buttoncta__title
{
  margin-bottom: 0;
}
.widget--buttoncta__body
{
  color: #000000;
}
.widget--buttoncta__title + .widget--buttoncta__body
{
  margin-top: 10px;
}

.widget--buttoncta__symbol
{
  position: absolute;
  left: -19px;
  width: 59px;

  top: 50%;
  transform: translate(0, -50%);
}



/** Widget 'iframe' *********************************************************************/

.widget--iframe iframe
{
  width:  100%; /* IE & Edge */
  width:  -webkit-fill-available; /* Chrome 22+ & SF6.1+ */
  width:  -moz-available; /* FF3+ */

  height: 300px;
  border: 0;
}



/** Widget 'Newsletter' ****************************************************************/

.widget--newsletter
{
  padding: var(--widget-padding-top) var(--widget-padding-h) var(--widget-padding-bottom) var(--widget-padding-h);
}

.widget--newsletter__header
{
  display: flex;
  align-items: center;

  font-size: 21px;
  line-height: 28px;

  margin-bottom: 20px;
}

.widget--newsletter .fa
{
  font-size: 50px;
  margin-right: 15px;
}

.widget--newsletter ul
{
  list-style-type: none;
  margin: 0 0 10px;
  padding: 0;
}
.widget--newsletter ul > li + li
{
  margin-top: 3px;
}

.widget--newsletter form
{
  margin-top: 10px;
}

.widget--newsletter__email, .widget--newsletter__text
{
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100% !important;
  margin-bottom: 10px;
}



/** Widget 'Slideshare' *********************************************************************/

.widget--slideshare .widget__body
{
  padding: var(--widget-padding-top) var(--widget-padding-h) var(--widget-padding-bottom) var(--widget-padding-h);
}

.widget--slideshare__embed
{
  position: relative;
  width: 100%;
}
/* force aspect ratio */
.widget--slideshare__embed:after
{
  display: block;
  content: '';
  padding-top: 83.5%;
}
.widget--slideshare__embed > div
{
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.widget--slideshare__embed iframe
{
  display: block;
  width: 100%;
  height: 100%;
}
.widget--slideshare__downloads
{
  display: flex;

  margin-top: 15px;
}
.widget--slideshare__downloads a + a
{
  margin-left: auto;
}
.widget--slideshare__downloads a
{
  display: inline-block; /* [IEFIX] */
  text-decoration: none;
  color: var(--themecolor);
  background: transparent none 0px 1px no-repeat;
}
.widget--slideshare__downloads a:hover
{
  text-decoration: underline;
}
.widget--slideshare__downloads .fa
{
  font-size: 125%;
  margin-right: 5px;
}
