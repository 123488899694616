/*@import "../config.scss";*/

/*
2017 ?

2023
- focus styling
  - replaced border-radius + overflow: hidden; width radius on first and last item
- divider done using ::before on checked item following a checked item (unless focus active)
- using :focus-visible instead of :focus
- dropped -webkit prefix for flex
- use of CSS variables
*/

.comp-togglebuttonbar
{
  display: flex;
}

  .comp-togglebuttonbar input[type='checkbox']
, .comp-togglebuttonbar input[type='radio']
{
  /* display: none or visibility: hidden; will prevent focus!! */
  position: absolute; /* to not mess with flex containers */
  width: 0;
  height: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;

  /* Work around "ARC Toolkit"'s "Insufficient normal text contrast" error.
     The contrast actually isn't relevant, due it not being visible.
     (the label is the visual/interactable part of the checkbox)
  */
  background-color: #FFFFFF;
  color: #000000;
}

.comp-togglebutton
{
  background: var(--formcontrol-buttonbar-item-background);
  color:      var(--formcontrol-buttonbar-item-color);
}

/* override .wh-styledinput input[type="checkbox"] */
.comp-togglebutton
{
  flex: 1 1 auto;

  height:      var(--formcontrol-buttonbar-item-height);
  line-height: var(--formcontrol-buttonbar-item-height);

  text-align: center;
  cursor: pointer;

  position: relative;
}

.comp-togglebuttonbar input:first-child + .comp-togglebutton
{
  border-radius: var(--formcontrol-buttonbar-border-radius) 0 0 var(--formcontrol-buttonbar-border-radius);
}

.comp-togglebuttonbar .comp-togglebutton:last-child
{
  border-radius: 0 var(--formcontrol-buttonbar-border-radius) var(--formcontrol-buttonbar-border-radius) 0;
}

.comp-togglebuttonbar input:checked + .comp-togglebutton
{
  background-color: var(--formcontrol-buttonbar-item-selected-background);
  color:            var(--formcontrol-buttonbar-item-selected-color);
}

.comp-togglebuttonbar input:focus-visible + label
{
  box-shadow: 0 0 0 2px #000000;
  outline: 2px solid #FFFFFF;
  outline-offset: 3px;
  z-index: 2;
}

/*
Add the divider as element outside of the label,
so it doesn't get included
*/
.comp-togglebutton ~ .comp-togglebutton::before
{
  content: "";
  position: absolute;
  left:   0px;
  top:    0;
  bottom: 0;
  border-left: 1px solid var(--formcontrol-buttonbar-divider-color);
}

input:checked + .comp-togglebutton + input:checked:not(:focus-visible) + .comp-togglebutton.selected::before
{
  border-left: 1px solid var(--formcontrol-buttonbar-divider-selecteditems-color);
}
