/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
html {
  --quicklinks-border-radius: 5px;
}

body > .pageheader-quicklinks {
  width: 100%;
  max-width: var(--pagegrid-contentarea-width-full);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
}

.content__widgetscolumn .pageheader-quicklinks {
  margin-top: -49px;
  margin-bottom: 15px;
}

.pageheader--panels ~ .body__content .content__widgetscolumn .pageheader-quicklinks {
  margin-top: 0;
}

.quicklinks {
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.15);
}

.quicklinks__heading,
.quicklinks > a {
  display: flex;
  align-items: center;
  padding: 10px 16px 12px 20px;
  min-height: 49px;
  color: #FFFFFF;
}

.quicklinks > hr {
  margin: 0;
  border: 0;
  height: 2px;
  background-color: #FFFFFF;
}

.quicklinks > *:first-child {
  border-radius: 5px 5px 0 0;
}

.quicklinks > *:last-child {
  border-radius: 0 0 5px 5px;
}

.quicklinks__heading {
  margin: 0; /* override H2 */
  background-color: #641C76;
  color: #FFFFFF;
  font: 20px var(--rtd-heading-fontfamily);
  height: 49px;
}

.quicklinks a {
  background-color: #13A538;
  background-color: var(--color-theme-background);
  text-decoration: none;
  font: bold 20px var(--button-fontfamily);
}

.quicklinks a:hover {
  background: var(--color-theme-background-highcontrast);
  text-decoration: none;
}