/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
/*
Variations on the header:
  .page--home2021 .pageheader - Textpanel will be at the bottom right within the header

Notes:
- The home version of the header will grow to fit the text (or the part covering)
  (if this would be necessary for the normal/theme header we could try to have the negative margin-top as a grid row instead and have the background image cover that row)
*/
html {
  --pageheader-content-overlap-height: 0;
  --pageheader-image-height-max: min(700px, calc(100vh - var(--site-menubar-height) - var(--site-topbar-height) - 30px));
  --pageheader-image-overlap: 65px;
}

html.page--neo-overview {
  --pageheader-image-overlap: 125px;
}

.pageheader--centeredtextpanel {
  --pageheader-image-overlap: 0;
}

@media (max-width: 767px) {
  html {
    --pageheader-image-height: 188px;
    --pageheader-contentframe-padding: 12px 25px;
  }
  html.page--home {
    --pageheader-image-height: 400px;
  }
  /*
  .pageheader--compact
  {
    --pageheader-image-height: 172px; // 172 in wig
  }
  */
}
@media (min-width: 768px) {
  :root {
    --pageheader-image-height: 398px;
    --pageheader-contentframe-padding: 16px;
  }
  html.page--home {
    --pageheader-image-height: 400px;
  }
  /*
  .pageheader--compact
  {
    --pageheader-image-height: 172px; // 172 in wig
  }
  */
  .pageheader--centeredtextpanel {
    --pageheader-image-height: 248px;
  }
  html.page--neo-overview {
    --pageheader-contentframe-padding: 20px 22px 27px 22px;
  }
}
.pageheader__quicklinks:empty {
  display: none;
}

.defaultpageheader {
  display: grid;
  grid-template-rows: [image-start] 1fr [image-overlap] var(--pageheader-image-overlap) [image-end] min-content [filterpanel-start] min-content;
  /*
  &.pageheader--centeredtextpanel .pageheader__text__description
  {
    display: flex;
    flex-direction: wrap;
    align-items: center;

      h2
    , .rtdcontent
    {
      flex: 1 1 0px; // equal width
      word-break: break-word;
      text-wrap: pretty;
      margin: 0;
    }

    .pageheader__filterbar
    {
      width: 100%;
    }
  }
  */
}
.defaultpageheader > form {
  display: contents;
}
.defaultpageheader .pageheader__bgimage {
  grid-column: 1;
  grid-row: image-start/image-end;
  min-height: var(--pageheader-image-height);
  max-height: var(--pageheader-image-height-max);
  /* - Always used the full width
     - Try to ensure the aspect-ratio (if set)
     - But never let the height grow beyond 800px
  */
  /* NOTE:
     - The "min-height + aspect ratio" combination will stretch the width
       beyond the viewport.
     - To prevent this we use max-width: 100vw (we cannot use %).
       This means the image aspect-ratio will be overridden and the
       image will reach the maximum of "max-width X max-height".
  */
  width: 100%;
  max-width: 100vw;
}
.defaultpageheader.pageheader--fixedheight .pageheader__bgimage {
  aspect-ratio: auto;
}
.defaultpageheader .pageheader__bgimage--placeholder {
  background: #DDDDDD;
}
.defaultpageheader .pageheader__content-top {
  grid-row: 1;
  grid-column: 1/-1;
  width: 100%;
  max-width: var(--pagegrid-contentarea-width-full);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  padding-top: 30px;
  padding-bottom: 15px;
}
.defaultpageheader #readspeaker_button {
  margin-left: auto;
}
.defaultpageheader .pageheader__content--textandql .pageheader__text__title + * {
  margin-top: 12px;
}
.defaultpageheader .pageheader__content--textandql .headertextbutton::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f054";
  font-size: 22px;
  margin-right: 16px;
}
.defaultpageheader .pageheader__content--textandql {
  grid-column: 1/-1;
  grid-row: image-overlap/span 2;
  width: 100%;
  max-width: var(--pagegrid-contentarea-width-full);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  display: flex;
}
@media (max-width: 767px) {
  .defaultpageheader .pageheader__content--textandql .pageheader__content {
    width: 100%;
    max-width: var(--pagegrid-contentarea-width-full);
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    display: flex;
    flex-direction: column;
    row-gap: 9px;
  }
}
@media (min-width: 768px) {
  .defaultpageheader .pageheader__content--textandql .pageheader__content {
    width: 100%;
    max-width: var(--pagegrid-contentarea-width-full);
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    display: grid;
    grid-template-columns: 1fr 280px;
    column-gap: 20px;
    row-gap: 13px;
  }
  .defaultpageheader .pageheader__content--textandql .pageheader__content > .pageheader__text {
    grid-row: image-overlap;
  }
  .defaultpageheader .pageheader__content--textandql .pageheader__content > .pageheader__quicklinks {
    grid-row: image-overlap;
  }
}
.defaultpageheader .pageheader__content--textandql {
  grid-row: 1/4 !important;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-template-rows: subgrid;
}
@supports not (grid-template-columns: subgrid) {
  .defaultpageheader .pageheader__content--textandql .pageheader__content--textandql {
    display: flex;
  }
}
.defaultpageheader .pageheader__content--textandql .pageheader__icon {
  grid-column: 1;
  grid-row: image-end;
  align-self: start;
}
.defaultpageheader .pageheader__content--textandql .pageheader__text {
  grid-column: 2;
  grid-row: image-overlap/span 2;
}
.defaultpageheader .pageheader__content--textandql .pageheader__quicklinks {
  grid-column: 3;
  grid-row: image-overlap/span 2;
}
.defaultpageheader .pageheader__content--textandql :where(.pageheader__text + .pageheader__quicklinks) {
  margin-left: 20px;
}
@media (max-width: 767px) {
  .defaultpageheader .pageheader__content--textandql {
    margin-top: calc(-1 * var(--pageheader-image-overlap));
  }
  .defaultpageheader .pageheader__content--textandql .pageheader__icon {
    display: none;
  }
}

.defaultpageheader.pageheader--centeredtextpanel .pageheader__text {
  grid-row: 1;
  align-self: center;
}

.defaultpageheader {
  /* ------------------------------------------------------------------------------------------
  **  Filterbar below pageheader image
  ** .defaultpageheader > form.filteredoverview__form > .pageheader__filterbar
  */
  /* ------------------------------------------------------------------------------------------
  **  Filterbar generic styling
  */
}
.defaultpageheader > .filteredoverview__form > .pageheader__filterbar {
  grid-row: 3;
  grid-column: 1/-1;
  background: #FAFAFA;
  padding-top: 24px;
  padding-bottom: 24px;
}
.defaultpageheader > .filteredoverview__form > .pageheader__filterbar .pageheader__filterbar__content {
  width: 100%;
  max-width: var(--pagegrid-contentarea-width-full);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
}
.defaultpageheader .pageheader__filterbar__content {
  display: flex;
}
.defaultpageheader .pageheader__expandedfilters-background {
  z-index: -1;
  grid-column: 1;
  grid-row: image-end/span 2;
}
.defaultpageheader .pageheader__expandedfilters {
  grid-row: filterpanel-start;
}
.filteredoverview--showfilters .defaultpageheader .pageheader__filterbar {
  background: none;
}
.filteredoverview--showfilters .defaultpageheader .pageheader__expandedfilters-background {
  background: linear-gradient(180deg, #FAFAFA 0%, #EBEBEB 100%);
}

.defaultpageheader.defaultpageheader {
  position: relative;
}
.defaultpageheader.defaultpageheader .pageheader__text {
  border: 1px solid #D2D2D2;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.19);
}
.defaultpageheader.defaultpageheader .pageheader__text__title {
  color: var(--themecolor);
}
.defaultpageheader.defaultpageheader h1 {
  margin: 0 0 15px 0;
}
.defaultpageheader.defaultpageheader.pageheader--centeredtextpanel h1 {
  font-weight: normal;
}
.defaultpageheader.defaultpageheader h1 b {
  font-weight: bold;
}
.defaultpageheader.defaultpageheader .pageheader__text__description {
  color: #000000;
}
.defaultpageheader.defaultpageheader .headertextbutton {
  display: flex;
  align-items: baseline;
  color: var(--color-green);
  text-decoration: none;
}
.defaultpageheader.defaultpageheader .pageheader__text {
  padding: var(--pageheader-contentframe-padding);
}
.defaultpageheader.defaultpageheader .pageheader__text .pageheader__filterbar {
  margin-top: 28px;
}
@media (max-width: 767px) {
  .defaultpageheader.defaultpageheader {
    --pageheader-content-overlap-height: 58px;
  }
  .defaultpageheader.defaultpageheader.filteredoverview__form::before {
    display: block;
    content: "";
    grid-column: 1;
    grid-row: 1/span 2;
    background-color: #F5F5F5;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
  .defaultpageheader.defaultpageheader .pageheader__content {
    grid-column: 1/-1;
    grid-row: 3;
  }
  .defaultpageheader.defaultpageheader.pageheader--panels .pageheader__content {
    grid-column: 1/-1;
    grid-row: 2/span 2;
  }
  .defaultpageheader.defaultpageheader.pageheader--centeredtextpanel .pageheader__text__description .rtdcontent {
    --rtd-text-font: 15px/22px var(--rtd-heading-fontfamily);
    color: #333333;
  }
}
@media (min-width: 768px) {
  .defaultpageheader.defaultpageheader {
    --pageheader-content-overlap-height: 85px;
  }
  .defaultpageheader.defaultpageheader .pageheader__text {
    padding: var(--pageheader-contentframe-padding);
    flex: 1 1 0;
  }
  .defaultpageheader.defaultpageheader .pageheader__quicklinks {
    width: 280px;
  }
  .defaultpageheader.defaultpageheader .pageheader__content {
    grid-column: 1/-1;
    grid-row: 2/span 2;
    align-self: end;
  }
  .defaultpageheader.defaultpageheader.pageheader--centeredtextpanel .pageheader__text__description .rtdcontent {
    display: grid;
    grid-template-columns: minmax(30%, 2fr) minmax(30%, 3fr);
    grid-auto-rows: minmax(0, min-content);
    column-gap: 30px;
    --rtd-text-font: 15px/22px var(--rtd-heading-fontfamily);
    color: #333333;
  }
  .defaultpageheader.defaultpageheader.pageheader--centeredtextpanel .pageheader__text__description .rtdcontent > * {
    flex: 1 1 0px;
    word-break: break-word;
    text-wrap: pretty;
    margin: 0;
  }
  .defaultpageheader.defaultpageheader.pageheader--centeredtextpanel .pageheader__text__description .rtdcontent h1 {
    grid-row: 1/-1;
    grid-column: 1;
    align-self: center;
  }
  .defaultpageheader.defaultpageheader.pageheader--centeredtextpanel .pageheader__text__description .rtdcontent *:not(h1) {
    grid-column: 2;
    text-align: right;
    align-self: center;
  }
  .defaultpageheader.defaultpageheader.pageheader--centeredtextpanel .pageheader__text__description .pageheader__filterbar {
    grid-column: 1/-1;
    width: 100%;
  }
}

.defaultpageheader.pageheader--textsidebyside .pageheader__text__title, .defaultpageheader.pageheader--textsidebyside .pageheader__text {
  color: #FFFFFF;
}

/* row with crumblepath and ReadSpeaker button */
.pageheader-bottomrow {
  width: 100%;
  max-width: var(--pagegrid-contentarea-width-full);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  display: flex;
  align-items: center;
  margin-top: 13px;
}

.defaultpageheader + .pageheader-quicklinks {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .defaultpageheader.pageheader--textsidebyside .pageheader__content {
    grid-column: 1;
    grid-row: 1;
    align-self: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .defaultpageheader .pageheader__text__title {
    font: bold 24px/26px var(--rtd-heading-fontfamily);
  }
  .defaultpageheader .pageheader__text__description {
    font: 16px/24px var(--rtd-heading-fontfamily);
  }
  .defaultpageheader .headertextbutton {
    margin-top: 20px;
    font: bold 20px/24px var(--rtd-heading-fontfamily);
  }
}
@media (max-width: 988px) {
  .defaultpageheader .pageheader__readspeaker {
    grid-column: 1;
    grid-row: 1;
    align-self: start;
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .defaultpageheader .pageheader__readspeaker {
    width: 100%;
    max-width: var(--pagegrid-contentarea-width-full);
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
  }
}
@media (min-width: 989px) {
  .defaultpageheader .pageheader__readspeaker {
    grid-column: 1;
    grid-row: 2;
    align-self: end;
    position: relative;
  }
  .defaultpageheader .pageheader__readspeaker {
    width: 100%;
    max-width: var(--pagegrid-contentarea-width-full);
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
  }
  .defaultpageheader .pageheader #readspeaker_button {
    position: absolute;
    top: 40px;
    right: 15px;
  }
}
@media (min-width: 768px) {
  .defaultpageheader.pageheader--textsidebyside .pageheader__content {
    grid-column: 1;
    grid-row: 1;
  }
  .defaultpageheader.pageheader--sidebyside .pageheader__text__description {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .defaultpageheader.pageheader--sidebyside .pageheader__text__title {
    flex: 1 1 auto;
    padding-right: 30px;
  }
  .defaultpageheader.pageheader--sidebyside .pageheader__text__description .rtdcontent {
    flex: 1 0 auto;
    max-width: 53%;
    text-align: right;
  }
  .defaultpageheader .pageheader__text__title {
    font: bold 36px/40px var(--rtd-heading-fontfamily);
  }
  .defaultpageheader .pageheader__text__description {
    font: 18px/28px var(--rtd-heading-fontfamily);
  }
  .defaultpageheader .headertextbutton {
    margin-top: 20px;
    font: bold 22px/28px var(--rtd-heading-fontfamily);
  }
}
.defaultpageheader .pageheader__text__description .widget-button {
  margin-top: 20px;
}
.defaultpageheader .pageheader .headerbutton {
  display: inline-flex;
  align-items: center;
  height: 44px;
  border: 2px solid #13A538;
  border-radius: 5px;
  font: 500 18px/26px var(--rtd-heading-fontfamily);
  padding-left: 20px;
  padding-right: 40px;
  text-decoration: none;
  cursor: pointer;
}
.defaultpageheader .pageheader .headerbutton::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f054";
  font-size: 18px;
  margin-right: 15px;
}
.defaultpageheader .pageheader .headerbutton:hover {
  background-color: #13A538;
  color: #FFFFFF;
}