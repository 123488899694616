@charset "UTF-8";
html:not(.styling2025) {
  /************************************************************
   *
   *  Page components / Pagination
   */
  /*
    --pagination-prevnext-spacing:     10px;
    --pagination-prevnext-background:  var(--color-purple-faded3);
    --pagination-prevnext-color:       var(--color-purple-dark);
    --pagination-prevnext-hover-color: #FFFFFF;
  */
  --pagination-bar-height: 30px;
  --pagination-font: 20px/32px var(--rtd-text-fontfamily);
  --pagination-page-link-background: transparent;
  --pagination-page-link-color: #000000;
  --pagination-page-current-background: var(--themecolor);
  --pagination-page-current-color: #FFFFFF;
  --pagination-page-size: 29px;
  --pagination-page-radius: 5px;
  --pagination-page-spacing: 0px;
}
html:not(.styling2025) .spc-pagination__previous::before {
  margin-right: 10px;
  content: "«";
}
html:not(.styling2025) .spc-pagination__next::after {
  margin-left: 10px;
  content: "»";
}

html.styling2025,
html.page--eventsoverview,
html.page--newsoverview {
  /************************************************************
   *
   *  Page components / Pagination
   */
  --pagination-prevnext-spacing: 10px;
  --pagination-prevnext-border: none;
  --pagination-prevnext-icon-color: var(--themecolor);
  --pagination-prevnext-background: transparent;
  --pagination-prevnext-color: var(--themecolor);
  --pagination-prevnext-hover-color: var(--themecolor-hover);
  --pagination-bar-height: 29px;
  --pagination-font: 16px/26px var(--rtd-heading-fontfamily);
  --pagination-page-link-background: transparent;
  --pagination-page-link-color: var(--themecolor);
  --pagination-page-link-border: none;
  --pagination-page-current-background: var(--themecolor);
  --pagination-page-current-color: #FFFFFF;
  --pagination-page-current-border: none;
  --pagination-page-size: 29px;
  --pagination-page-radius: 50%;
  --pagination-page-spacing: 0px;
}
html.styling2025 .spc-pagination__previous::before,
html.page--eventsoverview .spc-pagination__previous::before,
html.page--newsoverview .spc-pagination__previous::before {
  margin-right: 10px;
  font: var(--fontawesome-regular);
  font-size: 13px;
  content: var(--fa-chevron-left);
}
html.styling2025 .spc-pagination__next::after,
html.page--eventsoverview .spc-pagination__next::after,
html.page--newsoverview .spc-pagination__next::after {
  margin-left: 10px;
  font: var(--fontawesome-regular);
  font-size: 13px;
  content: var(--fa-chevron-right);
}

.pagination {
  text-align: center;
}

.pagination__previous,
.pagination__next,
.pagination__pages {
  line-height: var(--pagination-bar-height);
}

.pagination__previous {
  float: left;
}

.pagination__next {
  float: right;
}

.pagination__pages > a {
  display: inline-block;
  /*
    width: 29px;
    height: 30px;
    line-height: 30px;
    text-align: center;
  */
  width: var(--pagination-page-size);
  height: var(--pagination-bar-height);
  border-radius: var(--pagination-page-radius);
  align-content: center;
  justify-content: center;
  background: var(--pagination-page-link-background);
  text-decoration: none;
  color: var(--pagination-page-link-color);
}

.pagination__pages > a:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.pagination__pages > a.selected {
  background-color: var(--pagination-page-current-background);
  color: var(--pagination-page-current-color);
}