@charset "UTF-8";
/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
html.styling2025 .sitefooter--gl {
  --button-height: 41px;
  --button-radius: 4px;
  --footer-heading-color: #FFFFFF;
  --footer-heading-font: bold 18px/22px var(--rtd-heading-fontfamily);
  --footer-button-font: 17px var(--rtd-heading-fontfamily);
  --footer-sociallinks-heading-font: bold 18px/22px "TypoPRO Dosis Buttons", "Dosis", Arial;
  --footer-sociallinks-item-spacing: 8px;
  --footer-sociallinks-item-size: 44px;
  --footer-sociallinks-item-border: 2px solid var(--wig-green-dark);
}
html.styling2025 .sitefooter--gl .body__widgetsbottom + .sitefooter {
  margin-top: 0;
}
html.styling2025 .sitefooter--gl .sitefooter__panel--identity__wig {
  display: none;
}
html.styling2025 .sitefooter--gl .sitefooter__panel {
  padding-top: 29px;
  padding-bottom: 29px;
  background: var(--wig-green-dark);
  color: #FFFFFF;
}
html.styling2025 .sitefooter--gl .sitefooter__panel__content {
  width: 100%;
  max-width: var(--pagegrid-contentarea-width-full);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
}
html.styling2025 .sitefooter--gl .sitefooter__heading {
  color: var(--footer-heading-color);
  font: var(--footer-heading-font);
  margin-top: 0;
  margin-bottom: 15px;
}
html.styling2025 .sitefooter--gl .sitefooter__panel--identity {
  display: contents;
}
html.styling2025 .sitefooter--gl .sitefooter__panel--identity__content {
  width: max-content;
  margin-right: 20px;
}
html.styling2025 .sitefooter--gl .sitefooter__panel--identity__gl {
  font: 15px/18px var(--rtd-heading-fontfamily);
  padding-bottom: 12px; /* make room for the extra text */
  position: relative;
}
html.styling2025 .sitefooter--gl .sitefooter__panel--identity__gl__text {
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: right;
}
html.styling2025 .sitefooter--gl .sitefooter__panel--identity__gl__image {
  margin-top: -10px;
  width: 209px;
}
html.styling2025 .sitefooter--gl .sitefooter__panel--links h2 {
  margin-bottom: 5px;
}
html.styling2025 .sitefooter--gl .sitefooter__panel--links ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
html.styling2025 .sitefooter--gl .sitefooter__panel--links li {
  display: flex;
  align-items: baseline;
}
html.styling2025 .sitefooter--gl .sitefooter__panel--links li + li {
  margin-top: 2px;
}
html.styling2025 .sitefooter--gl .sitefooter__panel--links li:before {
  font: var(--fontawesome-regular);
  content: var(--fa-chevron-right);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin-right: 10px;
}
html.styling2025 .sitefooter--gl .sitefooter__panel--links a {
  color: #FFFFFF;
  font: var(--footer-links-item-font);
  text-decoration: none;
  text-wrap: pretty;
  padding: 3px 0;
}
html.styling2025 .sitefooter--gl .sitefooter__panel--links a:hover {
  text-decoration: underline;
  text-underline-offset: 3px;
}
html.styling2025 .sitefooter--gl .sitefooter__buttonslist {
  width: max-content;
  display: flex;
  flex-direction: column;
}
html.styling2025 .sitefooter--gl .sitefooter__panel--newsletter .sitefooter__button {
  display: inline-flex;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: var(--button-height);
  padding: 5px 20px 5px 20px;
  font: var(--button-font);
  border-radius: var(--button-borderradius);
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid var(--button-color);
  color: var(--color-theme-background-highcontrast);
  text-decoration: none;
  --button-font: var(--footer-button-font);
  padding: 5px 15px;
  justify-content: space-between;
  white-space: nowrap;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.15);
}
html.styling2025 .sitefooter--gl .sitefooter__panel--newsletter .sitefooter__button:hover {
  text-decoration: underline;
  text-underline-offset: 3px;
}
html.styling2025 .sitefooter--gl .sitefooter__panel--newsletter .sitefooter__button:after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: var(--fa-chevron-right);
  content: var(--fa-chevron-right)/"";
  font-size: 13px;
  vertical-align: middle;
  margin-left: 35px;
}
html.styling2025 .sitefooter--gl .sitefooter__panel--submit .sitefooter__button {
  display: inline-flex;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: var(--button-height);
  padding: 5px 20px 5px 20px;
  font: var(--button-font);
  border-radius: var(--button-borderradius);
  cursor: pointer;
  border-radius: var(--button-radius);
  text-decoration: none;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  font: var(--button-font);
  --button-font: var(--footer-button-font);
  padding: 5px 15px;
  justify-content: space-between;
  white-space: nowrap;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.15);
}
html.styling2025 .sitefooter--gl .sitefooter__panel--submit .sitefooter__button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #FFFFFF;
}
html.styling2025 .sitefooter--gl .sitefooter__panel--submit .sitefooter__button:after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: var(--fa-chevron-right);
  content: var(--fa-chevron-right)/"";
  font-size: 13px;
  vertical-align: middle;
  margin-left: 35px;
}
html.styling2025 .sitefooter--gl .sitefooter__button + .sitefooter__button {
  margin-top: 15px;
}
@media (min-width: 850px) {
  html.styling2025 .sitefooter--gl {
    --footer-links-item-font: 17px/20px var(--rtd-heading-fontfamily);
  }
  html.styling2025 .sitefooter--gl .sitefooter__panel__content {
    display: flex;
    flex-wrap: wrap;
    column-gap: 32px;
    row-gap: 20px;
  }
  html.styling2025 .sitefooter--gl .sitefooter__panel__buttonpanels {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    width: max-content;
  }
  html.styling2025 .sitefooter--gl .sitefooter__panel--links {
    flex: 1 0 180px;
  }
  html.styling2025 .sitefooter--gl .sitefooter__panel--newsletter, html.styling2025 .sitefooter--gl .sitefooter__panel--submit {
    width: max-content;
    flex: none;
  }
}
@media (min-width: 975px) {
  html.styling2025 .sitefooter--gl {
    /*
    \00a0 non breakable space
    \200b zero width space
    \A newline
    */
  }
  html.styling2025 .sitefooter--gl .sitefooter__panel__buttonpanels {
    display: contents;
  }
  html.styling2025 .sitefooter--gl .sitefooter__panel--submit .sitefooter__heading::before {
    float: left;
    content: "​\a​";
    white-space: pre;
    vertical-align: top;
  }
  html.styling2025 .sitefooter--gl .sitefooter__panel--newsletter, html.styling2025 .sitefooter--gl .sitefooter__panel--submit {
    width: auto;
  }
}
@media (max-width: 849px) {
  html.styling2025 .sitefooter--gl .sitefooter__panel__content {
    display: grid;
    grid-template-columns: minmax(min-content, 40%) 1fr;
    row-gap: 50px;
  }
  html.styling2025 .sitefooter--gl .sitefooter__panel__buttonpanels {
    display: contents;
  }
}
@media (max-width: 669px) {
  html.styling2025 .sitefooter--gl {
    --footer-links-item-font: 15px/18px var(--rtd-heading-fontfamily);
    margin-top: 45px;
  }
  html.styling2025 .sitefooter--gl .sitefooter__panel--links, html.styling2025 .sitefooter--gl .sitefooter__panel--newsletter,
  html.styling2025 .sitefooter--gl .sitefooter__panel--submit {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
  html.styling2025 .sitefooter--gl .sitefooter__panel > *:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
  html.styling2025 .sitefooter--gl .sitefooter__panel__content {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  html.styling2025 .sitefooter--gl .sitefooter__panel--identity {
    display: none;
  }
  html.styling2025 .sitefooter--gl .sitefooter__social {
    margin-top: 40px;
  }
  html.styling2025 .sitefooter--gl .sitefooter__bottombar {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  html.styling2025 .sitefooter--gl .sitefooter__buttonslist {
    display: flex;
    flex-direction: column;
  }
  html.styling2025 .sitefooter--gl .sitefooter__button {
    min-width: 280px;
  }
}