/*

There are 3 video widget's
- the shared (FGM & WVHG) video widget with buttons
- the WVHG video widget which is like the WVHG image widget + playbutton which opens the video in a popup (and uses the .widget--image class and witty)
- the inline WVHG video widget which looks like a TV

*/



/** Widget 'Video' *********************************************************************/

/*
Video (FFW/WVHG)
*/

.widget--video
{
  position: relative;
}

.widget--video .widget__title .fa
{
  margin-right: 10px;
}

.widget--video .widget__description
{
  margin-bottom: 10px;
}
