.site-debugbar
{
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 1000;

  padding: 10px 15px 15px 15px;
  background: rgba(0,64,0,0.7);
  color: #FFFFFF;

  box-shadow: 0 0 4px 4px rgba(0,0,0,0.3);

  --font-awesome-weight:  600; /* regular (400), bold (600) */
  --font-awesome-size:    14px;
  --font-awesome-offsety: 1px;

  --formcontrol-checkradio-size:                22px;
  --formcontrol-checkradio-bordercolor:         #929292;
  --formcontrol-checkradio-borderwidth:         1px;
  --formcontrol-checkradio-borderradius:        4px;
  --formcontrol-radio-innersize:                14px;

  --formcontrol-check-shift:                    3px;
  --formcontrol-radio-shift:                    0px;

  --formcontrol-checkradio-checked-color:       #FFFFFF;
  --formcontrol-checkradio-checked-bgcolor:     var(--formcontrols-themecolor);
/*  --formcontrol-checkradio-checked-bordercolor: var(--formcontrols-themecolor);*/
  --formcontrol-checkradio-disabled-bgcolor:    #F2F2F2;

  /* Because of our greenish background,
     we need a white border for contrast. */
  --formcontrol-checkradio-checked-bordercolor: rgba(255, 255, 255, 0.5);
}

@media (max-width: 639px)
{
  .site-debugbar
  {
    display: flex;
    flex-direction: column;
/*    column-gap: 20px;*/
    row-gap: 20px;

    font-size: 14px;

    --textcontrols-font:     12px Arial;

    --formcontrols-height:   25px;
    --textcontrols_padleft:  5px;
    --textcontrols_padright: 5px;
  }

  .site-debugbar-item select
  {
    min-width: 100%;
  }
}

@media (min-width: 640px)
{
  .site-debugbar
  {
    display: flex;
    column-gap: 20px;

    font-size: 16px;

    --formcontrols-height:   35px;
    --textcontrols_padleft:  8px;
    --textcontrols_padright: 8px;
  }
}

.site-debugbar-item
{

}

.site-debugbar-item select
{
  cursor: pointer;
  padding: 5px 5px;
}

.site-debugbar-item label
{
  display: block;
  color: #FFFFFF;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.site-debugbar-item label .keybinding
{
  margin-left: 25px;
  opacity: 0.6;
  font-size: 75%;
}

wh-outputtools
{
  left: 5px !important;
  top: 5px;
}



.site-debugbar-item--withmenu
{
  align-self: end;
/*  outline: 1px solid #F00;*/
}

.site-debugbar-menubar
{
  list-style: none;
  margin: 0;
  padding: 0;

  flex: none;

  height: var(--formcontrols-height);

  display: flex;
}

.site-debugbar-menubar a
{
  color: inherit;
  text-decoration: none;
}

.site-debugbar-menubar a:hover
{
/*  background: rgba(255,255,255,0.5);*/
  background: rgba(0,0,0,0.4);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
}

.site-debugbar-menubar li
{
  margin: 0;
  padding: 0;

  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.site-debugbar-menubar > li > .spc-debugbar-menuitem
{
  background: rgba(255, 255, 255, 0.1);
  padding: 2px 10px;
  height: 100%;

  transition: background 0.15s;

  display: flex;
  align-items: center;
  justify-content: center;
}

  .site-debugbar-menubar > li > .spc-debugbar-menuitem:hover
, .site-debugbar-menubar > li:hover > .spc-debugbar-menuitem
{
/*  background: rgba(0, 0, 0, 0.1);*/
  background: #FFFFFF;
  color: #000000;
}

.site-debugbar-pulldown
{
  width: 100%; /* This willk force to the next line + make the width inheritable */
}

.site-debugbar-pulldown > ul
{
  position: absolute;
  right: 0;

  list-style: none;
  margin: 0;
  padding: 0;

  width: max-content;
  min-width: clamp(100%, 230px, calc(100vw - 60px)); /* use the width of the flex container so we are at minimum as wide as our parent */

  background: rgba(0,64,0,0.7);
  border: 1px solid #FFFFFF;
  box-shadow: 2px 2px 6px rgba(0,0,0,0.5);

  display: none;
}

.site-debugbar-menubar li:hover .site-debugbar-pulldown > ul
{
  display: flex;
  flex-direction: column;
}

  .site-debugbar-pulldown ul a
, .site-debugbar-pulldown ul .menuitem--toggle
{
  display: flex;
  flex: 1 0 auto;
  padding: 5px 15px;

  min-height: 40px;

  cursor: pointer;
}

.site-debugbar-pulldown hr
{
  width: 100%;
  margin: 5px 15px;
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.site-debugbar-pulldown ul .menuitem--toggle input
{
  margin-right: 15px;
}



/*html.debug-forcemenuopen*/
html.debug-forcemenuopen .spc-menubar__pulldown[hidden]
{
  display: block !important;
}

/*
.spc-menubar__level3, .spc-menubar__level4 {
    height: 0;
    overflow: hidden;
}
*/

/* Submenu's nested within a pulldown */
html.debug-forcemenuopen .spc-menubar__pulldown ul
{
  height: auto !important;
  overflow: visible;
  display: block !important; /* override the hidden attribute */

  appearance: none;
}
