/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
/* horizontal perfect scrollbar */
.timetable-scrollbar-container {
  padding-top: 25.5px;
}
.timetable-scrollbar-container.nativescroll {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.timetable-scrollbar-container .ps__rail-x {
  margin-right: 10px;
  height: 6px;
  border-radius: 3px;
  opacity: 1;
  cursor: pointer;
}
@media (max-width: 639px) {
  .timetable-scrollbar-container .ps__rail-x {
    margin-left: 50px;
  }
}
@media (min-width: 640px) {
  .timetable-scrollbar-container .ps__rail-x {
    margin-left: 100px;
  }
}
.timetable-scrollbar-container .ps__rail-x > .ps__thumb-x {
  top: 50%;
  margin-top: -3px;
  height: 6px;
  border-radius: 5.5px;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.22);
}

.timetable-scrollbar-container {
  position: relative;
  height: 57px;
  background-color: rgba(0, 0, 0, 0.41);
}

.timetable-scrollbar-container .ps__rail-x {
  opacity: 1;
  display: block;
  position: relative;
  top: auto;
  left: auto;
}