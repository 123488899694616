/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
/* voor de fake handschrift titels */
.coursematerial {
  margin-top: 30px;
}

.coursematerial__item .ctadownloadbutton::before {
  font-size: 20px;
  margin-right: 12px;
  margin-top: 2px;
}

.coursematerial__downloads {
  margin-bottom: 20px;
}

.coursematerial__description {
  margin-bottom: 20px;
}

.coursematerial__image {
  /* don't stretch */
  align-self: flex-start;
}

@media (max-width: 767px) {
  /* we don't need the copy of the image within the textual content */
  .coursematerial__item > .coursematerial__image {
    display: none;
  }
}
@media (min-width: 768px) {
  /* we don't need the copy of the image within the textual content */
  .coursematerial__content > .coursematerial__image {
    display: none;
  }
}
.coursematerial__item + .coursematerial__item {
  border-top: 1px solid #BBBBBB;
}

.coursematerial__item__intro {
  position: relative;
}

/* mobile course material list */
@media (max-width: 767px) {
  .coursematerial__item {
    position: relative; /* pos.container for the toggler */
  }
  .coursematerial__image {
    float: right;
    max-width: 140px;
  }
  .coursematerial__item {
    max-height: 130px;
    overflow: hidden;
  }
}
/* tablet/desktop course material list */
@media (min-width: 768px) {
  .coursematerial__item {
    display: flex;
    position: relative; /* pos.container for the toggler */
  }
  .coursematerial__content {
    flex: 1;
  }
  .coursematerial__image {
    flex-shrink: 0;
    margin-right: 75px; /* room for the toggle */
    margin-top: 20px;
  }
  .coursematerial__item {
    max-height: 130px;
    overflow: hidden;
  }
}
.coursematerial__item.wh-toggledelement--active {
  max-height: none;
}

.coursematerial__item:not(.wh-toggledelement--active):active,
.coursematerial__item:not(.wh-toggledelement--active):hover {
  background-color: var(--color-purple);
  border-color: var(--color-purple);
  color: #FFFFFF;
}

.coursematerial__item:not(.wh-toggledelement--active):active .coursematerial__toggler::before,
.coursematerial__item:not(.wh-toggledelement--active):hover .coursematerial__toggler::before {
  color: #FFFFFF !important;
}

.coursematerial__item:not(.wh-toggledelement--active):active .coursematerial__title,
.coursematerial__item:not(.wh-toggledelement--active):hover .coursematerial__title {
  color: #FFFFFF !important;
}

.coursematerial__fullcontent {
  display: none;
}

.coursematerial__item.wh-toggledelement--active .coursematerial__fullcontent {
  display: block;
}

.coursematerial__item.wh-toggledelement--active .coursematerial__previewdescription {
  display: none;
}

#coursematerial__tab--0 .coursematerial__title {
  color: var(--color-magenta);
}

#coursematerial__tab--0 .coursematerial__item:not(.wh-toggledelement--active):active,
#coursematerial__tab--0 .coursematerial__item:not(.wh-toggledelement--active):hover {
  background-color: var(--color-magenta);
}

#coursematerial__tab--1 .coursematerial__title {
  color: var(--color-green);
}

#coursematerial__tab--1 .coursematerial__item:not(.wh-toggledelement--active):active,
#coursematerial__tab--1 .coursematerial__item:not(.wh-toggledelement--active):hover {
  background-color: var(--color-green);
}

#coursematerial__tab--2 .coursematerial__title {
  color: #fec62e;
}

#coursematerial__tab--2 .coursematerial__item:not(.wh-toggledelement--active):active,
#coursematerial__tab--2 .coursematerial__item:not(.wh-toggledelement--active):hover {
  background-color: #fec62e;
}

#coursematerial__tab--3 .coursematerial__title {
  color: #e47205;
} /* orange from NVB */
#coursematerial__tab--3 .coursematerial__item:not(.wh-toggledelement--active):active,
#coursematerial__tab--3 .coursematerial__item:not(.wh-toggledelement--active):hover {
  background-color: #e47205;
}

#coursematerial__tab--4 .coursematerial__title {
  color: var(--color-purple);
}

#coursematerial__tab--4 .coursematerial__item:not(.wh-toggledelement--active):active,
#coursematerial__tab--4 .coursematerial__item:not(.wh-toggledelement--active):hover {
  background-color: var(--color-purple);
}

#coursematerial__tab--5 .coursematerial__title {
  color: var(--color-magenta);
} /* ?? */
#coursematerial__tab--5 .coursematerial__item:not(.wh-toggledelement--active):active,
#coursematerial__tab--5 .coursematerial__item:not(.wh-toggledelement--active):hover {
  background-color: var(--color-magenta);
}

#coursematerial__tab--6 .coursematerial__title {
  color: #4343c3;
}

#coursematerial__tab--6 .coursematerial__item:not(.wh-toggledelement--active):active,
#coursematerial__tab--6 .coursematerial__item:not(.wh-toggledelement--active):hover {
  background-color: #4343c3;
}