/*
FIX voor ZVI
--filtertag-gap-h/v
  color: $feedbackbar-tag-hover-text-color;
  border-color: $feedbackbar-tag-hover-outline-color; --> --feedbackbar-tag-hover-border: 1px solid var()

  background: var(--feedbackbar-tag-hover-background);
*/
/* JZOJP
html
{
  --feedbackbar-tag-background:    #FFFFFF;
  --feedbackbar-tag-font:          300 14px var(--rtd-text-fontfamily);
  --feedbackbar-tag-height:        29px;
  --feedbackbar-tag-border:        1px solid $feedbackbar-tag-outline-color;
  --feedbackbar-tag-borderradius:  16px;
  --feedbackbar-tag-text-color:    #8E8E8E;
}


html
{
  // ZVI
  --filtertag-gap-h: 15px;
  --filtertag-gap-v: 15px;

  --feedbackbar-tag-background:    #F3F3F3;
  --feedbackbar-tag-font:          15px var(--rtd-text-fontfamily);
  --feedbackbar-tag-height:        34px;
  --feedbackbar-tag-border:        none;
  --feedbackbar-tag-borderradius:  3px;
  --feedbackbar-tag-text-color:    #000000;
  --feedbackbar-tag-padding-h:     12px;

  --feedbackbar-tag-hover-background: #EBEBEB;

$feedbackbar-tag-outline-color: #8E8E8E;
$feedbackbar-tag-hover-outline-color: var(--color-theme);
  --feedbackbar-tag-hover-text-color:    var(--color-theme);
}
*/
html {
  --filtertag-gap-h: 10px;
  --filtertag-gap-v: 10px;
  --feedbackbar-tag-background: #FFFFFF;
  --feedbackbar-tag-font: 14px var(--rtd-text-fontfamily);
  --feedbackbar-tag-height: 29px;
  --feedbackbar-tag-border: 1px solid var(--color-green);
  --feedbackbar-tag-borderradius: 16px;
  --feedbackbar-tag-text-color: #3F3F3F;
  --feedbackbar-tag-padding-h: 14px;
  --feedbackbar-tag-hover-background: #FFFFFF;
  --feedbackbar-tag-hover-text-color: var(--color-green);
  --feedbackbar-tag-hover-border: 1px solid var(--color-green);
}

/* --filteredoverview-feedbackbar */
/*
.filteredoverview__feedbackandviewbar
{
  grid-template-columns: var(--pagegrid-template-columns);
  grid-column: var(--pagegrid-col-default);

  color: var(--common-caption-color);
  padding-top: 4px;

  margin-bottom: 20px;
  font: 18px/22px var(--rtd-heading-fontfamily);
}

@media (max-width: 767px)
{
  .filteredoverview__feedbackandviewbar
  {
    font: 16px/19px var(--rtd-heading-fontfamily);
  }
}

.filteredoverview__feedback
{
  //font: bold 16px/24px var(--rtd-heading-fontfamily);
  color: #3F3F3F;

  margin-right: 15px;
}


.filteredoverview__feedbackandviewbar__content
{
  display: flex;
  flex-wrap: wrap; // if the tags don't fix on a single line, show the below the label
  align-items: baseline;
}
*/
.filteredoverview__filtertags {
  display: none;
}

.filteredoverview--filtersactive .filteredoverview__filtertags {
  display: block;
}

.filtertags__title {
  flex: none;
}

.filtertags__items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: var(--filtertag-gap-h);
  row-gap: var(--filtertag-gap-v);
}

/*
.filtertags__items
{
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  margin-top:    -$filtertag_gap_v * 0.5;
  margin-bottom: -$filtertag_gap_v * 0.5;

  margin-left:   -$filtertag_gap_h;
}

.filtertags__item
{
  margin-left:   $filtertag_gap_h;
  margin-top:    $filtertag_gap_v * 0.5;
  margin-bottom: $filtertag_gap_v * 0.5;
}
*/
.filtertags__item {
  display: inline-flex;
  align-items: center;
  min-height: var(--feedbackbar-tag-height);
  padding-top: 4px;
  padding-bottom: 4px;
  font: var(--feedbackbar-tag-font);
  background-color: var(--feedbackbar-tag-background);
  border: var(--feedbackbar-tag-border);
  border-radius: var(--feedbackbar-tag-borderradius);
  color: var(--feedbackbar-tag-text-color);
  padding-left: var(--feedbackbar-tag-padding-h);
  padding-right: var(--feedbackbar-tag-padding-h);
  cursor: pointer;
  text-decoration: none;
  max-width: 100%;
}

.filtertags__item:hover {
  color: var(--feedbackbar-tag-hover-text-color);
  border: var(--feedbackbar-tag-hover-border);
  background: var(--feedbackbar-tag-hover-background);
}

.filtertags__item::after {
  margin-left: 16px;
  font: var(--fontawesome-light);
  content: var(--fa-times);
  font-size: 14px;
  color: #000000;
  margin-top: 4px;
}

.filtertags__item:hover::after {
  color: var(--color-theme);
}

.filtertags__item__remove {
  align-self: center;
  padding: 0 8px 0 8px;
  font-size: 9px;
}