/*

.wh-timetable-time
.wh-timetable-time.label_hide - the available area is deemed too small to fit any label
.wh-timetable-time.label_ellipsis - when a horizontal overflow keeps happening, this is probably due to a long word which cannot be wrapped
.wh-timetable-time.label-ellipsis-multiline - when after font-size scaling we still have vertical overflow

*/
.wh-timetable {
  position: relative;
  z-index: 0; /* new stacking context */
}

.wh-timetable-viewport {
  position: relative; /* keep scrollport/content and locations/labels within same space */
  width: 100%;
}

.wh-timetable-scrollport {
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}

.wh-timetable-content {
  position: relative;
  /*  width: 200%;*/
}

.wh-timetable-header {
  position: relative;
  overflow: hidden;
  padding-left: 9px;
  /*
    transform: translate3d(0,0,0);
    -webkit-backface-visibility: hidden;
  */
}

.wh-timetable-header .wh-timetable-timeindicator {
  padding-left: 8px;
}

/* location label overlays */
.wh-timetable-rowlabels {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wh-timetable-row {
  position: relative;
}

/*
.wh-timetable-content .wh-timetable-row:nth-child(odd)
{
  background-color: #FFFFFF;
}
.wh-timetable-content .wh-timetable-row:nth-child(even)
{
  background-color: #F6F6F6;
}
*/
.wh-timetable-label {
  display: flex;
  height: 100%;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.wh-timetable-time {
  position: absolute;
  left: 0;
  padding: 9px;
  min-width: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wh-timetable-time.whtt-allowmultiline {
  white-space: normal;
}

.wh-timetable-time.label-ellipsis-multiline span {
  /*
  This work on:
  - Chrome 14+  (sep 2011)
  - Edge 17+    (apr 2018)
  - Safari 5+   (mar 2012)
  - iOS 5+      (mar 2012)
  - Firefox 68+ (jul 2019)

  Also see
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102 - Support for -webkit-line-clamp
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992 - Support for a future max-lines property
  - https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.wh-timetable-time > * {
  vertical-align: middle;
}

.wh-timetable-timeindicator {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.wh-timetable-prev,
.wh-timetable-next {
  z-index: 5;
}

.wh-timetable-header {
  z-index: 4;
}

.wh-timetable-rowlabels {
  z-index: 3;
}

.wh-timetable-time {
  z-index: 2;
}

.wh-timetable-timeindicator {
  z-index: 1;
}