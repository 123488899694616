/*
We need to group
- so we can show the metadata (when, where) visually above the title
 while keeping it below the title in the DOM for screenreaders.
*/

.contentheader
{
  display: flex;
  flex-direction: column;

  position: relative;
}

.contentheader .minfinMetaSheet
{
  order: -1;

  display: flex;
  column-gap: 20px;
  flex-wrap: wrap;

  margin: 0 0 4px 0;
  --widget-itemslist-item-icon-color:     var(--rtd-heading-color);
  --widget-itemslist-item-metadata-color: var(--rtd-heading-color);
  --widget-itemslist-item-metadata-icon-margin: 7px;

/*  .icon*/
  dt
  {
    flex: none;
    display: block;
    width: 22px;

    display: flex;
    justify-content: center;
  }
}

.page__newsimage
{
  margin-top: var(--rtd-spacing-paragraph);
  max-width: 100%;
  height: auto;
}

.contentheader
{
  --pageicon-width-max: 65px;
  --pageicon-height-max: 65px;

  .pageheader__icon
  {
    --themecolor: var(--rtd-heading-color);

    position: absolute;
    left: -35px;
    transform: translateX(-100%);
    top: 0;
/*    width: 120px;*/
/*    height: 120px;*/
/*    outline: 1px solid #F00;*/

    @media (max-width: 1200px)
    {
      display: none !important;
    }
  }
}
