/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
@media (min-width: 800px) {
  html.page--home .content__widgetscolumn {
    background: linear-gradient(to bottom, rgb(255, 255, 255) 0, rgba(255, 255, 255, 0) 100px), linear-gradient(to top, rgb(255, 255, 255) 0, rgba(255, 255, 255, 0) 100px), linear-gradient(to right, rgb(235, 235, 235) 0, rgba(235, 235, 235, 0) 20px); /* gradient at the left */
    /* prevent overlap of widgetscolumn gradient with the footer background */
    /* margin-bottom: 75px; */
  }
  .page--home .widget--widgetsgroup + .widget--widgetsgroup {
    margin-top: 40px;
  }
}
/* mobile/tablet */
@media (max-width: 799px) {
  .page--home .widget--widgetsgroup + .widget--widgetsgroup {
    margin-top: 20px;
  }
}
.widget--largecta {
  /*
    min-height: 307px;
  */
  margin-top: 22px; /* not a default margin ... $margin-v */
  overflow: hidden; /* cut off the rounded corners */
}

.widget--largecta__body {
  --rtd-link-color: #FFFFFF;
  --rtd-text-color: #FFFFFF;
}

.widget--largecta__media .widget--image__playbutton {
  background: none;
  border: 0;
  padding: 20px; /* increase hitarea */
}

@media (max-width: 767px) {
  .widget--largecta__media,
  .widget--largecta__body {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .widget--largecta {
    display: flex;
    border-radius: 5px;
  }
  .widget--largecta__media {
    flex-grow: 1;
    /*
        position: relative;
        padding-top: 56,25%;
    */
  }
  .widget--largecta__body {
    width: 315px;
    flex-shrink: 0;
    display: flex;
  }
  .widget--largecta__bodycontent {
    align-self: center;
  }
}
.widget--largecta__mediaimage {
  position: relative; /* for positioning of the video playbutton */
  display: block; /* in case it's an <a> */
  min-height: 307px;
  height: 100%;
  background-size: cover;
}

.widget--largecta__body {
  color: #FFFFFF;
}

/* requested 10 jan 2017 */
@media (max-width: 640px) {
  .widget--largecta--hidemediaonmobile .widget--largecta__mediaimage {
    display: none;
  }
}