/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
html:not(.styling2025) .sitefooter--gl {
  --button-height: 41px;
  --button-radius: 4px;
  --footer-heading-font: 600 20px/26px var(--rtd-heading-fontfamily);
  --footer-sociallinks-heading-font: bold 18px/22px "TypoPRO Dosis Buttons", "Dosis", Arial;
  --footer-sociallinks-item-spacing: 14px;
  --footer-sociallinks-item-size: 38px;
  --footer-sociallinks-item-border: 2px solid var(--themecolor);
  /*
  .sitefooter__newsletter__icon .far
  {
    color: #FFFFFF;
    font-size: 18px;
  }
  */
}
html:not(.styling2025) .sitefooter--gl .body__widgetsbottom + .sitefooter {
  margin-top: 0;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__panel--links {
  display: none;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__panel__buttonpanels {
  display: contents;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__button {
  display: inline-flex;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: var(--button-height);
  padding: 5px 20px 5px 20px;
  font: var(--button-font);
  border-radius: var(--button-borderradius);
  cursor: pointer;
  border-radius: var(--button-radius);
  text-decoration: none;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  font: var(--button-font);
  font: 17px var(--rtd-heading-fontfamily);
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.15);
}
html:not(.styling2025) .sitefooter--gl .sitefooter__button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #FFFFFF;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__button:after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: var(--fa-chevron-right);
  content: var(--fa-chevron-right)/"";
  font-size: 13px;
  vertical-align: middle;
  margin-left: 35px;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__button + .sitefooter__button {
  margin-top: 15px;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__panel {
  margin-top: 17px;
  margin-bottom: 17px;
  color: #FFFFFF;
  background: var(--color-theme-background-highcontrast);
  display: grid;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__panel__content {
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  max-width: var(--pagegrid-contentarea-width-full);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
}
html:not(.styling2025) .sitefooter--gl .sitefooter__heading {
  color: #FFFFFF;
  font: var(--footer-heading-font);
}
html:not(.styling2025) .sitefooter--gl .sitefooter__panel--identity {
  margin-top: -17px;
  margin-bottom: -17px;
  background-color: #FFFFFF;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__panel--identity__content {
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__panel--identity__gl {
  color: var(--color-purple);
  font: 400 18.4px var(--rtd-heading-fontfamily);
  padding-bottom: 20px;
  position: relative;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__panel--identity__gl__text {
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: right;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__panel--identity__gl__image {
  width: 250px;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__panel--identity__wig {
  text-align: right;
  margin-top: 10px;
  margin-left: auto;
  font: 11px var(--rtd-heading-fontfamily);
  color: var(--color-purple);
  text-decoration: none;
  display: inline-flex;
  align-items: flex-end;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__panel--identity__wig img {
  width: 100px;
  margin-left: 5px;
}
@media (max-width: 974px) {
  html:not(.styling2025) .sitefooter--gl .sitefooter__panel--identity {
    padding: 20px 0;
  }
}
@media (min-width: 975px) {
  html:not(.styling2025) .sitefooter--gl .sitefooter__panel--identity {
    padding: 20px 25px;
  }
}
@media (min-width: 975px) {
  html:not(.styling2025) .sitefooter--gl {
    /*
    .sitefooter__panel__content
    {
      display: flex;
      align-items: center;
    }

    .sitefooter__panel--identity
    {
      flex: none;
    }

    .sitefooter__panel--newsletter
    {
      flex: 1 1 calc(100% / 3);
    }

    .sitefooter__panel--submit
    {
      margin-left: 30px;
      flex: 1 1 calc(100% / 3);
    }
    */
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__panel__content {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: 1fr min-content 1fr;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__panel--identity {
    grid-column: 1;
    grid-row: 1/span 3;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__panel--newsletter {
    grid-column: 2;
    grid-row: 2;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__panel--submit {
    grid-column: 3;
    grid-row: 2;
  }
}
html:not(.styling2025) .sitefooter--gl .sitefooter__social {
  margin-top: 30px;
  text-align: center;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__social .sitefooter__heading {
  margin-top: 0;
  color: var(--color-purple);
  font-family: bold 20px "TypoPRO Dosis Buttons", "Dosis", Arial;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__socialitem .fab {
  color: var(--themecolor);
  font-size: 20px;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__socialitem .fab.fa-youtube {
  font-size: 18px;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__bottombar {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: left;
  /*
  - when we are on a single line the max-content will shrink wrap.
    (preventing each flex-item from growing and using the text-align: center;)
  - when flex wraps the flex container will use the maximum width it can get,
    so when this happens we use the max-width (and our "-" will be positioned outside
    the container and will be clipped).
    Also the content of each row will be centered using text-align: center;
  */
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  max-width: calc(100% - 60px); /* instead of using padding - so we can clip the padding area */
  column-gap: 18px; /* to make space for the "-" divider */
  /* Clip the "-", which is placed in our padding
     when the __bottomlinks wraps (and takes the full width)
  */
  overflow: hidden;
  /* Prevent the a:focus being clipped */
  padding-bottom: 3px;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__bottombar__copyright, html:not(.styling2025) .sitefooter--gl .sitefooter__bottomlinks {
  /* the max-content of the .sitefooter__bottombar wrapper will shrink-wrap
     for single-line situations... when the flex wraps this flex-grow: 1;
     kicks in and our rows will use the full width
  */
  flex: 1 0 auto;
  text-align: center; /* kicks in when the __bottomlinks wraps to the second row */
}
html:not(.styling2025) .sitefooter--gl .sitefooter__bottombar__copyright {
  white-space: nowrap;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__bottomlinks {
  position: relative; /* so we can absolute position the seperator (::before)
  max-width: 100%;
}

.sitefooter__bottomlinks::before
{
  position: absolute;
  left: -10px; /* when __bottomlinks is wrapped (and fullwidth) -10px will be in the clipped area of sitefooter__bottombar) */
  content: "-";
}
html:not(.styling2025) .sitefooter--gl .sitefooter__bottombar__copyright, html:not(.styling2025) .sitefooter--gl .sitefooter__bottomlinks__items {
  font: 13px var(--rtd-text-fontfamily);
}
html:not(.styling2025) .sitefooter--gl .sitefooter__bottomlinks__items a {
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  white-space: nowrap;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__bottombar__copyright {
  color: #333333;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__bottomlinks a {
  color: var(--themecolor);
  text-decoration: none;
  padding-left: 6px;
  padding-right: 6px;
  text-decoration: underline;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__bottomlinks a:hover {
  text-decoration: underline;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__social {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
html:not(.styling2025) .sitefooter--gl .sitefooter__social__links {
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 669px) {
  html:not(.styling2025) .sitefooter--gl .sitefooter {
    --footer-heading-font: 500 22px var(--rtd-heading-fontfamily);
    margin-top: 45px;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__panel__background {
    margin-top: 30px;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__panel__content {
    padding-bottom: 40px;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__panel--newsletter, html:not(.styling2025) .sitefooter--gl .sitefooter__panel--submit {
    margin-top: 40px;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__newsletter__submitbar input {
    display: block;
    width: 100%;
    border-radius: 5px;
    border: 0;
    height: 41px;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__newsletter__submitbar button {
    appearance: none;
    -webkit-appearance: none;
    padding: 0;
    border: 0;
    background-color: transparent;
    background-color: var(--color-purple);
    color: #FFFFFF;
    font: 18px var(--rtd-heading-fontfamily);
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    display: block;
    width: 100%;
    height: 41px;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__heading {
    text-align: center;
    margin-top: 0;
    margin-bottom: 15px;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__panel--submit .sitefooter__button {
    width: 100%;
    justify-content: space-between;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__social {
    margin-top: 40px;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__bottombar {
    margin-top: 40px;
    margin-bottom: 20px;
  }
}
@media (min-width: 670px) {
  html:not(.styling2025) .sitefooter--gl .sitefooter {
    --footer-heading-font: 600 20px/26px var(--rtd-heading-fontfamily);
    margin-top: 95px;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__panel__background {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__panel--newsletter, html:not(.styling2025) .sitefooter--gl .sitefooter__panel--submit {
    margin-left: 30px;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__newsletter__submitbar {
    display: flex;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__newsletter__submitbar input {
    flex: 1 0 0px;
    min-width: 0;
    border-radius: 5px 0 0 5px;
    border: 0;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__newsletter__submitbar button {
    appearance: none;
    -webkit-appearance: none;
    padding: 0;
    border: 0;
    background-color: transparent;
    background-color: var(--color-purple);
    color: #FFFFFF;
    font: 17px var(--rtd-heading-fontfamily);
    border-radius: 0 5px 5px 0;
    padding-left: 10px;
    padding-right: 10px;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__heading {
    margin-top: 0;
    margin-bottom: 15px;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__bottombar {
    margin-top: 45px;
    margin-bottom: 45px;
  }
}
@media (min-width: 670px) and (max-width: 974px) {
  html:not(.styling2025) .sitefooter--gl .sitefooter__panel__content {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content min-content;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__panel--identity {
    grid-column: 1;
    grid-row: 1/span 2;
    padding: 20px 20px;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__panel--newsletter {
    padding-top: 40px;
    grid-column: 2;
    grid-row: 1;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__panel--submit {
    grid-column: 2;
    grid-row: 2;
    padding-bottom: 45px;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__panel--newsletter + .sitefooter__panel--submit {
    margin-top: 30px;
  }
  html:not(.styling2025) .sitefooter--gl .sitefooter__panel--submit .sitefooter__button {
    width: 100%;
  }
}