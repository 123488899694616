/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
/* was 880px, maar met pulldown toggles hebben we meer ruimte nodig en toen een tussenoplossing voor het menu bedacht waarmee juist 768px (iPad) ook past */
html:where(.styling2025) {
  --site-topbar-height: 101px;
  --site-menubar-height: 60px;
}
@media (max-width: 767px) {
  html:where(.styling2025) {
    --site-topbar-height: 48px;
    --site-menubar-height: 48px;
  }
}
html:where(.styling2025) .siteheader-top__content {
  width: 100%;
  max-width: var(--pagegrid-contentarea-width-full);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
}
@media (max-width: 767px) {
  html:where(.styling2025) .siteheader-top__content {
    display: flex;
  }
  html:where(.styling2025) .siteheader__showsidebartoggle {
    margin-left: -7px;
  }
  html:where(.styling2025) .siteheader-top .siteheader-identity__gl {
    display: none;
  }
  html:where(.styling2025) a.siteheader-menubar__logo {
    align-self: center;
  }
  html:where(.styling2025) .siteheader-menubar__logo img {
    display: block;
    height: 40px;
  }
}
@media (min-width: 768px) {
  html:where(.styling2025) .siteheader-top, html:where(.styling2025) .siteheader-top__content {
    height: var(--site-topbar-height);
  }
  html:where(.styling2025) .siteheader-top__content {
    display: flex;
    align-items: center;
  }
  html:where(.styling2025) .siteheader-identity__gl img {
    display: block;
    height: 88px;
  }
  html:where(.styling2025) .siteheader-top__right {
    margin-bottom: 10px;
  }
  html:where(.styling2025) .siteheader-menubar__logo {
    display: none;
  }
}