/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
.tabcontrol {
  position: relative;
}

@media (max-width: 767px) {
  /* groene omlijning om de gehele container */
  .tabcontrol {
    border: 2px solid #0db24c;
    border-radius: 5px;
    overflow: hidden; /* cut off the arrow thingy */
  }
  .tabcontrol__tabstrip {
    display: flex;
    flex-flow: row wrap; /* flex-wrap: wrap */
    width: calc(100% + 5px);
    padding: 10px;
    /*margin-bottom: -20px; eat the margin-bottom of the .widget's on the last row */
  }
  .tabcontrol__tabstrip__tab {
    flex-basis: calc(25% - 5px);
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .tabcontrol__tabstrip__tab__inner {
    font: 16px/32px Arial;
    height: 32px;
    text-align: center;
    background-color: #ebebeb;
    border-radius: 5px;
    cursor: pointer;
  }
  .tabcontrol__tabstrip__tab.selected .tabcontrol__tabstrip__tab__inner {
    background-color: var(--color-green);
    color: #FFFFFF;
  }
  .tabcontrol__pointer {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 37px;
    overflow: hidden;
    padding-bottom: 22px;
    /*margin-bottom: -22px;*/
  }
  .tabcontrol__pointer::before {
    content: "";
    display: block;
    border-top: 2px solid var(--color-green);
  }
  .tabcontrol__pointer::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: 50px;
    margin-left: -25px;
    height: 50px;
    margin-top: -28px;
    transform: rotate(45deg);
    background-color: #FFFFFF;
    border: 2px solid var(--color-green);
  }
  #coursematerial__tab--0 .widget--padding {
    padding-top: 44px;
  }
}
@media (min-width: 768px) {
  /****/
  .tabcontrol__tabstrip__tab.selected .tabcontrol__tabstrip__tab__inner,
  .tabcontrol__tabpanel {
    box-shadow: 0 0.5px 3px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background-color: #FFFFFF;
  }
  .tabcontrol__tabstrip__tab.selected .tabcontrol__tabstrip__tab__inner,
  .tabcontrol__tabpanel {
    border: 2px solid #0db24c;
  }
  /****/
  .tabcontrol__tabstrip {
    display: flex;
    margin-bottom: -2px;
  }
  .tabcontrol__tabstrip__tab {
    height: 41px; /* 36 + 5px padding */
    padding: 5px 5px 0 5px;
    margin-left: -5px; /* to compensate for the padding containing the box-shadow */
    overflow: hidden;
    cursor: pointer;
  }
  .tabcontrol__tabstrip__tab__inner {
    font: 16px/36px Arial;
    background-color: #ebebeb;
    padding: 0 15px;
    border-radius: 5px 5px 0 0 !important;
  }
  .tabcontrol__tabstrip__tab + .tabcontrol__tabstrip__tab {
    margin-left: -3px;
  }
  .tabcontrol__tabstrip__tab.selected {
    /* overlap tab */
    position: relative;
    z-index: 1;
  }
  .tabcontrol__tabstrip__tab.selected .tabcontrol__tabstrip__inner {
    line-height: 34px;
  }
}
.tabcontrol__tabpanel {
  display: none;
  position: relative; /* overlap non-selected tab's (but selected tabs's do overlap using z-index: 1;) */
  border-top-left-radius: 0 !important;
}

.tabcontrol__tabpanel.selected {
  display: block;
}