/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
.sitefooter--wvhg .sitefooter__panel {
  background: var(--footer-panel-background);
  color: #FFFFFF;
}
.sitefooter--wvhg .sitefooter__abovepanel {
  display: flex;
  justify-content: space-between;
}
.sitefooter--wvhg .sitefooter__social {
  display: flex;
  align-items: center;
}
.sitefooter--wvhg .sitefooter__social__links {
  margin-left: 15px;
  display: flex;
}
.sitefooter--wvhg .sitefooter__socialitem {
  display: block;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  background-color: #FFFFFF;
  border-radius: 50%;
  cursor: pointer;
  text-decoration: none;
}
.sitefooter--wvhg .sitefooter__socialitem + .sitefooter__socialitem {
  margin-left: 13px;
}
.sitefooter--wvhg .sitefooter__socialitem .fab {
  color: var(--color-green);
  font-size: 20px;
}
.sitefooter--wvhg .sitefooter__socialitem .fab.fa-youtube {
  font-size: 18px;
}
.sitefooter--wvhg .sitefooter__socialitem:hover {
  background-color: #050;
}
.sitefooter--wvhg .sitefooter__socialitem:hover .fab {
  color: #FFFFFF;
}
.sitefooter--wvhg .sitefooter__bottombar {
  text-align: left;
  font-size: 0; /* so the "|" char can be made invisible when spanning two lines */
}
.sitefooter--wvhg .sitefooter__bottombar__copyright {
  white-space: nowrap;
}
.sitefooter--wvhg .sitefooter__bottombar > .metaitem {
  white-space: nowrap;
  display: inline-block;
  vertical-align: bottom;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.sitefooter--wvhg .sitefooter__bottomlinks::before {
  content: "-";
}
.sitefooter--wvhg .sitefooter__bottombar__copyright, .sitefooter--wvhg .sitefooter__bottomlinks__items,
.sitefooter--wvhg .sitefooter__bottombar:first-line {
  font: 13px var(--rtd-text-fontfamily);
}
.sitefooter--wvhg .sitefooter__bottomlinks__items a {
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
}
.sitefooter--wvhg .sitefooter__bottombar__copyright {
  color: #333333;
  margin-right: 6px;
}
.sitefooter--wvhg .sitefooter__bottomlinks a {
  color: #333333;
  text-decoration: none;
  padding-left: 6px;
  padding-right: 6px;
}
.sitefooter--wvhg .sitefooter__bottomlinks a:hover {
  text-decoration: underline;
}
@media (max-width: 669px) {
  .sitefooter--wvhg .sitefooter__panel__content {
    display: grid;
    grid-template-columns: 1fr minmax(100%, var(--pagegrid-contentarea-width-full)) 1fr;
    grid-template-rows: min-content min-content min-content min-content min-content;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .sitefooter--wvhg .sitefooter__panel__identity {
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
  }
  .sitefooter--wvhg .sitefooter__panel__identity img {
    display: block;
    height: 75px;
    height: clamp(75px, 14vw, 115px);
  }
  .sitefooter--wvhg .sitefooter__when {
    grid-column: 2;
    grid-row: 2;
    justify-self: center;
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    justify-content: center;
    font: bold 22px var(--rtd-heading-fontfamily);
  }
  .sitefooter--wvhg .sitefooter__social {
    margin-top: 20px;
    grid-column: 2;
    grid-row: 3;
    justify-self: center;
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
  }
  .sitefooter--wvhg .sitefooter__social .sitefooter__heading {
    display: none;
  }
  .sitefooter--wvhg .sitefooter__socialitem {
    width: 34px;
    height: 34px;
  }
  .sitefooter--wvhg .sitefooter__panel--identity__wig {
    margin-top: 15px;
    margin-bottom: 14px;
    grid-column: 2;
    grid-row: 4;
    justify-self: center;
    display: flex;
    font: 14px var(--font-heading-fontfamily);
    color: #FFFFFF;
    text-decoration: none;
  }
  .sitefooter--wvhg .sitefooter__panel--identity__wig br {
    display: none;
  }
  .sitefooter--wvhg .sitefooter__identity__wiglogo {
    display: block;
    height: 16px;
    margin-left: 5px;
    transform: translateY(1px);
  }
  .sitefooter--wvhg .sitefooter__panel--identity__wig {
    display: none;
  }
  .sitefooter--wvhg .sitefooter__bottombar {
    grid-column: 2;
    grid-row: 5;
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .sitefooter--wvhg .sitefooter__bottombar__copyright {
    display: none;
  }
  .sitefooter--wvhg .sitefooter__bottomlinks::before {
    display: none;
  }
  .sitefooter--wvhg .sitefooter__bottomlinks {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (min-width: 670px) {
  .sitefooter--wvhg .sitefooter {
    margin-top: 95px;
  }
  .sitefooter--wvhg .sitefooter__abovepanel, .sitefooter--wvhg .sitefooter__panel__content,
  .sitefooter--wvhg .sitefooter__bottombar {
    width: 100%;
    max-width: var(--pagegrid-contentarea-width-full);
    margin-left: auto;
    margin-right: auto;
  }
  .sitefooter--wvhg .sitefooter__panel__content {
    display: grid;
    grid-template-columns: 1fr minmax(100%, var(--pagegrid-contentarea-width-full)) 1fr;
    grid-template-rows: min-content 74px min-content;
  }
  .sitefooter--wvhg .sitefooter__panel__identity {
    grid-column: 2;
    grid-row: 1;
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    justify-content: start;
  }
  .sitefooter--wvhg .sitefooter__panel__identity img {
    display: block;
    height: 140px;
  }
  .sitefooter--wvhg .sitefooter__panel--identity__wig {
    grid-column: 2;
    grid-row: 1;
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    justify-self: end;
    align-self: end;
    margin-bottom: 11px;
    width: min-content;
    text-align: right;
    font: 14px var(--rtd-heading-fontfamily);
    color: var(--color-grey-text-small);
    text-decoration: none;
  }
  .sitefooter--wvhg .sitefooter__identity__wiglogo {
    display: block;
    height: 24px;
  }
  .sitefooter--wvhg .sitefooter__identity__wiglogo--white {
    display: none;
  }
  .sitefooter--wvhg .sitefooter__when {
    grid-column: 2;
    grid-row: 2;
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    justify-self: start;
    align-self: center;
    font: bold 24px var(--rtd-heading-fontfamily);
  }
  .sitefooter--wvhg .sitefooter__social {
    grid-column: 2;
    grid-row: 2;
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    justify-self: end;
    align-self: center;
  }
  .sitefooter--wvhg .sitefooter__social .sitefooter__heading {
    color: #FFFFFF;
    font: bold 20px var(--rtd-heading-fontfamily);
  }
  .sitefooter--wvhg .sitefooter__bottombar {
    grid-column: 2;
    grid-row: 3;
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.sitefooter--wvhg .body__widgetsbottom + .sitefooter {
  margin-top: 0;
}