/*
This stylesheet can NOT be a SASS stylesheet.
It must be useable within the richtext editor (which does not support SASS for includes stylesheets)
*/

@import url(/.publisher/sd/minfin_wig/shared/assets/fontawesome5/css/all.css);
@import url(/.minfin_wig/fonts/@typopro/web-open-sans/typopro-opensans.css);
@import url(/.minfin_wig/fonts/@typopro/web-dosis/typopro-dosis.css);
/*@import url(/.publisher/sd/minfin_wig/shared/fontawesome5-cssvars.css);*/
/*@import url("../../../shared/web/fontawesome5-cssvars.css"); - This works in RTD but not in the assetpack */
/*@import "../../../shared/web/assets/fontawesome5/css/all.css";*/

@import url("../../../shared/web/fontawesome5-cssvars.css");




/* Page layout */
html
{
  /************************************************************
   *   Page layout
   */

  --pagegrid-contentarea-width:     970px;
  --pagegrid-contentarea-padding-h:  15px;
  --pagegrid-contentarea-width-full: 1000px;

  --sidepadding: 15px;

  --pagecontent-columns-gutter: 40px;

  --pagecontent-widgetcolumn-width: 300px; /* 220px for activity and activitiesoverview */

  --site-transitions-duration: 0.2s;
  --button-transition-duration: 0.2s;


  /************************************************************
   *   Page components / Widgets
   */

  --widget-padding-h: 18px;
  --widget-padding-v: 20px;
  --widget-padding-top: 20px;
  --widget-padding-bottom: 20px;

  --widget-expand-h: 15px; /* amount to protrude outside the content area */


  /************************************************************
   *   Page components / Widgets grid
   */

  --widgetsgrid-gutter-x: 20px;
  --widgetsgrid-gutter-y: 20px;


  /************************************************************
   *   Richtext layout
   */

  --rtd-spacing-paragraph:  15px;

  --widget-spacing-inflow:  15px; /* widgets which must look like they just start a new paragraph */
  --widget-spacing-default: 30px;
  --widget-spacing-aside:   50px; /* a widget with it's own standalone content (such as a news / events widget) */
}



/* Basic RTD settings */
html
{
  --rtd-text-fontfamily:    Arial;
  --rtd-heading-fontfamily: "TypoPRO Dosis", "Dosis", Arial;
  --rtd-alternative-fontfamily: "TypoPRO Open Sans";

  --rtd-h1-font:    bold 36px/40px var(--rtd-heading-fontfamily);
  --rtd-h2-font:    bold 24px/28px var(--rtd-heading-fontfamily);
  --rtd-h3-font:    bold 22px/26px var(--rtd-heading-fontfamily);
  --rtd-h4-font:    bold 20px/26px var(--rtd-heading-fontfamily);
/*  --rtd-intro-font: 22px/28px var(--rtd-heading-fontfamily);*/
  --rtd-intro-font: bold 16px/26px var(--rtd-text-fontfamily);
  --rtd-text-font:  16px/26px var(--rtd-text-fontfamily);

  --rtd-heading-color:  var(--color-purple);
  --rtd-text-color:     #333333;
  --rtd-link-color:     var(--color-green-small);
  --rtd-link-hover-color: var(--button-color-hover);

  --widget-background: #FFFFFF;
  --widget-title-font: var(--rtd-h3-font); /* behalve highlight als h4 volgens design?? */
  --widget-title-color: var(--color-purple);

  --widget-large-title-font: bold 28px/28px var(--rtd-heading-fontfamily);
}



/*
This was 375.
But 500px is better now because:
- mobiles often go up to 412x.. now
- 500px is the min width of Chrome on a Mac, so we can test mobile by only resizing the window;
*/
@media (max-width: 500px)
{
  html
  {
    --rtd-h1-font:    bold 27px/26px var(--rtd-heading-fontfamily);
    --rtd-h2-font:    bold 24px/28px var(--rtd-heading-fontfamily);
    --rtd-h3-font:    bold 21px/26px var(--rtd-heading-fontfamily);
    --rtd-h4-font:    bold 18px/26px var(--rtd-heading-fontfamily);
/*    --rtd-intro-font: 16px/26px var(--rtd-heading-fontfamily);*/
    --rtd-intro-font: bold 16px/26px var(--rtd-text-fontfamily);
    --rtd-text-font:  16px/26px var(--rtd-text-fontfamily);

    --widget-title-font: 600 20px/26px var(--rtd-heading-fontfamily);

    --widget-spacing-aside:   30px; /* a widget with it's own standalone content (such as a news / events widget) */
  }
}



/* Styling of specific widgets */
html
{
  /* Accordion/FAQ widget */
  --widget-accordion-line-color:        #DADADA;
  --widget-accordion-items-title-color: var(--themecolor);
  --widget-accordion-items-title-font:  600 20px/26px var(--rtd-heading-fontfamily);
  --widget-accordion-icon-color:        var(--color-purple);

  /* Checklist widget */
  --widget-checklist-number-color:     var(--color-green-small);
  --widget-checklist-number-font:      700 17px/20px var(--rtd-heading-fontfamily);
  --widget-checklist-item-color:       var(--color-purple);
  --widget-checklist-item-font:        var(--rtd-h4-font);

  /* CTA button */
  --widget-ctabutton-background:       linear-gradient(180deg, #641C76 0%, #9420B1 100%); /* paarse gradient */
  --widget-ctabutton-hover-background: linear-gradient(180deg, #641C76 0%, var(--mbo-purple-dark) 100%); /* paarse gradient */

  /* Downloads widget embedded in the page content */
  --widget-download-item-padding-v:    6px;
  --widget-download-icon-color:        var(--color-green-small);
  --widget-download-icon-fontsize:     22px;
  --widget-download-title-color:       var(--color-green-small);
  --widget-download-title-font:        500 20px/26px var(--rtd-heading-fontfamily);
  --widget-download-description-color: #333333;
  --widget-download-description-font:  15px/26px Arial;

  /* Downloads widget in the sidebar */
  --widget-sidebar-download-item-padding-v: 7px;
  --widget-sidebar-download-title-font:     600 16px/20px var(--rtd-heading-fontfamily);

  /* "Table of contents" widget */
  --widget-tableofcontents-header-color:      var(--color-green-large-on-light-background);
  --widget-tableofcontents-header-font:       bold 18px var(--button-fontfamily);
  --widget-tableofcontents-item-bullet-color: var(--button-color); /* #25A22F */
  --widget-tableofcontents-item-bullet-size:  18px;
  --widget-tableofcontents-item-color:        #333333;
  --widget-tableofcontents-item-font:         500 17px/28px var(--rtd-heading-fontfamily);
}


/* Webhare's CSS parser currently doesn't recognize :root, so it'll prefix it to encapsulate it.
  So any :root rule won't ever work in the RTD.
*/
html
{
  /* Endusers of green colors */
  --footer-panel-background: var(--color-theme-background-highcontrast);


  /* 82% opacity in PSD */
  --color-bggradient-to-green:       linear-gradient(to bottom,  #ffffff 35%, #cdead1 100%);
  --color-bggradient-to-green-hover: linear-gradient(to bottom,  #ffffff 35%, #8ecf95 100%);



  --widget-links-title-color: var(--color-green);
  --widget-links-icon-color: var(--color-green);




  --widget-border-radius: 8px;
  --widget-border-color:             #D2D2D2;
  --widget-border-highlighted-color: #0DB24C; /* color for any widget with a border */
  --widget-box-shadow:               0   1px 5px 0 rgba(0,0,0,0.19); /* WVHG < 2025 */
  --widget-box-shadow:               3px 4px 4px 0 rgba(0,0,0,0.10); /* Geldlessen 2025 redesign */

  --widget-box-highlighted-shadow:   0 0.5px 4px rgba(0, 0, 0, 0.35);

  --widget-icon-space:         43px; /* amount of space to reserve for icons (for widgets such as checklist, links & downloads to keep their icons & titles aligned) */
  --widget-sidebar-icon-space: 26px; /* amount of space to reserve for icons (for widgets such as checklist, links & downloads to keep their icons & titles aligned) */



  /************************************************************
   *
   *  Page components / Buttons
   */
   --button-outline-text-color: inherit;

   --button-borderradius:   5px;

   --button-color:          var(--wig-green-button-color);
   --button-color-hover:    var(--wig-green-button-hover-color);

   /*--button-contrastcolor:     #FFFFFF;*/
   --button-fontfamily:     "TypoPRO Dosis Buttons", "TypePRO Dosis", "Dosis", Arial;
   --button-font:           bold 20px var(--button-fontfamily);
   --button-padding:        5px 20px 5px 20px;
   --button-height:         46px;
   --button-disabled-backgroundcolor: #AAAAAA;
   --button-disabled-textcolor:       #666666;
   /*--button-text-alignment-tweak:  4px; / * because of Officina font */

   --button-filterbar-search-desktop: bold 18.7px var(--button-fontfamily);
   --button-filterbar-search-mobile:  bold 20px   var(--button-fontfamily);
   --button-bekijklesmateriaal-font:  bold 18.7px var(--button-fontfamily);


   /* MinFin specific button additions */
   --button-icon-font:          900 13px "Font Awesome 5 Pro";
   --button-icon-backward:      var(--fa-arrow-left);
   --button-icon-forward:       var(--fa-arrow-right);
   --button-icon-search:        var(--fa-search);

   --textbutton-icon-font:      900 18px "Font Awesome 5 Pro";
   --textbutton-icon-backward:  var(--fa-chevron-left);
   --textbutton-icon-forward:   var(--fa-chevron-right);
}

.widget__description
{
  --rtd-text-font: 16px/24px Arial;
}

html.site--geldlessen .widget__description
{
  --rtd-text-font: 14px/22px Arial;
}




/* For partners list */
.widgetsgrid.widgetsgrid--extraspacing
{
  --widgetsgrid-gutter-x: 40px;
  --widgetsgrid-gutter-y: 40px;
}



.lesmateriaalblok__link
{
  --button-borderradius: 8px;
  --button-color: var(--color-green-small);
}

a.shareactionsbar__downloadpdflink
{
  --button-borderradius: 8px;
}


@media (max-width: $maxwidth-mobile)
{
  :root
  {
    --sidepadding: 10px;
  }
}

html
{
  font: 16px/26px Arial, Sans-Serif; /* 14px/21px */
  background: #ffffff;
  color: #000000;
}

a
{
  color: var(--rtd-link-color);
  outline-offset: var(--actionbutton-outline-offset);
  text-underline-offset: 2px;
}

a:hover
{
  color: var(--rtd-link-hover-color);
  text-decoration-thickness: 2px;
}


:where( html.wh-widgetpreview
      , .widgetsgrid
      , .body__widgetsbottom
      , .content__widgetscolumn)
      :where(.widget:not(.widget-debugwidget))
{
  border: 1px solid var(--widget-border-color);
  border-radius: var(--widget-border-radius);
  background-color: #FFFFFF;
}


/* Widget grids with a white or light background */
:where(html.wh-widgetpreview
      , .widgetsgrid--transparent
      , .body__widgetsbottom
      , .content__widgetscolumn)
      :where(.widget:not(.widget-debugwidget))
{
  box-shadow: var(--widget-box-shadow);
}


/* Widgets directly in a richcontent area
   :where(.richdoc, .wh-rtd__body) */
:where(.richdoc)
{
  /*& >  FIXME: wat hiervan is nog nodig? */
    & > .searchresult
  , & > .widget--custom-partnerlogo
  , & > .widget--partneractivities
  , & > .widget__searchactivity
  , & > .widget__activity-share
  , & > .widget__activity-organizers
  , & > .widget--highlightbig
  , & > .widget--geldles-downloads
  {
    border: 1px solid var(--widget-border-color);
    border-radius: var(--widget-border-radius);
    background-color: #FFFFFF;
    box-shadow: var(--widget-box-shadow);
  }
}






/* Hack for search page and old news and events page */
.widgets--spacing .widget + .widget
{
  margin-top: 20px;
}



[data-popup-video]
{
  cursor: pointer;
}


.richdoc--toplevel:empty
{
  display: none;
}


/* h3 outside RTD / .richdoc */
h3
{
  margin-top: 25px;
}


/* Geldlessen home tweak */
.pageheader--panels ~ .body__content .richdoc--toplevel .widget-richcontent:first-child
{
  margin-top: 50px;
  margin-bottom: 50px;
  --rtd-heading-color: var(--wig-green-dark);
}

@media (max-width: 500px)
{
  .pageheader--panels ~ .body__content .richdoc--toplevel .widget-richcontent:first-child
  {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

/* Geldlessen home tweak */
.pageheader--panels ~ .body__content .richdoc--toplevel .widget-richcontent:first-child h2
{
  font: var(--rtd-h1-font);
  font-weight: normal;
}

.pageheader--panels ~ .body__content .richdoc--toplevel .widget-richcontent:first-child .widget-richcontent__content
{
  padding-left: 7%;
  padding-right: 7%;
  text-wrap: balance;
}


/* .richdoc */
  h1,h2,h3,h4
, h1.heading1
, h2.heading2
, h3.heading3
, h4.heading4
, .searchresult__title
, .coursematerial__title
, .activity__title
, .dialog__title
, .widget--widgetsgroup__title
{
  color: var(--rtd-heading-color);
}

.page__date
{
  color: var(--color-grey-text-small);
  margin-bottom: 30px;
}

.widget /* FIXME: bad naming */
{
  font: 14px/21px Arial;
}

  h1
, .widget--widgetsgroup__title
{
  font: var(--rtd-h1-font);
  margin-top: 0;
  margin-bottom: 0px;
}

h1 + .page__date
{
  margin-top: 5px;
}

  h2
, .wh-popup-body .title
, .searchresult__title
, .coursematerial__title
, .dialog__title
{
  font: var(--rtd-h2-font);
  margin-top: 25px;
  margin-bottom: 0px;
}

h3
{
  font: var(--rtd-h3-font);
  margin-top: 25px;
  margin-bottom: 0px;
}

h4
{
  font: var(--rtd-h4-font);
  margin-top: 25px;
  margin-bottom: 0px;
}

  .widget__title
, .wh-poll__title
{
  font: var(--widget-title-font);
}

  p.normal
, ul.unordered
, ol.ordered
{
  color: var(--rtd-text-color);
  font: var(--rtd-text-font);
}

p.intro
{
  margin-top: 0;
  margin-bottom: 21px;

  font: var(--rtd-intro-font);
  color: #000000;
}


.widget__richtitle
{
  /* 22.6 on homepage, 21.11 on 'over week van het geld'.
  */
  font: normal 21px "TypoPRO Dosis", "Dosis", Arial;
}
.widget__richtitle > p:last-child
{
  margin-bottom: 0;
}





.widget--widgetsgroup__title
{
  margin-bottom: 30px;
}


  p.normal
, ul.unordered
, ol.ordered
, blockquote
, .wh-video
{
  margin-top: 0;
  margin-bottom: 18px; /* 25px in design, but got too much whitespace */
}

  .richdoc > .widget
, .richdoc > .embeddedobject
{
  margin-top:    var(--widget-spacing-default);
  margin-bottom: var(--widget-spacing-default);
}

  .richdoc--toplevel > .widget-events
, .richdoc--toplevel > .widget--news
{
  margin-top:    var(--widget-spacing-aside);
  margin-bottom: var(--widget-spacing-aside);
}



  .richdoc > p
, .richdoc > ul
, .richdoc > ol
, .richdoc > .embeddedobject.transparent
, .richdoc > h1 + .widget-richcontent
, .richdoc > h2 + .widget-richcontent
, .richdoc > h3 + .widget-richcontent
, .richdoc > p  + .widget-richcontent
, .richdoc > ul + .widget-richcontent
, .richdoc > ol + .widget-richcontent
{
  margin-top:    var(--rtd-spacing-paragraph);
  margin-bottom: var(--rtd-spacing-paragraph);
}

/* aka .rtdcontent, .richcontent */
  .richdoc > *:first-child
, .rtdcontent > *:first-child
{
  margin-top: 0;
}
  .richdoc > *:last-child
, .rtdcontent > *:last-child
{
  margin-bottom: 0;
}

/* we don't want to cancel the margin-bottom
   if there's a successive richdoc. (for example as embedded object which opens a new container)
*/

/* sort of collapse-margins because we use padding instead of margins */

.richdoc > .embeddedobject + p
{
  margin-top: 15px; /* top so we can remove if needed in a + selector */
}

.richdoc > h1 + * { margin-top: 20px; }
.richdoc > h2 + * { margin-top: 8px; }
.richdoc > h3 + * { margin-top: 5px; }
.richdoc > h4 + * { margin-top: 5px; }


  .richdoc > .ctalink
, .richdoc > .ctabutton
{
  margin-top: 8px;
}
  p.normal + .ctalink
, p.normal + .ctabutton
{
  margin-top: -4px; /* we want less space and (NOTE) we cannot change the margin-bottom on p.normal with a sibling selector :( */
}



/* prevent image in columns or in fluid mobile layout
   to stick outside their content area
*/
img.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}

img.wh-rtd__img--floatleft
{
  float: left;
}

img.wh-rtd__img--floatright
{
  float: right;
}

  img.wh-rtd__img--floatleft
, img.wh-rtd__img--floatright
{
  max-width: 50%;
  margin: 0 20px 0 0;
}




/* prevent embeddedobject getting beside a floating image */
  .embeddedobject
, .wh-rtd-embeddedobject
{
  clear: both;
}

/* NOTE: this will give a problem when there are floats in another column
         (outside this element)
         (Unless .richdoc get's an overflow: auto/hidden/scroll; or float: left; so it'll make a new context)
         (essecially have some kind of layout context)
*/
.richdoc::after
{
  display: block;
  content: "";
  clear: both;
}


/* Since the widgetsgroup closes the .richdoc container, the last P in the .richdoc will lose it's margin-bottom
   So let's add the margin to the widgetsgroup
*/
.widget--widgetsgroup
{
  margin-top: 30px; /* 2x default margin */
}
/*
.content__widgetscolumn .widget--widgetsgroup
{
  margin-top: 0;
}
*/


.widget--widgetsgroup:first-child
{
  margin-top: 0;
}




  .ctalink
, .backlink
{
  display: inline-flex;
  align-items: center;

  text-decoration: none;

  /* specify anchor color in case we're not used as an <a> element
     might happen when we are in a container which uses [data-popup].
  */
/*  color: var(--color-green-small);*/
  color: var(--themecolor);
}

.ctalink > *
{
  text-decoration: underline;
}

html.styling2025 .ctalink > *
{
  text-decoration: none;
  text-underline-offset: 3px;
}
html.styling2025 .ctalink:hover > *
{
  text-decoration: underline;
}


  .ctalink::before
, .backlink::before
{
  display: inline-block;
  text-decoration: none;

  font: normal normal normal 14px/1 'Font Awesome 5 Pro';
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);

  font-size: 75%;
  margin-right: 5px;
}




.backlink::before
{
  content: "\f053"; /* fa-chevron-left */
}



  .ctabutton /* the one with an arrow the left */
, .ctadownloadbutton
, .wh-form__button
{
  display: inline-flex;
  align-items: center;

  /* when using in a flex container, don't allow us to shrink */
  flex-shrink: 0;

  max-width: 100%;

  /* reset for <button> */
  border: 0;
  padding: 0;
  -webkit-appearance: none;

  /* reset for <a> */
  text-decoration: none;

  position: relative;

  font: var(--button-font);
  letter-spacing: 0.5px;
  text-align: left;

  min-height: 44px; /* was 31px; */
  padding: 8px 35px 8px 18px;
  border-radius: 5px;
  color: #FFFFFF;

  background: var(--button-color);

  cursor: pointer;

  outline-offset: var(--actionbutton-outline-offset);
}

.ctabutton.ctabutton--arrow::before
/*, .wh-form__button::before*/
{
  font: normal normal normal 14px/1 'Font Awesome 5 Pro';
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);

  content:  var(--fa-chevron-right);
  font-weight: 900;
  font-size: 16px;
  margin-right: 15px;
}

.ctadownloadbutton::before
{
  display: inline-block;
  content: "\f1c6";
  margin-right: 5px;

  font: normal normal normal 14px/1 'Font Awesome 5 Pro';
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
/*  transform: translate(0, 0);*/
}

  .ctabutton:active
, .ctabutton:hover
, .ctadownloadbutton:active
, .ctadownloadbutton:hover
, .wh-form__button:active
, .wh-form__button:hover
{
  background: var(--button-color-hover);
}
