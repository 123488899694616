/** Widget 'Video' (inline) *********************************************************************/

.widget--video-inline
{

}
.widget--video-inline__tv
{
  margin-top: 10px;

  display: block;
  max-width: 400px; /* was eerder 279px */
  cursor: pointer;
}
.widget--video-inline__tv__screen
{
  position: relative;
  padding: 10px 12px 18px 12px;
  background-color: #24a638; /* FIXME: green */
  border-radius: 6px;
}
.widget--video-inline__tv__screen::after
{
  content: "";
  display: block;
  width: 29px;
  height: 3px;
  position: absolute;
  left: 50%;
  margin-left: -15px;
  bottom: 5px;
  background-color: #d5ead9;
  border-top: 1px solid rgba(180, 163, 167, 0.3);
  border-bottom: 1px solid rgba(180, 163, 167, 0.3);
}
.widget--video-inline__image
{
  background-size: cover;
  border-radius: 6px;
  overflow: hidden;
}

.widget--video-inline__tv::after
{
  display: block;
  width: 129px;
  height: 26px;
  margin: 0 auto;
  content: url("../../web/img/voet-van-tv.png");

  /* compensate for inbalance in image */
  position: relative;
  left: -12px;
}
