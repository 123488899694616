/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
.widget-image__image {
  display: block;
  width: 100%;
}

.widget-image--allowfullscreen .widget-image__image {
  cursor: pointer;
}

.widget-image__caption {
  margin-top: 8px;
}

.widget-image__caption > p.normal {
  color: #333333;
  font: 14px/19px Arial;
}

.widget-image__caption + .widget-image__copyright {
  margin-top: 5px;
}

.widget-image__copyright {
  color: #757575;
  font: 14px/19px Arial;
}

.widget-image__imagewrapper {
  position: relative;
  overflow: hidden;
}

.widget-image__fullscreen {
  width: 37px;
  height: 37px;
  line-height: 37px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #D7D7D7;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  bottom: 10px;
  transition: transform 0.25s, border-radius 0.25s;
}

.widget-image__fullscreen::before {
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f00e";
}

.widget-image__imagewrapper:hover .widget-image__fullscreen {
  transform: scale(1.5) translate(5px, 5px);
  border-radius: 50% 0 0 0;
}