html.styling2025 {
  --menubar-level1-openbyhover: true;
  --menubar-level1-font: bold 18px "TypoPRO Dosis Menu", "Dosis", Arial;
  --menubar-level1-padding-left: 10px;
  --menubar-level1-padding-right: 10px;
  --menubar-level1-spacing: 0px;
  --menubar-level1-spacing-min: 0px;
  --menubar-bar-item-color: #FFFFFF;
  --menubar-bar-item-decoration: none;
  --menubar-bar-item-border-radius: 8px;
  --menubar-bar-item-icon-size: 22px;
  --menubar-bar-item-icon-margin: 6px;
  --menubar-bar-item-selected-color: #FFFFFF;
  --menubar-bar-item-selected-hover-color: var(--mbo-purple-dark);
  --menubar-bar-item-hover-background: var(--wig-green-dark);
  --menubar-bar-item-hover-color: #FFFFFF;
  --menubar-bar-item-hover-decoration: none;
  --menubar-bar-toggler-width: auto;
  --menubar-bar-toggler-margin: 6px;
  --menubar-bar-toggler-extrahitarea: 9px;
  --menubar-bar-toggler-shifty: 6px;
  --menubar-bar-toggler-font: var(--fontawesome-solid);
  --menubar-bar-toggler-size: 12px;
  --menubar-bar-toggler-content: var(--fa-chevron-down);
  --menubar-pulldown-zindex: 10;
  --menubar-pulldown-minwidth: 220px;
  --menubar-pulldown-maxwidth: 275px;
  --menubar-pulldown-border: none;
  --menubar-pulldown-border-radius: 8px;
  --menubar-pulldown-padding: 5px 0 12px 0;
  --menubar-pulldown-bgcolor: #FFFFFF;
  --menubar-pulldown-box-shadow: 1px 3px 3px rgb(0 0 0 / 25%);
  --menubar-pulldown-itemhover-bgcolor: #F1F1F1;
  --menubar-pulldown-itemhover-textcolor: var(--color-purple);
  --menubar-pulldown-itemhover-textdecoration: none;
  /* Shift to make room for toggle buttons.
     We add this padding inside the menuitem so a :hover on the
     item highlights the background over the full width */
  --menubar-items-left-shift: 15px;
  --menubar-level2-color: var(--color-purple);
  --menubar-level2-font: 600 15px/20px "TypoPRO Dosis", "Dosis", Arial;
  --menubar-level2-padding-left: calc(var(--menubar-items-left-shift) + 15px);
  --menubar-level2-padding-right: 15px;
  --menubar-level2-padding-top: 6px;
  --menubar-level2-padding-bottom: 6px;
  --menubar-level2-expandicon: var(--fa-chevron-right);
  --menubar-level2-collapseicon: var(--fa-chevron-down);
  --menubar-level2-toggle-font: 900 10px "Font Awesome 5 Pro"; /* solid */
  --menubar-level3-color: var(--color-purple);
  --menubar-level3-font: 500 15px/20px "TypoPRO Dosis", "Dosis", Arial;
  --menubar-level3-padding-left: calc(var(--menubar-items-left-shift) + 25px);
  --menubar-level3-padding-right: 15px;
  --menubar-level3-padding-top: 4px;
  --menubar-level3-padding-bottom: 4px;
  --menubar-level3-expandicon: var(--fa-plus);
  --menubar-level3-collapseicon: var(--fa-minus);
  --menubar-level3-toggle-font: 400 10px "Font Awesome 5 Pro"; /* regular */
  --menubar-level4-color: var(--color-purple);
  --menubar-level4-font: 400 14px/20px "TypoPRO Dosis", "Dosis", Arial;
  --menubar-level4-padding-left: calc(var(--menubar-items-left-shift) + 35px);
  --menubar-level4-padding-right: 15px;
  --menubar-level4-padding-top: 4px;
  --menubar-level4-padding-bottom: 4px;
}
html.styling2025 .spc-menubar__pulldown {
  --menubar-bar-toggler-margin: calc(-1 * var(--menubar-bar-toggler-extrahitarea));
}
html.styling2025 .spc-menubar-branch--horizontal {
  height: 100%;
}
html.styling2025 .spc-menubar-branch--horizontal > li > .spc-menubar__item {
  margin-top: 10px;
  height: calc(100% - 20px);
}
html.styling2025 .spc-menubar__spacer {
  align-self: center;
  height: 32px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  margin-left: 7px;
  margin-right: 7px;
}
html.styling2025 .spc-menubar__pulldown .spc-menubar__item__togglebutton {
  position: absolute;
  left: 15px;
}

@media (max-width: 950px) {
  html.styling2025 {
    --menubar-level1-font: bold 17px "TypoPRO Dosis Menu", "Dosis", Arial;
  }
}
@media (max-width: 900px) {
  html.styling2025 {
    --menubar-level1-font: bold 16px "TypoPRO Dosis Menu", "Dosis", Arial;
    --menubar-bar-item-icon-size: 18px;
    --menubar-level1-padding-left: 4px;
    --menubar-level1-padding-right: 4px;
    --menubar-level1-spacing: 0px;
    --menubar-level1-spacing-min: 0px;
  }
}
@media (max-width: 815px) {
  .spc-menubar__item .icon {
    display: none;
  }
}
.theme--po {
  --menubar-bar-item-selected-background: var(--po-brown);
  --menubar-bar-item-selected-hover-color: var(--po-brown);
  --menubar-bar-item-hover-color: #FFFFFF;
  --menubar-level2-color: var(--po-brown);
  --menubar-level3-color: var(--po-brown);
  --menubar-level4-color: var(--po-brown);
}

.theme--vo {
  --menubar-bar-item-selected-background: var(--vo-blue-dark);
  --menubar-bar-item-selected-hover-color: var(--vo-blue-dark);
  --menubar-bar-item-hover-color: #FFFFFF;
  --menubar-level2-color: var(--vo-blue-dark);
  --menubar-level3-color: var(--vo-blue-dark);
  --menubar-level4-color: var(--vo-blue-dark);
}

.theme--mbo {
  --menubar-bar-item-selected-background: var(--mbo-purple-dark);
  --menubar-bar-item-selected-hover-color: var(--mbo-purple-dark);
  --menubar-bar-item-hover-color: #FFFFFF;
  --menubar-level2-color: var(--mbo-purple-dark);
  --menubar-level3-color: var(--mbo-purple-dark);
  --menubar-level4-color: var(--mbo-purple-dark);
}