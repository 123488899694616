/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
html.eventactive {
  overflow: hidden;
}
html.eventactive .timetable-event--show {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

html.livestream-active .timetable-info {
  display: none;
}

html:not(.livestream-active) .timetable-view {
  display: none;
}

html:not(.livestream-active) .timetable-topbar .timetable-viewlivestreambutton {
  display: none;
}

html:not(.livestream-active) .timetable-livestream {
  display: none;
}

.timetable-topbar {
  display: flex;
  align-items: center;
  height: 55px;
  background-color: #FFFFFF;
  padding-left: 12px;
  padding-right: 12px;
}

.timetable-topbar__backbutton {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--color-purple);
  font: 18px var(--rtd-heading-fontfamily);
}

.timetable-topbar .fas {
  color: var(--color-green);
  margin-right: 22px;
}

.timetable-topbar img {
  display: block;
  width: 69px;
  height: 40px;
  margin-right: 14px;
  margin-top: -2px;
}

.timetable-topbar__backbutton__sitetitle {
  font-weight: bold;
  color: var(--color-green);
  margin-left: 4px;
}

.timetable-topbar .timetable-viewlivestreambutton {
  margin-left: auto;
}

@media (max-width: 579px) {
  .timetable-topbar {
    height: 45px;
  }
  .timetable-topbar img {
    display: none;
  }
  .timetable-topbar__backbutton {
    font-size: 13px;
  }
  .timetable-topbar .fas {
    font-size: 15px;
    margin-right: 10px;
  }
}
.timetable-header {
  display: grid;
  grid-template-columns: minmax(15px, 1fr) minmax(300px, var(--pagegrid-contentarea-width-full)) minmax(15px, 1fr);
  grid-template-rows: min-content minmax(min-content, 1fr);
  background: url("../../web/img/timetable/studio-wvhg-header-achtergrond.png") no-repeat;
  background-size: cover;
}

.timetable-header__background {
  grid-column: 1/span 3;
  grid-row: 1;
}

.timetable-header__identity {
  grid-column: 2;
  grid-row: 1;
  position: relative;
}

.timetable-header__identity__text {
  display: flex;
  flex-direction: column;
}

.timetable-header__overlaybg {
  grid-column: 1/span 3;
  grid-row: 2;
  background-color: rgba(0, 0, 0, 0.41);
}

.timetable-header__overlaycontent {
  grid-column: 2;
  grid-row: 2;
  color: #FFFFFF;
}

.timetable-filter {
  display: flex;
  align-items: center;
  font: 500 16px var(--rtd-heading-fontfamily);
}

.timetable-filter select {
  margin-left: 15px;
  flex: 1 0 0px;
  height: 44px;
  background-color: #FFFFFF;
  border: 0;
  border-radius: 4px;
  font: 500 16px var(--rtd-heading-fontfamily);
}

.timetable-filter input[type=submit] {
  /* display: none or visibility: hidden; will prevent focus!! */
  position: absolute !important; /* to not mess with flex containers */
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
  margin: 0;
}

@media (max-width: 579px) {
  .timetable-header__identity {
    display: flex;
    align-items: center;
    padding-top: 13px;
    padding-bottom: 13px;
  }
  .timetable-header__logo {
    flex: none;
    margin-bottom: -15px;
    width: 96px;
  }
  .timetable-header__identity__text {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .timetable-header__date {
    width: 205px;
    height: 14px;
  }
  .timetable-header__initiatief {
    margin-top: 9px;
    width: 187px;
    height: 14px;
  }
  .timetable-header__overlaycontent {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .timetable-info {
    display: none;
  }
  .timetable-filter select {
    height: 38px;
  }
}
@media (min-width: 580px) {
  .timetable-header__logo {
    flex: none;
    margin-left: 72px;
    margin-top: -24px;
    margin-bottom: -20px;
    width: 343px;
    max-width: 35vw;
    pointer-events: none;
  }
  .timetable-header__date {
    /*
            grid-column: 2;
            grid-row: 1;
            align-self: center;
            justify-self: end;
    */
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 383px;
    max-width: 43vw;
  }
  .timetable-header__initiatief {
    /*
      grid-column: 2;
      grid-row: 1;
      align-self: end;
      justify-self: end;
      margin-bottom: 20px;
      */
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 271px;
    max-width: 37vw;
  }
  .timetable-header__overlaycontent {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .timetable-info.rtdcontent > * {
    font: 16px/23px var(--rtd-heading-fontfamily);
  }
}
@media (max-width: 767px) {
  .timetable-info {
    margin-bottom: 15px;
  }
  .timetable-view {
    display: none;
  }
}
@media (min-width: 768px) {
  /*
  .timetable-header__overlaycontent
  {
    display: flex;
    align-items: center;
  }

   .timetable-info
  , .timetable-view
  {
    flex: 3 0 0px;
  }

  .timetable-filter
  {
    flex: 2 0 0px;
    min-width: 310px;
    margin-left: 80px;
  }
  */
  .timetable-topbar .timetable-viewlivestreambutton {
    display: none;
  }
  .timetable-header__overlaycontent {
    display: grid;
    grid-template-columns: 3fr minmax(320px, 2fr);
  }
  .timetable-info,
  .timetable-view {
    grid-column: 1;
    grid-row: 1;
    align-self: center;
  }
  .timetable-filter {
    grid-column: 2;
    grid-row: 1;
    margin-left: 80px;
  }
}
html.page--timeschedule body {
  display: flex;
  flex-direction: column;
}

.timetable-bottom {
  flex: 1 0 0px;
  background: url("../../web/img/timetable/studio-wvhg-header-achtergrond.png") no-repeat;
  background-size: 170vw;
  background-position: 0 0;
  background-repeat: repeat-y;
}

.timetable-livestream__header {
  font: bold 44px var(--rtd-heading-fontfamily);
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 30px;
}

.timetable-livestream__videocontainer {
  margin-bottom: 50px;
  display: grid;
  width: 100%;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.timetable-livestream__videocontainer::before {
  content: "";
  padding-top: 56.25%;
  grid-column: 1;
  grid-row: 1;
}

.timetable-livestream__videocontainer iframe {
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  align-self: stretch;
  border: 0;
}

.timetable-viewlivestreambutton {
  display: inline-flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  height: 44px;
  background-color: #D60064;
  border-radius: 4px;
  font: bold 24px var(--rtd-heading-fontfamily);
  color: #FFFFFF;
  text-decoration: none;
}

.timetable-viewlivestreambutton::after {
  margin-left: 30px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f0a9";
  font-size: 28px;
}

.timetable-topbar .timetable-viewlivestreambutton {
  height: 35px;
  font: 18px var(--rtd-heading-fontfamily);
  padding-left: 11px;
  padding-right: 11px;
}

.timetable-topbar .timetable-viewlivestreambutton::after {
  font-size: 21px;
}