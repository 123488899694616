/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
.lesmateriaalheader {
  grid-column: 1;
  grid-row: 1;
  padding-top: 12px;
  padding-bottom: 10px;
  width: 100%;
  max-width: var(--pagegrid-contentarea-width-full);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
}

/*
oude lesmateriaal header styling

.lesmateriaalheader__title
{
  color: #FFFFFF;
  font: bold 52px var(--rtd-heading-fontfamily);

  // 24px/32px
  font: bold clamp(24px,5.5vw,52px) var(--rtd-heading-fontfamily);
}
*/
.lesmateriaalheader {
  align-self: center;
  background: #FFFFFF;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 6px;
  box-shadow: 0 0 9px rgba(255, 255, 255, 0.35);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.lesmateriaalheader__title {
  font: bold 36px/40px var(--rtd-heading-fontfamily);
}

.lesmateriaalheader__title:first-line {
  font-weight: normal;
}

.page--lesmateriaaloverview .pageheader .page--lesmateriaaloverview .pageheader__image {
  height: 166px;
}

.page--lesmateriaaloverview .pageheader {
  color: #FFFFFF;
}

.lesmateriaalheader__description.lesmateriaalheader__description > * {
  color: #FFFFFF;
  font: 17px/26px var(--rtd-heading-fontfamily);
}

.lesmateriaalheader__description.lesmateriaalheader__description a {
  color: #FFFFFF;
  text-decoration: underline;
}

@media (max-width: 650px) {
  .pageheader--lesmateriaal .pageheader__image {
    height: auto;
    min-height: auto;
  }
  .lesmateriaalheader {
    padding-top: 35px;
    padding-bottom: 30px;
  }
  .lesmateriaalheader__description {
    margin-left: 0;
  }
}
/*
@media (min-width: 481px) and (max-width: 650px)
{
  .page--lesmateriaaloverview .pageheader__image
  {
    min-height: auto;
  }
}
*/
@media (min-width: 651px) {
  .lesmateriaalheader {
    display: flex;
    align-items: center;
  }
  .page--lesmateriaaloverview .pageheader__image {
    min-height: 250px;
    height: auto;
  }
  .lesmateriaalheader__textcontent {
    display: flex;
    align-items: center;
  }
  .lesmateriaalheader__title {
    flex: 1 0 auto;
    padding-right: 30px;
  }
  .lesmateriaalheader__description {
    margin-left: auto;
    flex: 1 0 auto;
    max-width: 53%;
    text-align: right;
  }
}