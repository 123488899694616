/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
/* was 880px, maar met pulldown toggles hebben we meer ruimte nodig en toen een tussenoplossing voor het menu bedacht waarmee juist 768px (iPad) ook past */
.site--wvhg {
  --site-topbar-height: 53px;
  --site-menubar-height: 60px;
}
@media (max-width: 767px) {
  .site--wvhg {
    --site-menubar-height: 60px;
  }
}
.site--wvhg .siteheader-top--wvhg-neutraal .siteheader-identity__content {
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.site--wvhg .siteheader-identity__content img {
  display: block;
}
.site--wvhg .siteheader-top__content {
  width: 100%;
  max-width: var(--pagegrid-contentarea-width-full);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  display: flex;
}
@media (max-width: 767px) {
  .site--wvhg .siteheader-top--wvhg-neutraal .siteheader-top, .site--wvhg .siteheader-top--wvhg-neutraal .siteheader-top__content {
    height: var(--site-topbar-height);
  }
  .site--wvhg .siteheader-top--wvhg-neutraal .siteheader-identity {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -12px;
  }
  .site--wvhg .siteheader-top--wvhg-neutraal img {
    display: block;
    height: 66px;
  }
  .site--wvhg .siteheader-top--wvhg-neutraal .siteheader-wvhg__when {
    display: none;
  }
}
@media (min-width: 768px) {
  .site--wvhg .siteheader-menubar {
    height: var(--site-menubar-height);
  }
  .site--wvhg .siteheader-top--wvhg-neutraal .siteheader-identity {
    display: contents;
  }
  .site--wvhg .siteheader-top--wvhg-neutraal .siteheader-identity__content {
    flex-direction: row;
  }
  .site--wvhg .siteheader-top--wvhg-neutraal .siteheader-top__content {
    height: 120px;
  }
  .site--wvhg .siteheader-top--wvhg-neutraal .siteheader-identity__wvhg img {
    margin-bottom: -3px;
    height: 127px;
  }
  .site--wvhg .siteheader-top--wvhg-neutraal .siteheader-wvhg__when {
    margin-left: 20px;
    margin-bottom: 17px;
    display: flex;
    font: 600 28px/40px var(--rtd-heading-fontfamily);
  }
  .site--wvhg .siteheader-top--wvhg-neutraal .siteheader-wvhg__when__start {
    color: var(--color-green);
  }
  .site--wvhg .siteheader-top--wvhg-neutraal .siteheader-wvhg__when__until {
    color: var(--color-purple);
    margin-left: 7px;
    margin-right: 7px;
  }
  .site--wvhg .siteheader-top--wvhg-neutraal .siteheader-wvhg__when__end {
    color: var(--color-green);
  }
}