@charset "UTF-8";
/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
/*
  --checkbox_switch_borderwidth:   3px;
  --checkbox_switch_track_width:  45px;
  --checkbox_switch_track_height: 24px;
  --checkbox_switch_track_color:  #A4A4A4;
  --checkbox_switch_track_color-disabled: #A4A4A4;
  --checkbox_switch_track_color-active: var(--color-green);
  --checkbox_switch_thumb_size:   18px;
  --checkbox_switch_thumb_color:  #FFFFFF;
}
*/
:root {
  --checkbox-switch-borderwidth: 3px;
  --checkbox-switch-track-width: 51px;
  --checkbox-switch-track-height: 30px;
  --checkbox-switch-track-color: #A4A4A4;
  --checkbox-switch-track-color-disabled: #A4A4A4;
  --checkbox-switch-track-color-active: var(--formcontrols-themecolor);
  --checkbox-switch-thumb-size: 18px;
  --checkbox-switch-thumb-color: #FFFFFF;
}

input[type=checkbox].spc-toggle {
  position: absolute !important;
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
  margin: 0;
}

input[type=checkbox].spc-toggle + label + label {
  vertical-align: middle;
}

input[type=checkbox].spc-toggle + label {
  flex: none;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  position: relative;
  width: var(--checkbox-switch-track-width);
  min-width: var(--checkbox-switch-track-width);
  height: var(--checkbox-switch-track-height);
  border-radius: calc(var(--checkbox-switch-track-height) / 2);
  background-color: var(--checkbox-switch-track-color);
  border: var(--checkbox-switch-borderwidth) solid var(--checkbox-switch-track-color);
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

html[data-whatinput=keyboard] input[type=checkbox].spc-toggle:focus + label,
input[type=checkbox].spc-toggle:focus-visible + label {
  outline: 2px solid var(--checkbox-switch-track-color-active);
  outline: -webkit-focus-ring-color auto 5px;
  outline-offset: 4px;
}

input[type=checkbox].spc-toggle + label::before {
  content: "";
  position: absolute;
  height: var(--checkbox-switch-thumb-size);
  left: 1px;
  width: var(--checkbox-switch-thumb-size);
  background-color: #ffffff;
  border-radius: 50%;
  -webkit-transition: left 0.2s, background-color 0.2s, border-color 0.2s; /* iOS 6 */
  transition: left 0.2s, background-color 0.2s, border-color 0.2s;
}

/* OFF */
input[type=checkbox].spc-toggle.spc-toggle + label:hover {
  border-color: var(--checkbox-switch-track-color);
}

/* ON */
input[type=checkbox].spc-toggle.spc-toggle:checked + label {
  background-color: var(--checkbox-switch-track-color-active);
  border-color: var(--checkbox-switch-track-color-active);
}

/* Disabled */
input[type=checkbox].spc-toggle.spc-toggle[disabled] + label {
  background-color: var(--checkbox-switch-track-color-disabled);
  border-color: var(--checkbox-switch-track-color-disabled);
}

input[type=checkbox].spc-toggle:checked + label::before {
  left: calc(100% - var(--checkbox-switch-thumb-size) - 1px);
}

input[type=checkbox].spc-toggle + label::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f00c";
  font-size: 11px;
  color: #FFFFFF;
  position: absolute;
  top: 0;
  left: 5px;
  line-height: calc(var(--checkbox-switch-track-height) - var(--checkbox-switch-borderwidth) * 2 + 1px);
}

.sharecomponent__buttonbar {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  row-gap: 18px;
}

.sharecomponent__buttonbar .wh-share {
  appearance: none;
  border: 0;
  margin: 0;
  background: none;
  width: 37px;
  height: 37px;
  width: var(--component-sharepage-button-size, 37px);
  height: var(--component-sharepage-button-size, 37px);
  background-color: #FFFFFF;
  background: var(--component-sharepage-button-background, #FFFFFF);
  color: var(--component-sharepage-button-color);
  border: 1px solid var(--component-sharepage-button-color);
  border-radius: 8px;
  cursor: pointer;
}

.sharecomponent__buttonbar > .wh-share + .wh-share {
  margin-left: var(--component-sharepage-button-spacing, 13px);
}

.sharecomponent__buttonbar .wh-share:hover {
  background-color: var(--component-sharepage-button-color);
  background-image: none;
  color: #FFFFFF;
}

.sharecomponent__buttonbar .wh-share[data-network=twitter]::before {
  content: "𝕏" !important;
  font-size: 110%;
  font-weight: 700;
  font-family: Arial-black;
}

.sharecomponent__buttonbar .wh-share[data-network=facebook]::before {
  font: var(--fontawesome-brands);
  content: var(--fa-facebook-f);
}

.sharecomponent__buttonbar .wh-share[data-network=linkedin]::before {
  font: var(--fontawesome-brands);
  content: var(--fa-linkedin-in);
}

.sharecomponent__buttonbar .wh-share[data-network=whatsapp]::before {
  font: var(--fontawesome-brands);
  content: var(--fa-whatsapp);
}

.sharecomponent__buttonbar .wh-share[data-network=email]::before,
.sharecomponent__buttonbar .wh-share.siteaction--emailpagelink::before {
  font: var(--fontawesome-regular);
  content: var(--fa-envelope);
}

.sharecomponent__buttonbar .wh-share[data-network=print]::before {
  font: var(--fontawesome-regular);
  content: var(--fa-print);
  font-size: 19px;
  font-size: var(--component-sharepage-button-symbolsize, 19px);
}

.sharecomponent__buttonbar .wh-share.wh-share::before {
  font-size: 20px;
  font-size: var(--component-sharepage-button-symbolsize, 20px);
}

.sharecomponent {
  margin-top: 60px;
  padding-top: 35px;
}

.contentwithin_wide .sharecomponent {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}
.contentwithin_wide .sharecomponent--bothactions {
  width: auto;
}
.contentwithin_wide .sharecomponent:not(.sharecomponent--bothactions) .sharecomponent__buttonbar {
  justify-content: center;
}

.content__contentcolumn .sharecomponent {
  padding-left: 0;
}

.sharecomponent__title {
  color: var(--component-sharepage-heading-color);
  font: var(--component-sharepage-heading-font);
  margin-bottom: 14px;
}

h2.sharecomponent__title {
  margin-top: 0;
}

a.shareactionsbar__downloadpdflink {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: var(--button-height);
  padding: 5px 20px 5px 20px;
  font: var(--button-font);
  border-radius: var(--button-borderradius);
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid var(--button-color);
  color: var(--button-color);
  background: var(--component-sharepage-button-background, #FFFFFF);
}
a.shareactionsbar__downloadpdflink:hover {
  background-image: none;
  background-color: var(--button-color-hover);
  color: #FFFFFF;
}

a.shareactionsbar__downloadpdflink .far {
  font-size: 20px;
  margin-right: 15px;
}

.sharecomponent__buttonbar__filler {
  flex: 1 0 0px;
}

.wh-share[data-network=print] {
  margin-right: 30px;
}

button.skiplinks {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
}

button.skiplinks:focus {
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}

/*
main
{
  scroll-margin-top: 15px; // keep space for fixed or sticky header floating over the content
}

main:focus
{
  outline: 0;
}
*/
.widget-richcontent--left {
  text-align: left;
}

.widget-richcontent--right {
  text-align: right;
}

.widget-richcontent--center {
  text-align: center;
}

@media (max-width: 799px) {
  .widget-richcontent__column + .widget-richcontent__column {
    margin-top: 15px;
  }
  .widget-richcontent__column:first-child > *:first-child {
    margin-top: 0;
  }
  .widget-richcontent__column:last-child > *:last-child {
    margin-bottom: 0;
  }
  .widget-richcontent--reverseonmobile .widget-richcontent__content {
    display: flex;
    flex-direction: column;
  }
  .widget-richcontent--reverseonmobile .widget-richcontent__column:first-child {
    margin-top: 15px;
  }
  .widget-richcontent--reverseonmobile .widget-richcontent__column:last-child {
    margin-top: 0;
    order: -1;
  }
}
@media (min-width: 800px) {
  .widget-richcontent__content {
    display: flex;
    column-gap: 30px;
  }
  .widget-richcontent__column > *:first-child {
    margin-top: 0;
  }
  .widget-richcontent__column > *:last-child {
    margin-bottom: 0;
  }
  .widget-richcontent__column {
    flex: 1 1 0px;
  }
}
/*
@media (min-width: $minwidth-pagecontent-twocolumn-centered)
{
  .widget-richcontent
  {
    .widget-richcontent__column:nth-child(1)
    {
      @include layout--default-left;
    }
    .widget-richcontent__column:nth-child(2)
    {
      @include layout--default-right;
    }
  }

  .widget-richcontent--equalheight .widget-richcontent__column
  {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
*/
.widget--collapsedcontent {
  margin-top: var(--rtd-spacing-paragraph);
}

.widget--collapsedcontent__intro {
  position: relative;
}

.widget--collapsedcontent__intro ~ .widget--collapsedcontent__viewport {
  margin-top: var(--rtd-spacing-paragraph);
}

.widget--collapsedcontent__intro::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 30px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  opacity: 1;
  transition: height 0.2s ease-in-out 0.1s, opacity 0.2s ease-in-out 0s;
  pointer-events: none;
}

.widget--collapsedcontent__toggler {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: var(--rtd-link-color);
  font: inherit;
  cursor: pointer;
}

.widget--collapsedcontent__toggler::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f067";
  margin-right: 8px;
}

.widget--collapsedcontent__viewport {
  display: none;
  opacity: 0;
  transition: opacity 2s;
}

.widget--collapsedcontent--expanded .widget--collapsedcontent__intro::after {
  display: none;
}
.widget--collapsedcontent--expanded .widget--collapsedcontent__toggler {
  display: none;
}
.widget--collapsedcontent--expanded .widget--collapsedcontent__viewport {
  display: block;
  opacity: 1;
}

.foldablecontent__foldcontainer {
  position: relative;
  overflow: hidden;
  height: 0;
  transition: height 0s;
}

.foldablecontent__foldcontainer::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 30px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  opacity: 1;
  transition: height 0.2s ease-in-out 0.1s, opacity 0.2s ease-in-out 0s;
  pointer-events: none;
}

.widget--foldablecontent--open .foldablecontent__foldcontainer::after {
  opacity: 0;
  transition: height 0.2s, opacity 0.2s ease-in-out 0.1s;
}

.foldablecontent.open > .foldablecontent__foldcontainer::after {
  height: 0;
}

.foldablecontent__contents {
  display: flow-root;
}

.foldablecontent__toggle {
  /* clear <button> styling */
  background: none;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  color: inherit;
  font: inherit;
  margin-top: 3px;
  display: block;
  color: var(--rtd-link-color);
  cursor: pointer;
}

.foldablecontent__toggle::before {
  display: none;
}

html.site--ffw .foldablecontent__toggle .more::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f107";
  font-size: 17px;
  margin-left: 8px;
}
html.site--ffw .foldablecontent__toggle .less::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f106";
  font-size: 17px;
  margin-left: 8px;
}

html.site--wig .foldablecontent__toggle,
html.site--geldlessen .foldablecontent__toggle,
html.site--wvhg .foldablecontent__toggle {
  text-transform: uppercase;
}
html.site--wig .foldablecontent__toggle .more::before,
html.site--geldlessen .foldablecontent__toggle .more::before,
html.site--wvhg .foldablecontent__toggle .more::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f067";
  margin-right: 8px;
}
html.site--wig .foldablecontent__toggle .less::before,
html.site--geldlessen .foldablecontent__toggle .less::before,
html.site--wvhg .foldablecontent__toggle .less::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f068";
  margin-right: 8px;
}

.foldablecontent__toggle > span {
  position: relative;
  display: inline-block;
}

.foldablecontent__toggle .fa {
  position: absolute;
  left: 0;
  font-size: 14px;
  top: 5px;
}

html.layout_mobile .foldablecontent--desktop .foldablecontent__foldcontainer::after,
html.layout_mobile .foldablecontent--desktop .foldablecontent__toggle,
html.layout_tablet .foldablecontent--desktop .foldablecontent__foldcontainer::after,
html.layout_tablet .foldablecontent--desktop .foldablecontent__toggle,
html.layout_desktop .foldablecontent--mobile .foldablecontent__foldcontainer::after,
html.layout_desktop .foldablecontent--mobile .foldablecontent__toggle {
  display: none;
}

html.layout_mobile .foldablecontent--desktop .foldablecontent__foldcontainer,
html.layout_tablet .foldablecontent--desktop .foldablecontent__foldcontainer,
html.layout_desktop .foldablecontent--mobile .foldablecontent__foldcontainer,
.wh-rtd-editor .foldablecontent .foldablecontent__foldcontainer {
  height: auto !important;
}

.foldablecontent__toggle > span.more,
.widget--foldablecontent--open > .foldablecontent__toggle > .less {
  display: block;
}

.foldablecontent__toggle > span.less,
.widget--foldablecontent--open > .foldablecontent__toggle > .more,
.foldablecontent.hasfit .foldablecontent__toggle,
.foldablecontent.hasfit .foldablecontent__foldcontainer::after {
  display: none;
}

/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
/*fieldset*/
.multiselect {
  border: 0;
  padding: 0;
  margin: 0;
}

/*fieldset*/
.multiselect > legend {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}

.multiselect {
  display: flex;
  flex-direction: column;
  position: relative;
}

/*
.multiselect__panel__sticky
{
  position: sticky;
  top: 10px;
  z-index: 99;

  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.multiselect__panel__close::after
{
  position: absolute;
  top: 12px;
  right: 13px;
  //margin-left: 30px;

  @include fontawesome-regular;
  content: $fa-times-circle;
  font-size: 18px;
  color: var(--color-green);
}
*/
.multiselect__closetrigger {
  position: absolute;
  z-index: -99;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  width: 0 !important;
  height: 0 !important;
}

.multiselect__closetrigger:focus {
  outline: none;
}

.multiselect__emptytext--hidden {
  display: none;
}

.multiselect__panel__trigger:focus {
  outline: 1px solid #000000;
}

.multiselect__valuedisplay.multiselect__valuedisplay.multiselect__valuedisplay {
  position: relative;
  left: 0;
  display: block;
  width: 100%;
  max-width: none;
  height: 33px;
  flex: none;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  padding: 0 12px 0 12px;
  white-space: nowrap;
  /*
    .fa-plus-circle
    {
      color: #929292;
    }
  */
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #929292;
  border-radius: 4px;
  background-color: #FFFFFF;
  height: 44px;
  cursor: pointer;
}

.multiselect__valuedisplay::after {
  margin-left: 30px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f055";
  font-size: 18px;
  color: var(--color-green);
}

.multiselect--open .multiselect__valuedisplay::after {
  content: "\f056";
}

.multiselect__valuedisplay__title {
  display: inline-block;
  flex: 1 0 0px;
  background-color: #FFFFFF;
  display: inline-block;
  line-height: 20px;
}

/*
.multiselect__valuedisplay svg
{
  flex: none;
  width: 22px;
}

.multiselect svg > *
{
  fill: #444444;
}

.multiselect:focus-within svg > *
{
  fill: #000000;
}
*/
.multiselect__panel__trigger {
  user-select: none;
}

.multiselect__panel__container {
  opacity: 0;
  pointer-events: none;
}

.multiselect__panel {
  visibility: hidden;
}

.multiselect--open .multiselect__panel {
  visibility: visible;
}

.multiselect--open .multiselect__panel__container {
  opacity: 1;
  pointer-events: auto;
}

.multiselect--open .multiselect__panel__container .multiselect__panel {
  margin-bottom: 100px;
}

.multiselect__panel__trigger.focus-within ~ .multiselect__panel__container {
  opacity: 1;
  pointer-events: auto;
}

.multiselect__panel__trigger.focus-within ~ .multiselect__panel__container .multiselect__panel {
  margin-bottom: 100px;
}

.multiselect--open .multiselect__panel__container {
  opacity: 1;
  pointer-events: auto;
}

.multiselect--open .multiselect__panel {
  margin-bottom: 100px;
}

.multiselect__panel__search {
  display: flex;
  align-items: center;
  margin: 7px 15px 0 15px;
  position: relative;
}

.multiselect__panel__search input {
  background-color: #FFFFFF;
  border: 1px solid transparent;
  border-bottom-color: var(--color-grey-border);
  border-radius: 0;
  width: 100%;
}
.multiselect__panel__search input:hover {
  border-color: var(--color-grey-border);
  border-radius: 4px;
}
.multiselect__panel__search input:focus {
  border: 1px solid #AAAAAA;
  outline: 0;
  border-radius: 4px;
}
.multiselect__panel__search input::placeholder {
  color: var(--color-grey-text-small);
  opacity: 1; /* Firefox */
}

.multiselect__panel__search .far {
  position: absolute;
  right: 15px;
  font-size: 15px;
}

.multiselect__panel__items {
  padding: 15px 15px;
  max-height: 350px;
  max-height: min(350px, 75vh);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.multiselect--hastoggleall .multiselect__panel__item {
  margin-left: 37px;
}

.multiselect--hastoggleall .multiselect__panel__item:first-child {
  margin-left: 0;
}

.multiselect__panel__item {
  display: flex;
}

.multiselect__panel__item input {
  flex: none;
}

.multiselect__panel__item > label + label {
  flex: 1 0 0;
}

.multiselect__panel__item > label:hover + label,
.multiselect__panel__item > label + label:hover {
  color: var(--color-green);
  cursor: pointer;
}

.multiselect__panel__buttonbar {
  justify-content: flex-end;
  border-top: 1px solid #EEEEEE;
  display: flex;
  align-items: center;
  padding-right: 19px;
  height: 60px;
}

.multiselect__panel__cancel {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: 33px;
  color: #888888;
  font-weight: 600;
}

.multiselect__panel__clear,
.multiselect__panel__ok {
  font-family: var(--rtd-heading-fontfamily);
}

.multiselect__panel__clear {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  font: inherit;
  text-decoration: underline;
  color: #666666;
}

.multiselect__panel__ok {
  display: flex;
  align-items: center;
  height: 36px;
  background-color: var(--color-green);
  transition: background-color var(--site-transitions-duration);
  color: #FFFFFF;
  border-radius: 2px;
  border-radius: 2px;
  padding-left: 33px;
  padding-right: 33px;
  cursor: pointer;
}
.multiselect__panel__ok:hover {
  background-color: #008000;
}
@media (max-width: 800px) {
  .multiselect__panel__ok {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.multiselect__panel__ok {
  margin-left: 25px;
}

/*
@media (max-width: 640px)
{
  @include multiselect-fullscreen;
}

@media (min-width: 640px)
{
  @include multiselect-inline;
}
*/
.multiselect__panel__container {
  position: relative;
}

.multiselect__panel {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 6;
}

.multiselect__panel {
  width: 100%;
  min-width: min(300px, 100vw - var(--sidepadding) * 2);
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.63);
  border-radius: 4px;
}

.multiselect__hidden {
  display: none;
}

/*

FIXME: modernize using the --widgetsgrid-columns + --widgetsgrid--maxcolumns approach.
       This simplifies things and allows us for sites to override where there are different amount of max columns.

*/
.widgetsgrid {
  position: relative; /* needed for masonry item positioning, but also use for equalheight view to keep behaviour the same */
}

.widget--widgetsgroup--equalheight.widget--widgetsgroup--cols2 {
  --widgetsgrid-item-width: calc(50% - var(--widgetsgrid-gutter-x, 20px) / 2);
  --widgetsgrid-item-width-2col: 100%;
}

.widget--widgetsgroup--equalheight.widget--widgetsgroup--cols3 {
  --widgetsgrid-item-width: calc(33.33% - var(--widgetsgrid-gutter-x, 20px) * 2 / 3);
  --widgetsgrid-item-width-2col: calc(var(--widgetsgrid-item-width) * 2 + var(--widgetsgrid-gutter-x));
}

.widget--widgetsgroup--equalheight.widget--widgetsgroup--cols4 {
  --widgetsgrid-item-width: calc(25% - var(--widgetsgrid-gutter-x, 20px) * 3 / 4);
  --widgetsgrid-item-width-2col: calc(var(--widgetsgrid-item-width) * 2 + var(--widgetsgrid-gutter-x));
}

/*
$responsive-widgetgrid-1col:   375px;
$responsive-widgetgrid-2col:   768px; / * iPad portrait mode * /
$responsive-widgetgrid-3col:  1000px; / * iPad landscape mode with 12px padding outside page content, 30px padding inside centered content * /
$responsive-widgetgrid-4col:  1200px; / * desktop * /
*/
/* If not enough width for 4 columns:  downgrade 4 col grids to 3 */
@media (max-width: 1199px) {
  /* When we get below 4col, revert the 4col mode to 3col */
  .widget--widgetsgroup--equalheight.widget--widgetsgroup--cols4 {
    --widgetsgrid-item-width: calc(33.33% - var(--widgetsgrid-gutter-x, 20px));
    --widgetsgrid-item-width-2col: calc(66.66% - var(--widgetsgrid-gutter-x, 20px));
  }
}
@media (max-width: 999px) {
  /* When we get below 4col, revert the 4col and 3col mode to 2col */
  .widget--widgetsgroup--equalheight.widget--widgetsgroup--cols4,
  .widget--widgetsgroup--equalheight.widget--widgetsgroup--cols3 {
    --widgetsgrid-item-width: calc(50% - var(--widgetsgrid-gutter-x, 20px));
    --widgetsgrid-item-width-2col: 100%;
  }
}
.site--ffw .content__contentcolumn .widget--widgetsgroup--equalheight.widget--widgetsgroup--cols4,
.site--ffw .content__contentcolumn .widget--widgetsgroup--equalheight.widget--widgetsgroup--cols3 {
  --widgetsgrid-item-width: calc(50% - var(--widgetsgrid-gutter-x, 20px) / 2);
  --widgetsgrid-item-width-2col: 100%;
}

/* Single column, just stack items */
@media (max-width: 640px) {
  .widget--widgetsgroup--equalheight .widgetsgrid .widget + .widget {
    margin-top: var(--widgetsgrid-gutter-y, 20px);
  }
}
/* Grid */
@media (min-width: 640px) {
  .widget--widgetsgroup--equalheight .widgetsgrid {
    display: flex;
    flex-flow: row wrap;
    column-gap: var(--widgetsgrid-gutter-x);
    row-gap: var(--widgetsgrid-gutter-y);
    width: 100%;
  }
  .widget--widgetsgroup--equalheight .widgetsgrid > .widget {
    position: relative;
    flex-basis: auto;
    width: var(--widgetsgrid-item-width);
  }
  .widget--widgetsgroup--equalheight .widgetsgrid > .widget[data-cols="2"] {
    flex-basis: auto;
    width: var(--widgetsgrid-item-width-2col);
  }
}
/*

How it works:
- We have the preferred amount of columns in --widgetsgrid-columns
- The max amount of columns is set using media queries (and specific overrides for special situations/places)
- The amount of columns is set using "column-count"
  - this causes a fallback layout using CSS columns
  - as a progressive enhencement a Javascript Masonry component will do the masonry layouting (using the set column-count as amount of columns)
    (this allows the column layout as fallback if Javascript fails)

*/
.widget--widgetsgroup--masonry .widgetsgrid {
  column-count: min(var(--widgetsgrid-columns), var(--widgetsgrid-maxcolumns));
  --widgetsgrid-maxcolumns: 1;
  column-gap: 20px;
  row-gap: 20px;
}

.widget--widgetsgroup--masonry.widget--widgetsgroup--cols2 {
  --widgetsgrid-columns: 2;
}

.widget--widgetsgroup--masonry.widget--widgetsgroup--cols3 {
  --widgetsgrid-columns: 3;
}

.widget--widgetsgroup--masonry.widget--widgetsgroup--cols4 {
  --widgetsgrid-columns: 4;
}

.widget--widgetsgroup--masonry.widget--widgetsgroup--cols5 {
  --widgetsgrid-columns: 5;
}

@media (max-width: 639px) {
  .widget--widgetsgroup--masonry .widgetsgrid {
    display: flex;
    flex-wrap: wrap;
  }
  .widget--widgetsgroup--masonry .widgetsgrid > * {
    width: 100%;
  }
}
@media (min-width: 640px) {
  .widget--widgetsgroup--masonry .widgetsgrid {
    --widgetsgrid-maxcolumns: 2;
  }
}
@media (min-width: 1000px) {
  .widget--widgetsgroup--masonry .widgetsgrid {
    --widgetsgrid-maxcolumns: 4;
  }
}
@media (min-width: 1200px) {
  .widget--widgetsgroup--masonry .widgetsgrid {
    --widgetsgrid-maxcolumns: 5;
  }
}
@media (min-width: 640px) {
  .content__contentcolumn .widgetsgrid.widgetsgrid {
    column-count: 2;
  }
}
.widget--widgetsgroup--masonry {
  column-gap: 20px;
}

@media (min-width: 1000px) {
  .widget--widgetsgroup--cols3,
  .widget--widgetsgroup--cols4 {
    --button-padding: 4px 17px 4px 17px;
  }
}
@media (max-width: 1199px) {
  .widget--widgetsgroup--cols4 {
    --button-padding: 4px 17px 4px 17px;
  }
}
/*
This overrides the default WebReader style

WebReader CSS on 28 sep 2022
https://cdn1.readspeaker.com/script/10218/webReader/r/r2050/ReadSpeaker.Styles-Button.css?v=3.6.1.2050
*/
html {
  --webreader-height: 35px;
  --webreader-button-width: var(--webreader-height);
  --webreader-border: 1px solid #DDDDDD;
  --webreader-border-color: #DDDDDD;
  --webreader-border-radius: 4px;
  --webreader-hoverbgcolor: #EEEEEE;
  --webreader-border-focus-color: #333333;
  --webreader-themecolor: var(--color-green-small);
  --webreader-popup-panel-arrowsize: 10px;
  --webreader-popup-panel-backgroundcolor: #E0E0E0;
  --webreader-popup-panel-padding: 12px;
}

.rsbtn.rsbtn_customskin .rsbtn_play {
  background: #efe;
  border-color: var(--webreader-themecolor);
}
.rsbtn.rsbtn_customskin .rsbtn_play .rsbtn_left .rsbtn_text::before {
  color: var(--webreader-themecolor);
  /* Speaker icon */
}
.rsbtn.rsbtn_customskin .rsbtn_play .rsbtn_left .rsbtn_text .rsbtn_label {
  color: var(--webreader-themecolor);
  /* Listen label */
}
.rsbtn.rsbtn_customskin .rsbtn_play .rsbtn_right {
  border-color: var(--webreader-themecolor);
}
.rsbtn.rsbtn_customskin .rsbtn_play .rsbtn_right::before {
  color: var(--webreader-themecolor);
  /* Play icon */
}
.rsbtn.rsbtn_customskin .rsbtn_play:hover {
  background: #fff;
}
.rsbtn.rsbtn_customskin .rsbtn_play:hover .rsbtn_left .rsbtn_text::before {
  color: #070;
  /* Speaker icon */
}
.rsbtn.rsbtn_customskin .rsbtn_play:hover .rsbtn_left .rsbtn_text .rsbtn_label {
  color: #070;
  /* Listen label */
}
.rsbtn.rsbtn_customskin .rsbtn_play:hover .rsbtn_right::before {
  color: #070;
  /* Play icon */
}
.rsbtn.rsbtn_customskin .rsbtn_tooltoggle {
  background: #efe;
  border-color: var(--webreader-themecolor);
  color: var(--webreader-themecolor);
}
.rsbtn.rsbtn_customskin.zoom-tooltoggler .rsbtn_tooltoggle {
  background: #fff;
  border-color: var(--webreader-themecolor);
  color: #070;
}

.rsbtn .rsbtn_play .rsbtn_left .rsbtn_text span {
  color: var(--webreader-themecolor) !important;
}

.rsbtn .rsbtn_play .rsbtn_left .rsbtn_text::before,
.rsbtn .rsbtn_play .rsbtn_left .rsbtn_text::after {
  display: none;
}

.rsbtn .rsbtn_play:focus .rsbtn_left .rsbtn_text,
.rsbtn .rsbtn_play:focus .rsbtn_left .rsbtn_text span {
  color: var(--webreader-themecolor) !important;
}

.rs_addtools .rsbtn_play:focus .rsbtn_right::before,
.rsbtn .rsbtn_play:focus .rsbtn_right::before {
  color: var(--webreader-themecolor) !important;
}

/*

#readspeaker_button
  a.rsbtn_play
  div.rsbtn_exp.rsimg.rspart
  button.rsbtn_tooltoggle
  button.rsbtn_focusforward
  div.rsbtn_toolpanel
*/
#rsbtn_scrollcontrols span.rsicn {
  color: var(--webreader-themecolor);
}

#readspeaker_button {
  display: flex;
  width: fit-content;
  padding-bottom: 0;
  box-sizing: border-box;
  /*
    .rs_addtools .rsbtn_play .rsbtn_right:before
  , .rsbtn .rsbtn_play .rsbtn_right:before
  , .rspopup .rspopup_btn.rspopup_play:before
  {
      content: "\e902";
  }
  */
  /*
    &.rsexpanded .rsbtn_play
    {
      border-radius: var(--webreader-border-radius) 0 0 var(--webreader-border-radius);
    }
  */
}
@media print {
  #readspeaker_button {
    display: none !important;
  }
}
#readspeaker_button > * {
  float: none;
}
#readspeaker_button.rsbtn {
  margin-bottom: 0;
}
#readspeaker_button .rs_addtools .rsbtn_play .rsbtn_left,
#readspeaker_button .rsbtn .rsbtn_play .rsbtn_left {
  float: none;
  height: auto;
}
#readspeaker_button .rsbtn_text::before {
  height: auto;
  line-height: 100%;
}
#readspeaker_button .rsbtn_text span {
  padding-left: 0;
  font-size: 15px;
  line-height: 100%;
  height: auto;
}
#readspeaker_button .rs_addtools.zoom-tooltoggler .rsbtn_tooltoggle,
#readspeaker_button .rsbtn.zoom-tooltoggler .rsbtn_tooltoggle {
  left: auto;
  line-height: auto;
}
#readspeaker_button .rsbtn_play {
  margin-bottom: 0;
}
#readspeaker_button .rsbtn_player_item {
  float: none;
  line-height: 1;
}
#readspeaker_button span.rsicn {
  background-color: transparent;
}
#readspeaker_button .rsbtn_tooltoggle {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  position: static;
  width: auto;
  height: auto;
}
#readspeaker_button .rsbtn_toolpanel {
  width: auto !important;
  min-width: 0 !important;
}
#readspeaker_button .rsbtn_powered {
  position: static;
  left: auto;
  top: auto;
}
#readspeaker_button button.rsbtn_tool {
  padding: 0;
}
#readspeaker_button .rsbtn_play {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
#readspeaker_button .rsbtn_left, #readspeaker_button .rsbtn_right {
  margin: 0;
  float: none;
  height: auto;
  line-height: 1;
  border: 0;
}
#readspeaker_button.rsexpanded .rsbtn_play .rsbtn_right {
  display: block;
}
#readspeaker_button .rsbtn_right.rsimg.rsplay.rspart::before {
  display: block;
  content: "\e902";
}
#readspeaker_button span.rsicn {
  color: var(--webreader-themecolor);
}
#readspeaker_button .rsbtn_play {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  border: var(--webreader-border);
  border-radius: var(--webreader-border-radius);
  background: #fff;
  padding: 0px 10px 0 15px;
  height: 35px;
  height: var(--webreader-height, 35px);
}
#readspeaker_button .rsbtn_play:focus {
  border-color: var(--webreader-border-focus-color);
}
#readspeaker_button .rsbtn_tooltoggle {
  border-radius: var(--webreader-border-radius) 0 0 var(--webreader-border-radius);
}
#readspeaker_button .rsbtn_tooltoggle + .rsbtn_play {
  border-radius: 0 var(--webreader-border-radius) var(--webreader-border-radius) 0;
}
#readspeaker_button .rsbtn_exp {
  height: 35px;
  height: var(--webreader-height, 35px);
}
#readspeaker_button.rsexpanded.rsbtn_exp {
  display: flex;
}
#readspeaker_button .rsbtn_exp_inner {
  border: 0;
  display: flex;
  height: 100%;
}
#readspeaker_button .rsbtn_player_item, #readspeaker_button .rsbtn_tooltoggle,
#readspeaker_button .rsbtn_tool .rsicn {
  border: var(--webreader-border);
  margin-left: -1px;
  width: var(--webreader-button-width);
  background-color: #FFFFFF;
  cursor: pointer;
}
#readspeaker_button .rsbtn_player_item:focus,
#readspeaker_button .rsbtn_tooltoggle:focus,
#readspeaker_button .rsbtn_tool:focus .rsicn {
  background-color: var(--webreader-hoverbgcolor);
  border-color: var(--webreader-border-focus-color);
  z-index: 1;
}
#readspeaker_button .rsbtn_player_item:hover,
#readspeaker_button .rsbtn_tooltoggle:hover,
#readspeaker_button .rsbtn_tool:hover .rsicn {
  background-color: var(--webreader-themecolor);
  color: #FFFFFF;
}
#readspeaker_button .rsbtn_player_item:hover span.rsicn,
#readspeaker_button .rsbtn_tooltoggle:hover span.rsicn,
#readspeaker_button .rsbtn_tool:hover .rsicn span.rsicn {
  color: #FFFFFF;
}
#readspeaker_button .rsbtn_player_item:active,
#readspeaker_button .rsbtn_tooltoggle:active,
#readspeaker_button .rsbtn_tool:active .rsicn {
  box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
#readspeaker_button .rsbtn_volume.rsbtn_player_item {
  margin-left: 6px;
}
#readspeaker_button .rsbtn_closer.rsbtn_player_item {
  margin-left: 6px;
}
#readspeaker_button .rsbtn_tooltoggle {
  margin-left: 0px;
}
#readspeaker_button .rsbtn_left.rsimg.rspart {
  margin-left: 0;
}
#readspeaker_button .rsimg .rsbtn_text {
  display: flex;
  align-items: center;
}
#readspeaker_button .rsimg .rsbtn_text::before {
  position: static;
  color: var(--webreader-themecolor);
  margin-right: 15px;
  display: none;
}
#readspeaker_button .rsbtn_focusforward {
  /*
      position: static !important; // abusing this as breakline
      width: 100% !important; // ReadSpeaker sets inline styles
  */
}
#readspeaker_button .rsbtn_tools {
  border: 0;
}
#readspeaker_button .rsbtn_toolpanel.vertical,
#readspeaker_button .rsbtn_toolpanel.vertical .rsbtn_tools {
  position: static;
}
#readspeaker_button .rsbtn_tools_inner {
  position: absolute;
  right: 0;
  top: var(--webreader-height);
  width: min-content;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  border: 0;
  padding: var(--webreader-popup-panel-padding);
  border-radius: var(--webreader-border-radius);
  background-color: var(--webreader-popup-panel-backgroundcolor);
  margin-right: -6px;
  margin-top: var(--webreader-popup-panel-arrowsize);
}
#readspeaker_button .rsbtn_toolpanel .rsbtn_tools::before {
  content: "";
  width: 0;
  height: 0;
  border-left: var(--webreader-popup-panel-arrowsize) solid transparent;
  border-right: var(--webreader-popup-panel-arrowsize) solid transparent;
  border-bottom: var(--webreader-popup-panel-arrowsize) solid var(--webreader-popup-panel-backgroundcolor);
}
#readspeaker_button button.rsbtn_tool {
  display: flex;
  align-items: center;
}
#readspeaker_button button.rsbtn_tool .rsicn {
  flex: none;
}
#readspeaker_button button.rsbtn_tool .rsbtn_btnlabel {
  padding-right: 30px;
  white-space: nowrap;
}
#readspeaker_button button.rsbtn_tool .rs_minitoggler {
  float: none;
  margin: 0 0 0 auto;
}
#readspeaker_button .rsbtn_powered {
  display: none;
  /*
  display: block;
  width: max-content;
  margin-left: auto;
  color: #666666;
  */
}
#readspeaker_button .rsbtn_status_overlay {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: #FFFFFF;
}
#readspeaker_button .rsbtn_status_overlay span {
  color: #FFFFFF;
}

#readspeaker_button .rsbtn_tools_inner {
  position: absolute;
  left: 0;
  right: auto;
}
#readspeaker_button .rsbtn_toolpanel .rsbtn_tools::before {
  position: absolute;
  right: calc(var(--webreader-button-width) / 2 - 7px);
  top: var(--webreader-height);
}
#readspeaker_button.rsexpanded .rsbtn_tools_inner {
  position: absolute;
  right: 0;
  left: auto;
}
#readspeaker_button.rsexpanded .rsbtn_toolpanel .rsbtn_tools::before {
  position: absolute;
  right: calc(var(--webreader-button-width) / 2 - 7px);
  top: var(--webreader-height);
}

.richdoc--toplevel > #readspeaker_button + * {
  margin-top: 30px;
}

/*

.body__content
  .pagecontent__topbar
  .pagecontent__main ... ADDME

*/
* {
  box-sizing: border-box;
}

a img {
  border: 0; /* [IEFIX] IE10 adds a border to any image within an anchor */
}

/*
  html.geldlesactive
, html.geldlesactive body
{
  overflow: hidden;
}
*/
.body__scrolltotopbutton {
  background: #FFFFFF;
  color: var(--color-theme);
  border-radius: 50%;
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 42px;
  height: 42px;
  border: 1px solid var(--color-theme);
  cursor: pointer;
  box-shadow: 0 0 1px 2px #FFFFFF, 0 0 15px 4px rgba(255, 255, 255, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s;
}

html.showtotopbutton .body__scrolltotopbutton {
  opacity: 1;
  pointer-events: auto;
}

.body__scrolltotopbutton:hover {
  background: var(--color-theme);
  color: #FFFFFF;
}

.body__scrolltotopbutton .far {
  font-size: 18px;
}

.embeddedobject .widget__title {
  padding-bottom: 15px;
}

.widget-downloadsandlinks .widget__title {
  padding-bottom: 0;
}

[data-popup],
.wh-share,
.siteaction--emailpagelink {
  cursor: pointer;
}

/* layout modes */
@media (max-width: 799px) {
  .layout--widgets::after {
    content: "bottom";
    display: none;
  }
}
.body__widgetsbottom {
  display: none;
}

.body__widgetsbottom.body__widgetsbottom--visible {
  display: block;
}

.body__widgetsbottom--alwaysvisible {
  display: block;
}

.pagecontent__topbar {
  width: 100%;
  max-width: var(--pagegrid-contentarea-width-full);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 15px;
}

.pagecontent__topbar:empty {
  display: none;
}

.siteheader-menubar + .body__content > .pagecontent__topbar:first-child,
.pageheader + .body__content > .pagecontent__topbar:first-child {
  margin-top: 20px;
}

.body__widgetsbottom {
  display: grid;
  margin-top: 60px;
}

@media print {
  .body__widgetsbottom:empty {
    display: none;
  }
}
.body__widgetsbottom::before {
  content: "";
  display: block;
  /* background-color: #EEEEEE; */
  /* background: linear-gradient(to bottom, rgba(238, 238, 238, 1) 0, rgba(238, 238, 238, 0) 100%); */
  /*
    background: linear-gradient( to bottom
                               , rgba(238, 238, 238, 1) 0
                               , rgba(238, 238, 238, 1) calc(100% - 80px)
                               , rgba(238, 238, 238, 0) 100%
                               );
  */
  background: linear-gradient(to bottom, #EBEBEB 0%, #FFFFFF 188px);
  grid-column: 1;
  grid-row: 1;
}

.body__widgetsbottom .widgetsgrid {
  margin-top: 70px;
  margin-bottom: 70px;
  width: 100%;
  max-width: min(100% - var(--sidepadding) * 2, var(--pagegrid-contentarea-width));
  margin-left: auto;
  margin-right: auto;
}

.body__widgetsbottom .widget--widgetsgroup {
  grid-column: 1;
  grid-row: 1;
}

/* When the mainmenu is hidden, the large CTA block of the home
   will take over the role of divider by sticking to the sides.
*/
@media (max-width: 1000px) {
  /* regardless of the padding mode the largecta widget at the top
     of the home page will stick to the sides in single-column mode
  */
  html.page--home .widget--largecta.widget--largecta {
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
  }
  html.page--home .widget--largecta__body.widget--padding {
    padding: 20px;
  }
}
@media (max-width: 575px) {
  .widget--widgetsgroup__title {
    font-size: 25px;
  }
}
body {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

/**********************************************************
Layout page so that the footer is always pushed to
either the bottom of the viewport or the bottom of the page
**********************************************************/
html {
  overflow-y: scroll;
  padding: 0;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  min-height: 100vh;
}

/* take remaining space to push footer down the buttom.
   (otherwise we have to test for each type of (possible custom) pages to see if the footer is pushed down correctly)
*/
.site__footerspacer {
  flex: 1;
}

body {
  /* be a positioning container so #slidemenu-container
     can stretch to the full page size.
     (and it can act as clipping container to prevent the menu from
     stretching the <body> when it's animated outside the page)
  */
  position: relative;
}

/* Define all distances between content parts on the page ****/
.content--singlecolumn {
  margin-top: 25px; /* 25 on home */
}

.content--twocolumn > .content__contentcolumn {
  padding-top: 25px;
}

.content--twocolumn > .content__widgetscolumn {
  padding-top: 25px;
}

.content--twocolumn::after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 200px;
  width: 35px;
}

.page__actionsbar,
.page__nootvanderedactie,
.coursematerials {
  margin-top: 30px;
}

.pagination {
  margin-top: 20px;
}

.site__gobacklink-container,
.site__actionbar-container {
  margin-top: 50px;
}

/*************************************************************/
.content--pad {
  /* 12 h - 14 c */
}
@media (max-width: 767px) {
  .content--pad {
    /* part of content flow so the padding must be the same as normal content padding */
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 9px;
    padding-right: 9px;
  }
}
@media (max-width: 768px) {
  .content--pad {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media (min-width: 1000px) {
  .content--pad {
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 21px;
    padding-right: 21px;
  }
}

/* two column page layout (not to be confused with the amount of columns in a widgetgrid) */
@media (min-width: 800px) {
  .body__content--twocolumn .pageheader-quicklinks {
    display: none;
  }
  .body__content--singlecolumn .pagecontent__topbar {
    width: auto;
    margin-left: calc((100vw - var(--pagegrid-contentarea-width-full)) / 2);
    margin-right: 0;
    padding-right: 0;
  }
  .body__content--singlecolumn .pageheader-quicklinks {
    margin-top: -100px;
    width: 280px;
    float: right;
    position: relative;
    margin-right: calc((100vw - var(--pagegrid-contentarea-width-full)) / 2);
    margin-left: 30px;
    margin-bottom: 30px;
  }
  html.site--geldlessen .content__widgetscolumn .pageheader-quicklinks {
    display: block;
  }
  .content--singlecolumn,
  .content--twocolumn {
    flex-shrink: 0;
  }
  .content__contentcolumn {
    /* consume any available space */
    min-width: 0; /* [IEFIX] makes IE shrink down to what room is available instead of what it would need to not have to wrap text */
    flex: 1 0 0px;
  }
  .content__widgetscolumn {
    width: var(--pagecontent-widgetcolumn-width);
    flex-shrink: 0;
    /* reset the padding */
    padding-left: 0;
    padding-right: 0;
  }
  /* always a simple vertical view when we have a widget column (2 column mode) */
  .content__widgetscolumn .widgetsgrid {
    column-count: 1 !important;
  }
  .content__widgetscolumn .widgetsgrid.content--marginh-small {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  /*
  Not needed when masonry is active

  .content__widgetscolumn .widget + .widget
  {
    margin-top: 20px;
  }
  */
}
@media (max-width: 799px) {
  .body__content--singlecolumn .pageheader-quicklinks {
    width: 100%;
    max-width: var(--pagegrid-contentarea-width-full);
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    margin-bottom: 30px;
  }
  html.site--geldlessen .pageheader-quicklinks {
    display: block;
  }
  html.site--geldlessen .content__widgetscolumn .pageheader-quicklinks {
    display: none;
  }
  .content--twocolumn {
    flex-direction: column;
  }
  .content__widgetscolumn.widgetcolumn--empty {
    display: none;
  }
}
.page__nootvanderedactie {
  padding-top: 30px;
  border-top: 1px solid #dcdcdc;
  clear: both;
}

.site__gobacklink-container,
.site__actionbar-container--single {
  padding-top: 10px;
  border-top: 1px solid #dcdcdc;
}

@media print {
  .site__gobacklink-container,
  .site__actionbar-container {
    display: none;
  }
}
a.site__gobacklink {
  display: inline-block;
}

a.site__gobacklink > * {
  vertical-align: middle;
}

.site__gobacklink::before {
  display: inline-block;
  vertical-align: middle;
  content: url("./web/img/symbols/arrow-left-rounded.green.svg");
  width: 24px;
  margin-right: 10px;
}

/* when there is a pageheader AND quicklinks, make the quicklinks overlap the pageheader */
@media (min-width: 800px) {
  .pageheader ~ .body__content > .content > .content__widgetscolumn--withquicklinks {
    margin-top: -100px;
  }
  .defaultpageheader ~ .body__content > .content > .content__widgetscolumn--withquicklinks {
    margin-top: 0;
  }
}
.filteredoverview__resultsanchor {
  display: block;
}