/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
@media print {
  .siteheader-top,
  .siteheader-menubar,
  #slidemenu-container,
  .sharecomponent {
    display: none;
  }
}
.site__printheader {
  display: none;
}

.site__printheader__logo {
  display: block;
  margin: 0 auto;
  height: 83px;
}

/*
NOTE: Firefox and Chrome 49+ (mar 2016?) support columns in print.
      Safari 11 does not support this yet.

Chrome: https://bugs.chromium.org/p/chromium/issues/detail?id=99358
Safari: https://bugs.webkit.org/show_bug.cgi?id=15546

NOTE: Alternative could be to use flex or grid? But printouts are too low prio to spend much time on researching alternatives.
*/
@media print and (orientation: portrait) {
  body {
    width: 210mm;
    height: 296mm;
  }
  .activitiesoverview {
    column-count: 2;
  }
}
@media print and (orientation: landscape) {
  body {
    width: 296mm;
    height: 210mm;
  }
  .activitiesoverview {
    column-count: 3;
  }
}
@media print {
  html {
    overflow: auto !important;
  }
  body {
    padding: 0;
    margin: 0;
  }
  @page {
    margin: 15mm 15mm 15mm 15mm;
  }
  .richdoc a[href^="http://"],
  .richdoc a[href^="https://"] {
    overflow-wrap: break-word;
  }
  .richdoc a[href^="http://"]:after,
  .richdoc a[href^="https://"]:after {
    content: " (" attr(href) ")";
    font-size: 90%;
  }
  .site__printheader {
    display: block;
  }
  .site__header,
  .site__mobileheader {
    display: none;
  }
  body,
  .body__content,
  .content--twocolumn {
    display: block !important;
  }
  .slidemenu-container,
  .activitiesoverview__header {
    display: none;
  }
}