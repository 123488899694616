.widget--links
{
  border-radius: var(--widget-border-radius);
}

.widget--links .widget--links__items
{
  container-name: minfinLargeListing minfinLargeListingLine;
  container-type: inline-size;
}
