/*  Based on .lesmateriaal__link */
.widget--buttoncta-gl
{
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 20px 13px 20px;
  border-radius: var(--widget-border-radius);
/*   background: linear-gradient(180deg, #51D161 0%, #0B923E 100%); // groene gradient*/
  background: var(--widget-ctabutton-background);
  color: #FFFFFF;
  text-decoration: none;

  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: min-content min-content;

  transition: background var(--button-transition-duration);
}

.widget--buttoncta-gl:hover
{
  background: var(--widget-ctabutton-hover-background);
  color: #FFFFFF;
}

.widget--buttoncta-gl__title.widget--buttoncta-gl__title
{
  grid-column: 1;
  grid-row: 1;

  color: #FFFFFF;
  font: 500 22px/24px var(--rtd-heading-fontfamily);
}

.widget--buttoncta-gl__body
{
  grid-column: 1;
  grid-row: 2;

  opacity: 0.7;

  font: 500 15px var(--rtd-alternative-fontfamily);
}

.widget--buttoncta-gl::after
{
  /* @include fontawesome-solid; */
  font: var(--fontawesome-solid);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  content: var(--fa-external-link);

  grid-column: 2;
  grid-row: 1 / span 2;

  font-size: 24px;

  margin-left: 30px;
}
