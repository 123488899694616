@media (min-width: 650px) {
  .page--trainingoverview .pageheader__expandedfilters__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 30px;
    row-gap: 30px;
  }
  .page--trainingoverview .pageheader__expandedfilters__content > * {
    width: min(30vw, 300px);
    min-width: 200px;
  }
}
.page--trainingoverview .tagcategory {
  margin: 0 !important;
  padding: 0 !important;
}
.page--trainingoverview #tagcategory__title--niveau + .tagcategory__options {
  display: flex;
  column-gap: 50px;
}
.page--trainingoverview .multiselect__valuedisplay__title {
  width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 649px) {
  .page--trainingoverview .pageheader__expandedfilters__content {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }
  .page--trainingoverview .multiselect__panel {
    width: 100%;
  }
}
@media (max-width: 900px) {
  .page--trainingoverview #tagcategory__title--niveau + .tagcategory__options {
    column-gap: 30px;
  }
}