/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
.page--lesmateriaaloverview .body__content,
.page--trainingoverview .body__content {
  margin-bottom: 60px;
}

@media (max-width: 799px) {
  .page--lesmateriaaloverview .genericfilters__panel + .genericfilters__panel {
    margin-top: 30px;
  }
  .page--lesmateriaaloverview .genericfilters__item > .genericfilters__item + .genericfilters__item {
    margin-top: 15px;
  }
}
@media (min-width: 800px) {
  .page--lesmateriaaloverview .pageheader__expandedfilters__content, .page--lesmateriaaloverview .pageheader__expandedfilters__content {
    display: flex;
    justify-content: space-between;
  }
  .page--lesmateriaaloverview .genericfilters__panel + .genericfilters__panel {
    margin-left: 55px;
  }
  .page--lesmateriaaloverview .genericfilters__item > .genericfilters__item + .genericfilters__item {
    margin-top: 15px;
  }
  .page--lesmateriaaloverview .lesmateriaalfilters--column1 {
    max-width: 32%;
  }
  .page--lesmateriaaloverview .lesmateriaalfilters--column2 {
    max-width: 32%;
  }
  .page--lesmateriaaloverview .lesmateriaalfilters--column3 {
    max-width: 32%;
    min-width: 300px;
  }
}