html {
  --pageicon-width-max: 75px;
  --pageicon-height-max: 75px;
}

.pageheader__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: var(--themecolor);
}

.pageheader__icon img,
.pageheader__icon svg {
  max-width: var(--pageicon-width-max);
  max-height: var(--pageicon-height-max);
  width: 100%;
}

.pageheader__icon--news svg {
  max-width: 70px;
  max-height: 70px;
}

.pageheader__icon--event {
  font-size: 70px;
}

.pageheader__content--textandql .pageheader__icon {
  flex: none;
  width: 130px;
  height: 130px;
  margin-top: -65px;
  border-radius: 50%;
  background: #FFFFFF;
  margin-right: 110px;
}