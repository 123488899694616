/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
.embeddedobject-mpform {
  margin-top: 15px;
}

.mpForm {
  border: 1px solid #0db24c;
  box-shadow: 0 0.5px 4px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  background-color: #FFFFFF;
  padding: 15px;
  margin-left: -15px;
  margin-right: -15px;
}

.mpForm ul {
  list-style-type: none;
  margin: 8px 0;
  padding: 0;
}

.mpForm table {
  width: 100%;
}

.mpForm li {
  margin-bottom: 3px;
}

.mpForm td {
  vertical-align: top;
}

.mpLabelRow {
  margin-bottom: 5px;
}

.mpForm input[type=text],
.mpForm input[type=email] {
  width: 100%;
}

.mpFormLabel {
  padding-top: 8px;
  width: 180px;
}

.mpForm .submitCell {
  padding-top: 15px;
  text-align: right;
}

.mpForm .submitButton {
  -webkit-appearance: none;
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  border: 0;
  text-decoration: none;
  position: relative;
  font: normal 14px/100% Arial;
  text-align: left;
  min-height: 44px;
  padding: 8px 15px;
  border-radius: 5px;
  color: #FFFFFF;
  background: var(--themecolor);
  cursor: pointer;
}

.mpForm .submitButton:hover {
  background-color: #21b833;
}

.mpFormField li {
  display: flex;
  align-items: baseline;
}

.mpForm input[type=radio],
.mpForm input[type=checkbox] {
  transform: translateY(-3px);
}

.mpForm input[type=radio] + label,
.mpForm input[type=checkbox] + label {
  display: block;
  padding-left: 10px;
  padding-top: 3px;
}

@media (max-width: 500px) {
  .mpForm {
    margin: 0;
  }
  .mpForm td {
    display: block;
  }
}