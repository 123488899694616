/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
html {
  --zindex-modalitylayer: 49;
  --sidemainmenu-zindex: 50;
  --filteredoverview-teaserbar-zindex: 5;
}

html.page--lesmateriaal {
  --pagecontent-widgetcolumn-width: 279px;
}

html.page--home {
  --pagecontent-widgetcolumn-width: 315px;
}

.body__content > .content > main {
  margin-top: 30px;
}

.content__widgetscolumn .widgetsgrid {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}

/* tablet style padding */
@media (min-width: 500px) and (max-width: 999px) {
  html {
    --widget-expand-h: 3px;
  }
}
/* mobile style padding */
@media (max-width: 499px) {
  /* in mobile mode only the highlightbig widget expands */
  .widget--highlightbig {
    --widget-expand-h: 10px;
    border-radius: 0;
  }
}
@media (max-width: 799px) {
  .content__contentcolumn,
  .content__widgetscolumn {
    width: 100%;
    max-width: var(--pagegrid-contentarea-width-full);
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
  }
  :where(.content--wide .richdoc--toplevel > .widget),
  :where(.content--wide > .sharecomponent) {
    width: 100%;
    max-width: min(100% - var(--sidepadding) * 2, var(--pagegrid-contentarea-width));
    margin-left: auto;
    margin-right: auto;
  }
  :where(.content--wide .richdoc--toplevel > *:not(.widget)) {
    width: 100%;
    max-width: var(--pagegrid-contentarea-width-full);
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
  }
}
@media (min-width: 800px) {
  .content--twocolumn {
    width: 100%;
    max-width: var(--pagegrid-contentarea-width-full);
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    display: flex;
  }
  :where(.content--singlecolumn .richdoc--toplevel > .widget),
  :where(.content--singlecolumn > .sharecomponent) {
    width: 100%;
    max-width: min(100% - var(--sidepadding) * 2, var(--pagegrid-contentarea-width));
    margin-left: auto;
    margin-right: auto;
  }
  :where(.content--singlecolumn .richdoc--toplevel > *:not(.widget)) {
    width: 100%;
    max-width: var(--pagegrid-contentarea-width-full);
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
  }
  :where(.content--singlecolumn .richdoc--toplevel > ol.unordered),
  :where(.content--singlecolumn .richdoc--toplevel > ul.unordered) {
    max-width: var(--pagegrid-contentarea-width);
  }
  .content__contentcolumn {
    margin-right: 40px;
  }
}
/* in-flow with the widgets which expand a little outside of the (normal paragraph) content area */
/* Widgets within the contentarea (but not within a grid)
   will have their borders expanding to inside the padding around the contentarea
*/
.content--singlecolumn .richdoc > .widget--links,
.content--singlecolumn .richdoc > .widget--downloads,
.content--singlecolumn .richdoc > .widget--highlightbig,
.content--singlecolumn .tabscontrol,
.content--singlecolumn .widget--lessongroups,
.content--singlecolumn .widget--neoitems {
  max-width: calc(var(--pagegrid-contentarea-width-full) + var(--widget-expand-h) * 2);
}

.content--twocolumn {
  /* eat away padding to compensate for the shifted edges (due to neg. margins)
     so the content stays in line with normal RTE content
  */
}
.content--twocolumn .richdoc > .widget--links,
.content--twocolumn .richdoc > .widget--downloads,
.content--twocolumn .richdoc > .widget--highlightbig,
.content--twocolumn .tabscontrol,
.content--twocolumn .widget--lessongroups,
.content--twocolumn .widget--neoitems {
  margin-left: calc(-1 * var(--widget-expand-h));
  margin-right: calc(-1 * var(--widget-expand-h));
}
.content--twocolumn .richdoc > .widget--links .widget--padding,
.content--twocolumn .richdoc > .widget--downloads .widget--padding,
.content--twocolumn .widget--neoitems .widget--padding {
  --widget-padding-h: var(--widget-expand-h);
}

@media screen and (max-width: 768px) {
  .content--centered {
    width: auto;
  }
}
@media screen and (min-width: 1000px) {
  .content--centered {
    width: var(--pagegrid-contentarea-width-full);
    margin-left: auto;
    margin-right: auto;
  }
}