.widget-quote {
  display: flex;
  align-items: center;
}

.widget-quote__image {
  width: var(--widget-quote-photo-size);
  height: var(--widget-quote-photo-size);
  border-radius: 50%;
  flex: none;
  margin-right: 20px;
}

.widget-quote__text {
  flex: 1 0 0px;
}

.widget-quote__quote.widget-quote__quote > p {
  color: var(--widget-quote-name-color);
  font: 26px/32px var(--rtd-heading-fontfamily);
}

.widget-quote__person {
  margin-top: 10px;
}

.widget-quote__person__name {
  display: inline;
  color: #333333;
  font: 14px var(--rtd-text-fontfamily);
}

.widget-quote__person__function {
  margin-left: 7px;
  display: inline;
  color: #656565;
  font: 14px var(--rtd-text-fontfamily);
}