button.widget--image
{
  border: 0;
  padding: 0;
  background: none;

  display: block;
  width: 100%;
}

a.widget--image
{
  text-decoration: none;
}

  a.widget--image
, button.widget--image
{
  display: block;
/*  outline-offset: var(--actionbutton-outline-offset);*/
  outline-offset: 5px;
}

.widget--image__image--placeholder
{
  background-color: #E0E0E0;
}

.widget--image__title
{
  --rtd-text-color: #FFFFFF;
  margin: 0; /* cancel the margins of our <h2> */
}

.widget--image__image
{
  display: block;

  width: 100%;
  height: auto;
}

  .widget--image--noborder
, .widget--image--noborder .widget--image__image
{
  border: 0 !important;

  /*
  De "geen radius" als geen rand was denk ik voor specifieke afbeeldingen met tekst waar afronding niet mooi stond(?)
  Op verzoek uitgeschakeld: https://gitlab.webhare.com/minfin/minfin_wig/-/issues/765

  border-radius: 0 !important;
  */
}


/** Week van het geld ********************************/

.site--wvhg .widget--image
{
  position: relative;

  background: none !important;
  border: 0 !important;
  box-shadow: none !important;
}

.site--wvhg .widget--image__image
{
  border: 2px solid var(--widget-themecolor-bg);
  border-radius: var(--widget-border-radius);

  box-shadow: var(--widget-box-shadow);
}

.site--wvhg .widget--image__title
{
  margin: 0; /* cancel <h2> margin */
  position: relative;

  border-radius: var(--widget-border-radius);
  padding: 10px;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: -30px;
  text-align: center;

  box-shadow: var(--widget-box-shadow);
}

/* When image widget with green title panel is used on a green widgetsgroup */
.site--wvhg .widget--image__title.widget--image__title
{
  background: var(--widget-themecolor-bg);
  color: var(--widget-themecolor-text-color);
}



/** Geldlessen ***************************************/

.site--geldlessen .widget--image
{
  border: 2px solid var(--widget-themecolor-bg);
  border-radius: var(--widget-border-radius);
  overflow: hidden;
  overflow: clip; /* iOS 16+ */
}

.site--geldlessen .widget--image__image
{
  position: relative;
}

.site--geldlessen .widget--image__title
{
  background: var(--widget-themecolor-bg);
  color: var(--widget-themecolor-text-color);
  --rtd-text-font: 500 17px/26px var(--rtd-heading-fontfamily);

  padding: 15px 20px;
  text-align: center;
  text-wrap: pretty;
}
