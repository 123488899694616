html
{
  --widget-person-image-width: 122px;
  --widget-person-image-height: 122px;
  --widget-person-image-radius: 50%;
}

.content__widgetscolumn
{
  --widget-person-image-width: 69px;
  --widget-person-image-height: 69px;

  .personwidget__image
  {
    align-self: start;
  }
}

.widget--person > .widget__body
{
  padding: var(--widget-padding-top) var(--widget-padding-h) var(--widget-padding-bottom) var(--widget-padding-h);

  display: flex;
  align-items: center;
}

.personwidget__image
{
  display: block;
  width: var(--widget-person-image-width);
  height: var(--widget-person-image-height);
  border-radius: var(--widget-person-image-radius);

  margin-right: 20px;
}

.personwidget__name
{
  color: var(--themecolor);
  font: 500 18px var(--rtd-heading-fontfamily);
}

.personwidget__jobtitle
{
  color: #333333;
  font: 16px var(--rtd-heading-fontfamily);
}

table.personwidget__contactinfo
{
  margin-top: 20px;
  border-collapse: collapse;
  text-underline-offset: 2px;
}

.personwidget__contactinfo td
{
  padding: 0;
  vertical-align: center;
}

.personwidget__contactinfo th
{
  padding-right: 10px;
  text-align: center;
  vertical-align: center;
}

  .personwidget__contactinfo .icon
, .personwidget__contactinfo .icon
{
  position: relative;
  top: 2px;

  color: var(--themecolor);
  font-size: 16px;
}
