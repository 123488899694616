/* + / - to collapse/expand items */
.wh-toggle {
  cursor: pointer;
}

/*.coursematerial__toggler*/
.comp-toggle {
  position: absolute;
  top: 50px;
  right: 36px;
  padding: 5px; /* enlarge the clickable surface */
  cursor: pointer;
  color: var(--color-green);
}

.comp-toggle::before {
  display: inline-block;
  content: var(--fa-plus-square);
  font: var(--fontawesome-regular);
  font-size: 18px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wh-toggledelement--active .comp-toggle::before {
  content: var(--fa-minus-square);
}