.widget-button--center {
  text-align: center;
}

.widget-button--right {
  text-align: right;
}

.textbutton {
  color: var(--button-color);
  font: var(--button-font);
  text-decoration: none;
  /* NOTE: Don't use white-space: nowrap; for textual buttons !
           These sometimes have long titles so especially on mobile they'll stretch the page beyond the viewport.
  */
  display: inline-flex;
  align-items: baseline;
}

.textbutton:hover {
  color: var(--button-color-hover);
  text-decoration: underline;
}

.defaultbutton,
.ctabutton,
.wh-poll__votebutton.wh-poll__votebutton {
  border: 0 none;
  -webkit-appearance: none;
  text-decoration: none;
  box-shadow: none;
  font: var(--button-font);
  display: inline-flex;
  align-items: center;
  min-height: var(--button-height);
  padding: var(--button-padding);
  border-radius: var(--button-borderradius);
  transition: background-color var(--button-transition-duration);
  cursor: pointer;
  outline-offset: var(--actionbutton-outline-offset);
}

.defaultbutton--solid,
.ctabutton,
.wh-poll__votebutton.wh-poll__votebutton {
  background-color: var(--button-color);
  color: #FFFFFF;
  border: 0;
}
.defaultbutton--solid:hover,
.ctabutton:hover,
.wh-poll__votebutton.wh-poll__votebutton:hover {
  background: var(--button-color-hover);
  color: #FFFFFF;
  text-decoration: none;
}
.defaultbutton--solid .wh-form__button--disabled,
.ctabutton .wh-form__button--disabled,
.wh-poll__votebutton.wh-poll__votebutton .wh-form__button--disabled {
  background: var(--button_disabled_backgroundcolor);
  color: var(--button-disabled-textcolor);
  cursor: default;
}

.defaultbutton--outline {
  background: transparent;
  border: 1px solid var(--button-color);
  color: var(--button-color);
}
.defaultbutton--outline:hover {
  background: var(--button-color);
  color: #FFFFFF;
  text-decoration: none;
}
.defaultbutton--outline .wh-form__button--disabled {
  border: 1px solid var(--button-disabled-backgroundcolor);
  color: var(--button-disabled-textcolor);
  cursor: default;
}

.wh-poll__votebutton {
  justify-content: center;
}

.defaultbutton--search::after {
  font: var(--button-icon-font);
  content: var(--button-icon-search);
  margin-left: 35px;
  font-size: 18px;
  font-weight: normal;
}

.defaultbutton--backward::before {
  font: var(--button-icon-font);
  content: var(--button-icon-backward);
  margin-right: 15px;
}

.defaultbutton--forward::after {
  font: var(--button-icon-font);
  content: var(--button-icon-forward);
  vertical-align: middle;
  margin-left: 35px;
}

.textbutton--backward::before {
  font: var(--textbutton-icon-font);
  content: var(--textbutton-icon-backward);
  vertical-align: middle;
  margin-right: 15px;
}

.textbutton--forward::before {
  font: var(--textbutton-icon-font);
  content: var(--textbutton-icon-forward);
  vertical-align: middle;
  margin-right: 15px;
}

/*
  .wh-form__button--next
, .wh-form__button--submit
{
  justify-content: space-between;
}


form.wh-form--submitting .wh-form__button--submit
{
  opacity: 0.5;
  transition: none;
  pointer-events: none;
}
*/