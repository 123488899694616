.sitefooter__social {
  display: flex;
  align-items: center;
  column-gap: 15px;
  flex-wrap: wrap;
  row-gap: 15px;
}

.sitefooter__social__links {
  display: flex;
  align-items: center;
  column-gap: var(--footer-sociallinks-item-spacing);
}

.sitefooter__socialitem {
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--footer-sociallinks-item-size);
  height: var(--footer-sociallinks-item-size);
  flex: none;
  border: var(--footer-sociallinks-item-border);
  border-radius: 50%;
  cursor: pointer;
  text-decoration: none;
}

.sitefooter__socialitem .fab {
  color: var(--themecolor);
}

/*
.sitefooter__newsletter__icon .far
{
  color: #FFFFFF;
  font-size: 18px;
}
*/
.sitefooter__socialitem .fab.fa-youtube {
  font-size: 18px;
}

.sitefooter__socialitem:hover,
.sitefooter__newsletter__icon:hover {
  background-color: var(--themecolor);
}

.sitefooter__socialitem:hover .fab,
.sitefooter__newsletter__icon:hover .far {
  color: #FFFFFF;
}