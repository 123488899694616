/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
@media (max-width: 639px) {
  .wh-timetable-label {
    width: 50px;
  }
  .wh-timetable-label-title {
    display: none;
  }
  .wh-timetable-label-subtitle {
    display: none;
  }
}
@media (min-width: 640px) {
  .wh-timetable-label {
    width: 100px;
  }
  .wh-timetable-label-title-short {
    display: none;
  }
  .wh-timetable-label-subtitle-short {
    display: none;
  }
}
.wh-timetable-content {
  min-width: 4300px;
  padding-bottom: 25px;
}

.wh-timetable-header,
.wh-timetable-header-placeholder {
  height: 57px;
}

.wh-timetable-currenttimeindicator {
  position: absolute;
  top: 47px;
}

.wh-timetable-rowlabelsheading {
  height: 100%;
  display: flex;
  align-items: center;
}

@media (max-width: 580px) {
  .wh-timetable-header,
  .wh-timetable-header-placeholder {
    height: 50px;
  }
  .wh-timetable-currenttimeindicator {
    position: absolute;
    top: 40px;
  }
}
.wh-timetable-header {
  position: relative;
  overflow: hidden;
  background-color: #FFFFFF;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.41);
}

.wh-timetable-label {
  padding: 9px;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  text-shadow: 1px 1px 1px rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
}

.wh-timetable-label-title {
  color: #000000;
  font: 12px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
}

.wh-timetable-label-title-short {
  font: 12px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
}

.wh-timetable-label-subtitle {
  color: #D60064;
  font: bold 16px/18px var(--rtd-heading-fontfamily);
  white-space: pre;
}

.wh-timetable-label-subtitle-short {
  color: #D60064;
  white-space: pre;
  font: 12px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
}

.wh-timetable-label-subtitle-short:first-line {
  font: bold 16px/18px var(--rtd-heading-fontfamily);
}

.wh-timetable-row--active .wh-timetable-label-title {
  font-weight: bold;
}

.wh-timetable-timeindicator {
  padding-left: 0;
  color: #000000;
  font: 15px var(--rtd-heading-fontfamily);
}

.wh-timetable-currenttimeindicator {
  position: absolute;
  bottom: 0;
  width: 3px;
  background-color: #D60064;
  z-index: 5;
}

.wh-timetable-currenttimeindicator::before {
  position: absolute;
  left: -4px;
  top: -6px;
  z-index: 5;
  background-color: #D60064;
  display: block;
  content: "";
  width: 11px;
  height: 11px;
  border-radius: 50%;
}

.wh-timetable-prev,
.wh-timetable-next {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: rgba(56, 18, 65, 0.41);
  color: #FFFFFF;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.35s;
  /* make sure when we are at the edge the button is 'away' so
     the events at the right can be clicked */
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wh-timetable-next.enabled {
  opacity: 1;
}

@media (max-width: 639px) {
  .wh-timetable-prev,
  .wh-timetable-next {
    width: 25px;
  }
  .wh-timetable-prev::before,
  .wh-timetable-next::before {
    font-size: 20px;
  }
}
@media (min-width: 640px) {
  .wh-timetable-prev,
  .wh-timetable-next {
    width: 49px;
  }
  .wh-timetable-prev::before,
  .wh-timetable-next::before {
    font-size: 36px;
  }
}
.wh-timetable-prev {
  left: 0;
  opacity: 0;
}

.wh-timetable-next {
  right: 0;
}

.wh-timetable-prev.enabled,
.wh-timetable-next.enabled {
  pointer-events: auto;
}

.wh-timetable-prev.enabled:hover,
.wh-timetable-next.enabled:hover {
  opacity: 1;
}

.wh-timetable-prev::before,
.wh-timetable-next::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  color: #D60064;
}

.wh-timetable-prev::before {
  content: "\f053";
}

.wh-timetable-next::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f054";
}

*:not(.wh-timetable-row) + .wh-timetable-row {
  margin-top: 25px;
}

.wh-timetable-row {
  margin-bottom: 8px;
}

.wh-timetable-time {
  display: flex;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  background-color: var(--color-purple);
  color: #FFFFFF;
  border-radius: 5px;
  font: 500 16px var(--rtd-heading-fontfamily);
}

.wh-timetable-time--filter-notamatch {
  background-color: #DADADA;
  color: #333333;
}
.wh-timetable-time--filter-notamatch .wh-timetable-time__replayicon {
  background-color: #BBBBBB;
  color: #777777;
}

.wh-timetable-row--active .wh-timetable-time {
  background-color: var(--color-green);
}

.wh-timetable-row--active .wh-timetable-time--filter-notamatch {
  background-color: #DADADA;
  color: #333333;
}

/* Icon to indicate there is a label which cannot be shown (due it cannot fit) */
.wh-timetable-time .icon {
  display: none;
  font-size: 20px;
  width: 100%;
  text-align: center;
}

.wh-timetable-time {
  text-overflow: clip;
}

.wh-timetable-time.label_hide {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.wh-timetable-time.label_hide .icon {
  display: inline-block;
}

.wh-timetable-time.label_hide > span {
  display: none;
}

.wh-timetable-time__replayicon {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: #D60064;
  font: bold 13px/19px var(--rtd-heading-fontfamily);
  text-align: center;
}

.wh-timetable-time__replayicon::before {
  display: block;
  content: "H";
}