.wh-autocomplete-values {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: #FFFFFF;
  color: #222222;
  border: 1px solid #808080;
  border-radius: 2px;
  list-style-type: none;
  margin: 0;
  padding: 5px 0;
}

.wh-autocomplete-values > li {
  font: 15px/20px Arial, Sans-Serif;
  color: var(--color-grey-text-small);
  cursor: pointer;
  padding: 5px 12px;
}

.wh-autocomplete-values > li > .match {
  font-weight: bold;
}

.wh-autocomplete-values > li:hover,
.wh-autocomplete-values > li:focus {
  background-color: #F4F4F4;
  color: #333333;
}

/*
.ui-helper-hidden-accessible
{
  display: none;
}
*/