/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
.searchpage__header {
  margin-bottom: 3px;
}

.searchpage__searchcontrol {
  margin-bottom: 45px;
}

.searchresults {
  margin-top: 10px;
}

a.searchresult {
  display: block;
  text-decoration: none;
  color: inherit;
}

.searchresult h2 {
  margin: 0 0 4px 0;
}

.page--search .searchcontrol__submit {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.searchpage__header {
  color: var(--color-purple);
  font-style: italic;
}

.searchresults__stats {
  margin-top: 30px;
  display: flex;
}

.searchresults__range {
  margin-left: auto;
}

.searchresult__title {
  text-decoration: none;
  margin-bottom: 3px;
}

.searchresult__url {
  margin-top: 7px;
  color: var(--color-green);
  word-break: break-word;
}

.consilio--highlight {
  font-weight: bold;
}