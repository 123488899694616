@import "../../../shared/web/assets/fontawesome5/mixins.scss";





.widget--default .widget__body
{
  padding: var(--widget-padding-top) var(--widget-padding-h) var(--widget-padding-bottom) var(--widget-padding-h);
}



.defaultwidget__image
{
  width: 100%;
}

.widget--default .widget__body .ctabutton
{
  margin-top: 18px;
}



/** Widget 'Default' ******************************************************************/

.widget--default
{
  overflow: hidden; /* make image be rounded with the borders */
}

.widget--default .widget__title + .widget__description
{
  margin-top: 10px;
}

.defaultwidget__imagecontainer
{
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}
.defaultwidget__imagecontainer img
{
  width: 100%;
  height: auto;
  display: block;
}


.widget--default .ctalink::before
{
  /*
  @include fontawesome-solid;
  content: $fa-chevron-right;
  font-size: 12px;
  */

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;

/*  content: "\f054";*/
  content: var(--fa-chevron-right);

  font-size: 14px;

  margin-right: 14px;
}



html.site--geldlessen .widget--default .ctalink
{
  align-items: baseline;
  margin-top: 15px;
  font: 600 16px/20px var(--rtd-heading-fontfamily);
}

html.site--geldlessen .widget--default .ctabutton
{
  margin-top: 15px;
  font: 600 16px/20px var(--rtd-heading-fontfamily);
}


html.site--geldlessen .widget--default .ctalink::before
{
/*  @include fontawesome-regular;*/
  font: var(--fontawesome-regular);
  content: var(--fa-chevron-circle-right);
  /*font-weight: normal;*/
  font-size: 24px;
  margin-right: 10px;

  /* tweak our baseline alignment */
  position: relative;
  top: 4px;
}



html:not(.site--geldlessen) .defaultwidget__imagearrow
{
  position: absolute;
  bottom: 0;
  width: 100%;

  padding-bottom: 22px;
  margin-bottom: -22px;

  background-color: #FFFFFF;
}
  html:not(.site--geldlessen) .defaultwidget__imagearrow:before
, html:not(.site--geldlessen) .defaultwidget__imagearrow:after
{
  content: '';
  position: absolute;
  bottom: 100%;
  width: 50%;
  padding-bottom: inherit;
  background-color: inherit;
}
html:not(.site--geldlessen) .defaultwidget__imagearrow:before {
  right: 50%;
  transform-origin: 100% 100%;
  transform: skewX(60deg);
}
html:not(.site--geldlessen) .defaultwidget__imagearrow:after {
  left: 50%;
  transform-origin: 0 100%;
  transform: skewX(-60deg);
}


.widget--default img
{
  display: block;
  width: 100%;
  height: auto;
  vertical-align: bottom; /* to prevent whitespace below */
}

.defaultwidget__imagecontainer + .widget__body.widget--padding
{
  padding-top: 10px; /* according to PDF, not /v3/ preview */
}



/** Make default widgets align ******************************/
  .widget--default
, .widget--default .widget__body
, .widget--default .widget__body .widget__description
{
  display: flex;
  flex-direction: column;
}

.widget--default .widget__body
{
  flex: auto; /* of flex: 1 0 0px ? */
}

.widget--default .widget__body .widget__description
{
  flex: auto;
}
.widget--default .widget__body .widget__description .ctabutton
{
  margin-top: auto;
  align-self: flex-start;
}
