/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
.pageheader__carrousel {
  color: #FFFFFF;
  background: #641c76;
  grid-column: 1;
  grid-row: 1;
}

.pageheader__carrousel__content {
  width: 100%;
  max-width: var(--pagegrid-contentarea-width-full);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  display: grid;
  align-items: end; /* FIXME: why ? */
}

/*
For testing: (show all banners below each other)
.pageheader__carrousel__content
{
  display: block;
}
.pageheader__carrousel__item + .pageheader__carrousel__item
{
  border-top: 2px solid #FFFFFF;
}
*/
.pageheader__carrousel + .pageheader__content {
  z-index: 1;
}

.pageheader__carrousel + .pageheader__content .pageheader__quicklinks {
  border: 2px solid rgba(255, 255, 255, 0.25);
  border-radius: var(--quicklinks-border-radius) var(--quicklinks-border-radius) 0 0;
}

.pageheader__carrousel__item {
  min-height: var(--pageheader-image-height);
  height: 100%;
  grid-column: 1;
  grid-row: 1;
  background: #641c76;
  position: relative;
  clear: both;
  overflow: hidden;
  display: grid;
}

a.pageheader__carrousel__item {
  display: block;
  color: inherit;
  text-decoration: none;
}

.carrousel-initialized .pageheader__carrousel__item {
  opacity: 0;
  transition: opacity 0.2s;
}

.carrousel-initialized .pageheader__carrousel__item.activeslide {
  opacity: 1;
  pointer-events: auto;
  z-index: 1;
}

.pageheader__carrousel__item__content {
  align-self: center;
}

.pageheader__carrousel__item + .pageheader__carrousel__item {
  margin-top: 2px;
}

.carrouselitem-bdb__date {
  letter-spacing: 0.5px;
  font: bold 28px var(--rtd-heading-fontfamily);
  font-size: clamp(15px, 3vw, 24px);
  line-height: 125%;
}

.carrouselitem-bdb__line1 {
  font: 33px/40px var(--rtd-heading-fontfamily);
  font-size: clamp(20px, 3vw, 30px);
  line-height: 125%;
}

.carrouselitem-bdb__line2 {
  letter-spacing: 0.5px;
  font: bold 28px/40px var(--rtd-heading-fontfamily);
  font-size: clamp(20px, 2.5vw, 23px);
  line-height: 125%;
}

.carrouselitem-bdb__tile {
  width: clamp(215px, 37vw, 420px);
  margin-top: -6%;
  margin-bottom: -6%;
  transform: translateX(0%);
}

.carrouselitem-bdb__vlek {
  width: clamp(110px, 20vw, 190px);
}

/* Banner layout 1 */
.bdb-layout-1 .carrouselitem-bdb__tile {
  float: right;
  transform: rotate(-8deg);
}
.bdb-layout-1 .carrouselitem-bdb__vlek {
  float: right;
  margin-right: -5%;
  transform: translateY(-33%);
}
.bdb-layout-1 .carrouselitem-bdb__date {
  margin-top: 3%;
}
.bdb-layout-1 .carrouselitem-bdb__text {
  margin-top: 3%;
  margin-bottom: calc(5.5% + var(--pageheader-content-overlap-height));
}

/* Banner layout 2 */
.bdb-layout-2 .carrouselitem-bdb__vlek {
  float: left;
  margin-top: 6%;
  margin-right: 3%;
}
.bdb-layout-2 .carrouselitem-bdb__tile {
  float: right;
}
.bdb-layout-2 .carrouselitem-bdb__text {
  margin-top: 3%;
}
.bdb-layout-2 .carrouselitem-bdb__date {
  margin-top: 3%;
  margin-bottom: calc(5.5% + var(--pageheader-content-overlap-height));
}

/* Banner layout 3 */
.bdb-layout-3 .carrouselitem-bdb__tile {
  float: left;
  transform: rotate(-8deg);
}
.bdb-layout-3 .carrouselitem-bdb__vlek {
  float: left;
  margin-top: -15%;
  margin-left: -27%;
  position: relative;
  margin-right: 2%;
}
.bdb-layout-3 .carrouselitem-bdb__date {
  margin-top: 6%;
}
.bdb-layout-3 .wrapper-to-clear-left {
  position: relative;
  /* nieuwe context voor de float.
     (inline-block zou als bijeffect hebben dat bij wrappen van tekst
     deze container ook gelijk zou wrappen als onderdeel van het inline zijn) */
  display: table;
  display: flow-root;
}
.bdb-layout-3 .carrouselitem-bdb__text {
  clear: left;
  margin-top: 1%;
  padding-left: 8%;
  margin-bottom: calc(5.5% + var(--pageheader-content-overlap-height));
}

@media (max-width: 600px) {
  .pageheader__carrousel__content {
    padding-left: 0;
    padding-right: 0;
  }
  /* Banner layout 1 */
  .bdb-layout-1 {
    padding-left: var(--sidepadding);
  }
  .bdb-layout-1 .carrouselitem-bdb__tile {
    margin-right: -17%;
  }
  .bdb-layout-1 .carrouselitem-bdb__vlek {
    display: none;
  }
  /* Banner layout 2 */
  .bdb-layout-2 {
    padding-left: var(--sidepadding);
  }
  .bdb-layout-2 .carrouselitem-bdb__tile {
    margin-right: -17%;
  }
  .bdb-layout-2 .carrouselitem-bdb__vlek {
    display: none;
  }
  /* Banner layout 3 */
  .bdb-layout-3 {
    padding-right: var(--sidepadding);
  }
  .bdb-layout-3 .carrouselitem-bdb__tile {
    margin-left: -17%;
  }
  .bdb-layout-3 .carrouselitem-bdb__vlek {
    display: none;
  }
  .bdb-layout-3 .carrouselitem-bdb__text {
    clear: none;
  }
  .bdb-layout-3 .carrouselitem-bdb__date, .bdb-layout-3 .carrouselitem-bdb__text {
    padding-left: 5%;
  }
}