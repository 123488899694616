/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
.timetable-event {
  position: relative;
  position: fixed;
  right: 0;
  height: 100%;
  transform: translateX(calc(100% + 12px));
  transition: transform 0.2s;
  visibility: hidden;
  width: 85%;
  max-width: 467px;
  background-color: #FFFFFF;
  box-shadow: 0 -4px 12px 0 rgba(0, 0, 0, 0.5);
  padding: 20px 25px;
  color: #333333;
}

.timetable-event:focus {
  outline: 0;
}

.timetable-event.timetable-event--show {
  transform: translateX(0);
  visibility: visible;
}

html.debug .timetable-event {
  margin-left: auto;
  margin-top: 15px;
}

.timetable-event__tags {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-bottom: -4px;
}

.timetable-event__tags:empty {
  display: none;
}

.timetable-event__tag {
  margin-left: 10px;
  margin-bottom: 4px;
  background-color: #EBEBEB;
  border-radius: 16px;
  color: var(--color-green);
  padding: 4px 15px;
  font: 14px Arial;
}

.timetable-event__tag--replay {
  background-color: #D60064;
  color: #FFFFFF;
}

.timetable-event__close {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  position: absolute;
  top: 20px;
  right: 25px;
  cursor: pointer;
}

.timetable-event__close::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f057";
  color: var(--color-purple);
  font-size: 24px;
}

.timetable-event__image {
  margin-top: 14px;
  display: block;
  width: 100%;
  height: auto;
}

.timetable-event__when {
  margin-top: 20px;
  display: flex;
  align-items: center;
  font: 16px/26px var(--rtd-heading-fontfamily);
}

.timetable-event__when::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f017";
  color: var(--color-green);
  font-size: 16px;
  margin-right: 11px;
}

.timetable-event__title {
  margin-top: 5px;
  color: var(--color-purple);
  font: bold 30px/32px var(--rtd-heading-fontfamily);
}

.timetable-event__description {
  margin-top: 12px;
}

.timetable-event__description.rtdcontent > * {
  font: 16px/26px Arial;
}

.timetable-event__viewbutton {
  margin-top: 14px;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  text-align: left;
}

.timetable-event__viewbutton__available {
  text-decoration: none;
  color: #FFFFFF;
}

.timetable-event__viewbutton__available,
.timetable-event__viewbutton__countdown {
  align-items: center;
  height: 100%;
  background-color: var(--color-green);
  border-radius: 6px;
  color: #FFFFFF;
  height: 55px;
  font: 500 22px/20px var(--rtd-heading-fontfamily);
}

.timetable-event__viewbutton__available::before,
.timetable-event__viewbutton__countdown::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Brands";
  content: "\f167";
  font-size: 38px;
  margin-right: 16px;
}

.timetable-event__viewbutton__available {
  display: none;
  align-items: center;
  padding-left: 16px;
  padding-right: 34px;
}

.timetable-event__viewbutton__countdown {
  padding-left: 16px;
  padding-right: 15px;
}

.timetable-event--active .timetable-event__viewbutton__countdown {
  display: none;
}
.timetable-event--active .timetable-event__viewbutton__available {
  display: inline-flex;
}

.timetable-event--past .timetable-event__viewbutton {
  display: none;
}

.timetable-event__viewbutton__soon {
  display: none;
  margin-top: 10px;
  color: var(--color-green);
}

.timetable-event--soon .timetable-event__viewbutton__available {
  display: inline-flex;
}
.timetable-event--soon .timetable-event__viewbutton__soon {
  display: block;
}
.timetable-event--soon .timetable-event__viewbutton__countdown {
  background-color: transparent;
  color: #666666;
  padding-left: 0;
  padding-right: 0;
}
.timetable-event--soon .timetable-event__viewbutton__countdown::before {
  display: none;
}

.countdown {
  display: inline-flex;
}

.countdown > * {
  width: 50px;
}

.countdown__count {
  font: 20px var(--rtd-heading-fontfamily);
}

.countdown__unit {
  font: 11px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
}

@media (max-width: 375px) {
  .timetable-event__viewbutton {
    padding-left: 15px;
    padding-right: 10px;
  }
  .timetable-event__viewbutton::before {
    margin-right: 15px;
  }
  .countdown__seconds {
    display: none;
  }
}
.timetable-event hr {
  margin-top: 30px;
  border-top: 1px solid #DADADA;
  margin-bottom: 15px;
}

.timetable-event__aanbieders {
  padding-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
}

.timetable-event__aanbieders__heading {
  padding-bottom: 15px;
}

.timetable-event__aanbieders__heading,
.timetable-event__aanbieders__items {
  flex: 0 0 50%;
}

.timetable-event__aanbieders__items {
  display: flex;
  flex-direction: column;
}

.timetable-event__aanbieders__items img {
  align-self: start;
  display: block;
  max-width: 190px;
  max-height: 80px;
}

.timetable-event__aanbieders__items * + * {
  margin-top: 15px;
}

.timetable-event__aanbieders__heading,
.timetable-event__share__heading {
  color: var(--color-green);
  font: 16px var(--rtd-heading-fontfamily);
}

.timetable-event__sharebar {
  padding-top: 15px;
}

.timetable-event__share__heading {
  color: var(--color-green);
  font: bold 20px/26px var(--rtd-heading-fontfamily);
  margin-bottom: 14px;
}

.timetable-event__share__buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #EBEBEB;
  border-radius: 8px;
  padding-left: 7px;
  padding-right: 10px;
  position: relative;
}

.timetable-event__share__buttons .wh-share {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  width: 38px;
  height: 38px;
  color: var(--color-green);
  border-radius: 8px;
}

.timetable-event__share__buttons > .wh-share + .wh-share {
  margin-left: 5px;
}

.timetable-event__share__buttons .wh-share::before {
  font-size: 20px;
}

.timetable-event__share__buttons .wh-share[data-network=twitter]::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Brands";
  content: "\f099";
}

.timetable-event__share__buttons .wh-share[data-network=facebook]::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Brands";
  content: "\f39e";
}

.timetable-event__share__buttons .wh-share[data-network=linkedin]::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Brands";
  content: "\f0e1";
}

.timetable-event__share__buttons .wh-share.siteaction--emailpagelink::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f0e0";
}

.timetable-event__share__buttons .wh-share[data-network=print]::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f02f";
}

.timetable-event__share__buttons .wh-share[data-network=print] {
  margin-right: auto;
}

.timetable-event__share__buttons .wh-share:hover {
  background-color: var(--color-green);
  color: #FFFFFF;
}

.timetable-event__share__buttons .wh-share[data-network=linkedin] {
  margin-right: auto;
}

.timetable-event__sharebar__addtocalendar {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  display: block;
  height: 38px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  font: 16px var(--rtd-heading-fontfamily);
  text-decoration: none;
  border-radius: 8px;
  cursor: pointer;
  color: var(--color-green);
}

.timetable-event__sharebar__addtocalendar .far {
  margin-left: 12px;
  font-size: 19px;
}

.timetable-event__sharebar__addtocalendar:hover {
  background-color: var(--color-green);
  color: #FFFFFF;
}

.add-to-calendar-dropdown {
  display: none;
}

.showsharebar .add-to-calendar-dropdown {
  display: block;
}

.add-to-calendar-dropdown a {
  white-space: nowrap;
}