/*
content: var(--fa-arrow-circle-down);
content: var(--fa-angle-right);
content: var(--fa-arrow-right)

.filteredoverview__teaser
{
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}
*/
dialog.filteredoverview__teaser {
  padding: 0;
  background: none;
  border: 0;
  box-shadow: none;
}

/*
.filteredoverview__teaser
{
  display: none;

  position: fixed; // fallback value for browsers which don't understand sticky
  margin-top: auto; // push to bottom of the viewport, even if there is not enough content to fill the screen
  z-index: 99999;

  bottom: 0;

  width: max-content;

  opacity: 0;
  transition: opacity 0.5s;

  cursor: pointer;
  pointer-events: none;
}
*/
/*
.filteredoverview__teaser.trait-fullwidth
{
  width: 100%;
  padding-top:      5px;
  padding-bottom:   5px;
  box-shadow:       3px 2px 4px 6px rgba(0,0,0,0.07);
}
*/
.site-dialog .filteredoverview__teaser {
  margin: var(--pagegrid-gutteroutside-mobile);
  width: stretch;
  width: -webkit-fill-available;
}

/*
Note the __applybutton is when used inside a <dialog> (often done for mobile filters popup.)
*/
.filtersdialog__applybutton {
  padding: 0 20px;
  border: 0;
  position: relative;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  min-height: var(--filteredoverview-teaserbar-height);
  display: flex;
  align-items: center;
  border: var(--filteredoverview-teaserbar-border);
  border-radius: var(--filteredoverview-teaserbar-borderradius);
  background: var(--filteredoverview-teaserbar-background);
  cursor: pointer;
}

html.filteredoverview--showfilters .filteredoverview__teaser {
  display: block;
  opacity: 1;
  pointer-events: auto;
}

/*
.filteredoverview__teaser--aboveresults
{
  opacity: 1;
  pointer-events: auto;
}
*/
.filtersdialog__applybutton {
  font: var(--filteredoverview-teaserbar-font);
  color: var(--filteredoverview-teaserbar-color);
  transition: background-color 0.25s;
  /*
    &:focus
    {
      box-shadow: 0 0 0 2px #000;
      outline: 0;

      // Let Webkit use it's default focus indicator
      @supports (outline: -webkit-focus-ring-color auto 5px)
      {
        box-shadow: none;
        outline: -webkit-focus-ring-color auto 5px;
      }
    }
    &:active:focus
    {
      box-shadow: none;
      outline: none;
    }
  */
}
.filtersdialog__applybutton:hover {
  background-color: var(--color-purple);
  color: #FFFFFF;
}

/* ACOI
.filteredoverview__teaser__button::after
{
  content: ">";
  margin-left: 15px;

  font: var(--fontawesome-regular);
  // content: var(--fa-angle-right);
  // content: var(--fa-arrow-right);
  content: var(--fa-long-arrow-right);
  font-size: 20px;

  margin-top: 2px;
}
*/
/*
@media (min-width: 376px)
{
    .filteredoverview__teaser__button
  , .filtersdialog__applybutton
  {
    padding-left:  25px;
    padding-right: 25px;
  }
}

@media (max-width: 375px)
{
  .filteredoverview__teaser__button
  , .filtersdialog__applybutton
  {
    padding-left:  18px;
    padding-right: 18px;
  }
}
*/