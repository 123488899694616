/* was 880px, maar met pulldown toggles hebben we meer ruimte nodig en toen een tussenoplossing voor het menu bedacht waarmee juist 768px (iPad) ook past */
@font-face {
  font-weight: bold;
  font-family: "VAGRoundedStd";
  /*  src: url("../../web/fonts/VAGRoundedStd-Bold.ttf"); - FIXME: SASS crashes with .otf so we renamed to .ttf OMG */
  src: url("../web/fonts/vagroundedstd-bold-webfont.woff") format("woff");
  /*
  font-family: 'vag_rounded_std_thinbold';
  src: url(/profiles/weekvanhetgeld/themes/wvhg/fonts/vagroundedstd-bold-webfont.eot);
  src: url(/profiles/weekvanhetgeld/themes/wvhg/fonts/vagroundedstd-bold-webfont.eot?#iefix) format('embedded-opentype'), url(/profiles/weekvanhetgeld/themes/wvhg/fonts/vagroundedstd-bold-webfont.woff) format('woff'), url(/profiles/weekvanhetgeld/themes/wvhg/fonts/vagroundedstd-bold-webfont.ttf) format('truetype'), url(/profiles/weekvanhetgeld/themes/wvhg/fonts/vagroundedstd-bold-webfont.svg#vag_rounded_std_thinbold) format('svg');
  font-weight: normal;
  font-style: normal;
  */
}
.pageheader--wvhg-thema2025 {
  --pageheader-content-overlap-height: 80px !important;
  color: #FFFFFF;
  margin-bottom: 30px;
  overflow: hidden;
  /* Row 1 - Our thematic banner
     Row 2 - Textpanel and quicklinks panel start (the part overlapping the purple background)
     Row 3 - Textpanel and quicklinks panel part below the purple background
  */
  display: grid;
  --content-whitespace-around: calc(100vw - 1000px - var(--sidepadding) * 2);
  --contentarea-width: calc(100vw - var(--content-whitespace-around));
  --width-sides: calc( var(--content-whitespace-around) / 2);
  grid-template-columns: var(--width-sides) var(--sidepadding) minmax(0, 1fr) var(--sidepadding) var(--width-sides);
}
@media (max-width: 767px) {
  .pageheader--wvhg-thema2025 {
    --pageheader-content-overlap-height: 35px !important;
  }
}
.pageheader--wvhg-thema2025::before {
  content: "";
  grid-column: 1/-1;
  grid-row: 1/span 2;
  /*
  We use !important on the background to override the mobile background color used by the default header:

  @media (max-width: 767px) {
  .defaultpageheader.pageheader--textinframe::before {
  */
  background: var(--color-purple) !important;
}
.pageheader--wvhg-thema2025.pageheader--textinframe .pageheader__custombar {
  grid-row: 1;
}
.pageheader--wvhg-thema2025 .pageheader__custombar {
  grid-row: 1/span 2;
  grid-column: 3;
  display: grid;
  grid-template-rows: min-content;
  grid-template-columns: 100%;
}
.pageheader--wvhg-thema2025 .pageheader__custombar > * {
  grid-column: 1;
  grid-row: 1;
}
.pageheader--wvhg-thema2025 .pageheader__content.pageheader__content {
  margin-top: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
  max-width: none;
  grid-column: 3 !important;
}
.pageheader--wvhg-thema2025 .pageheader__custombar__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  column-gap: min(10vw, 32px);
}
.pageheader--wvhg-thema2025 .pageheader__slide--po.pageheader__mascotte {
  align-self: end;
  width: max(259.605px, 53%);
  transform: translate(-18%, 7.5%);
  margin-left: -3%;
  margin-top: -6%;
  z-index: 1;
}
.pageheader--wvhg-thema2025 .pageheader__slide--vo {
  align-self: end;
  position: relative;
}
.pageheader--wvhg-thema2025 .pageheader__slide--vo .pageheader__mascotte {
  align-self: end;
  width: max(148.07px, 32%);
  transform: translate(0%, 11%);
  margin-left: 0%;
  z-index: 1;
}
.pageheader--wvhg-thema2025 .pageheader__slide--vo .pageheader__slide__cloud {
  position: absolute;
  top: 0;
  width: clamp(274.54px, 55%, 617.715px);
  transform: translate(-23%, -46%);
}
.pageheader--wvhg-thema2025 .pageheader__slide--po {
  animation: 15s linear 0s infinite normal pageheader-fade1;
}
.pageheader--wvhg-thema2025 .pageheader__slide--vo {
  animation: 15s linear 0s infinite normal pageheader-fade2;
}
@media (max-width: 767px) {
  .pageheader--wvhg-thema2025 {
    --pageheader-contentframe-padding: 16px 20px 12px 20px;
  }
  .pageheader--wvhg-thema2025 .pageheader__content {
    grid-row: image-overlap/span 2 !important;
    display: block;
  }
  .pageheader--wvhg-thema2025 .pageheader__text, .pageheader--wvhg-thema2025 .pageheader__quicklinks {
    margin-left: var(--sidepadding);
    margin-right: var(--sidepadding);
  }
  .pageheader--wvhg-thema2025 .pageheader__text + .pageheader__quicklinks {
    margin-top: 20px;
  }
  .pageheader--wvhg-thema2025 .pageheader__mascotte {
    z-index: 20;
  }
  .pageheader--wvhg-thema2025.pageheader--textinframe .pageheader__text + #readspeaker_button {
    margin-top: 30px;
  }
  .pageheader--wvhg-thema2025:not(.pageheader--textinframe) .pageheader__content.pageheader__content {
    grid-row: 3;
    margin-top: 40px;
  }
  .pageheader--wvhg-thema2025 .pageheader__quicklinks .pageheader__wvhg-when {
    display: none;
  }
  .pageheader--wvhg-thema2025 .pageheader__custombar__textual {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .pageheader--wvhg-thema2025 .pageheader__custombar .pageheader__wvhg-when {
    margin-top: 30px;
    display: inline-block;
    height: 28px;
    border-radius: 14px;
    padding: 1px 14px 0 14px;
    background-color: var(--color-theme-background);
    font: bold 14px VAGRoundedStd;
    font-size: clamp(14px, 2vw, 25px);
    line-height: 28px;
    letter-spacing: 1px;
    white-space: nowrap;
  }
}
@media (min-width: 768px) {
  .pageheader--wvhg-thema2025 {
    --pageheader-contentframe-padding: 24px 20px 20px 20px;
    /* Ensure contast of the purple header to the purple background of the page header */
  }
  .pageheader--wvhg-thema2025 .pageheader__custombar .pageheader__wvhg-when {
    display: none;
  }
  .pageheader--wvhg-thema2025 .pageheader__quicklinks .pageheader__wvhg-when {
    background-color: var(--color-theme-background);
    font: bold 22.5px VAGRoundedStd;
    letter-spacing: 1px;
    padding: 11px 20px 9px 20px;
    border-radius: 25px;
    white-space: nowrap;
    margin-bottom: 20px;
  }
  .pageheader--wvhg-thema2025 .quicklinks {
    box-shadow: 0 0 9px rgba(255, 255, 255, 0.35);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 0;
    border-radius: 5px;
  }
}
.pageheader--wvhg-thema2025 .pageheader__custombar__textual {
  margin-left: auto;
  align-self: center;
}
.pageheader--wvhg-thema2025 .pageheader__slogan {
  white-space: nowrap;
  text-transform: uppercase;
  text-align: center;
  --available-space: min(100vw, 1000px);
  font: bold 55px VAGRoundedStd;
  font-size: max(23px, var(--available-space) * 0.04);
  letter-spacing: 1px;
  position: relative;
  z-index: 1;
}
.pageheader--wvhg-thema2025 .pageheader__slogan span.pageheader__slogan__normal {
  -webkit-text-stroke: 9px var(--color-purple);
  -webkit-text-stroke: 9px rgba(100, 28, 118, 0.6);
  paint-order: stroke fill;
}
.pageheader--wvhg-thema2025 .pageheader__slogan__highlight {
  display: inline-flex;
  align-items: center;
  background: #FFFFFF;
  color: var(--color-purple);
  box-shadow: 0 0 1px 5px rgba(100, 28, 118, 0.5333333333);
  --height: max(32px, calc(var(--available-space) * 0.07));
  padding: calc(var(--height) * 0.13) calc(var(--height) * 0.3) 0 calc(var(--height) * 0.3);
  height: var(--height);
  border-radius: 35px;
  --available-space: min(100vw, 1000px);
  font-size: max(34px, var(--available-space) * 0.062);
  line-height: 100%;
}
@media (min-width: 450px) {
  .pageheader--wvhg-thema2025 .pageheader__custombar__textual {
    margin-right: 5%;
  }
}
@media (max-width: 400px) {
  .pageheader--wvhg-thema2025 .pageheader__slide--po, .pageheader--wvhg-thema2025 .pageheader__slide--vo {
    margin-top: -13%;
  }
  .pageheader--wvhg-thema2025 .pageheader__slide--po.pageheader__mascotte {
    transform: translate(-22%, 7.5%);
  }
  .pageheader--wvhg-thema2025 .pageheader__slide--vo .pageheader__mascotte {
    transform: translate(-1%, 11%);
  }
}
@media (max-width: 375px) {
  .pageheader--wvhg-thema2025 .pageheader__slogan {
    font-size: max(20px, var(--available-space) * 0.056);
  }
  .pageheader--wvhg-thema2025 .pageheader__slogan__highlight.pageheader__slogan__highlight {
    font-size: max(29px, var(--available-space) * 0.085);
  }
}

@keyframes pageheader-fade1 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  30% {
    opacity: 1;
    z-index: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes pageheader-fade2 {
  0% {
    opacity: 1;
    z-index: 1;
  }
  25% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}