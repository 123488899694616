/* ThemeColor - SVG fill */
.tc-svgfill-green   svg { fill: var(--color-green); }
.tc-svgfill-magenta svg { fill: var(--color-magenta) }
.tc-svgfill-purple  svg { fill: #671e77; }
.tc-svgfill-yellow  svg { fill: #fec62e; }


/* 56 x 80 in example widget */
.widget--fancycta
{
  display: flex;
  position: relative;

  outline-offset: var(--actionbutton-outline-offset);
}

.widget--fancycta--outline
{
  border-width: 2px;
  border-style: solid;
}

.widget--fancycta--filled
{
  --rtd-text-color: #FFFFFF;
}

.widget--fancycta--outline
{
  --rtd-text-color: initial;
}

.widget--fancycta__body
{
  flex: 1;
  padding: var(--widget-padding-top) var(--widget-padding-h) var(--widget-padding-bottom) var(--widget-padding-h);

  align-self: center; /* vertical-align */
}



.fancycta__symbol--hleft .widget--fancycta__body
{
  order: 1;
}


.fancycta__symbol--vtop .widget--fancycta__symbolcontainer
{
  align-self: flex-end;
  padding-top: 0;
}
.fancycta__symbol--vmiddle .widget--fancycta__symbolcontainer
{
  align-self: center;
}
.fancycta__symbol--vbottom .widget--fancycta__symbolcontainer
{
  align-self: flex-end;
  padding-bottom: 0;
}


.fancycta__symbol--vtop .widget--fancycta__symbolcontainer
{
  align-self: flex-start;
}

.fancycta__symbol--vmiddle .widget--fancycta__symbolcontainer
{
  align-self: center;
}




.fancycta__symbol--overtext .widget--fancycta__symbolcontainer
{
  width: 0;
  display: flex;

  padding: 0 !important;
}

.fancycta__symbol--overtext.fancycta__symbol--hleft .widget--fancycta__symbolcontainer
{
  position: relative;
  left: 15px;

  justify-content: flex-start;
}
.fancycta__symbol--overtext.fancycta__symbol--hright .widget--fancycta__symbolcontainer
{
  position: relative;
  left: -15px;
}




  .imagescaling--fitcontentheight img
, .imagescaling--fitcontentheight svg
, .imagescaling--fitwidgetheight img
, .imagescaling--fitwidgetheight svg
{
  width: auto;
  max-width: 130px;
  height: 100%;
}

  .fancycta__symbol--vtop .imagescaling--verticaloverscan img
, .fancycta__symbol--vtop .imagescaling--verticaloverscan svg
{
  width: auto;

  /*
  1. IE needs a max-width or it'll go crazy and reserve more space than it needs
     (make a svg that sticks outside the widget and the image is centered within the SVG)
  2. IE % is based on flex container and Chrome % on the flex child it's in, so to work in all browser we must use an absolute size (px)
  */
  max-width: 130px;

  height: calc(100% + 20px);
  margin-top: 0;
  margin-bottom: -20px;
}

  .fancycta__symbol--vmiddle .imagescaling--verticaloverscan img
, .fancycta__symbol--vmiddle .imagescaling--verticaloverscan svg
{
  width: auto;
  max-width: 130px;
  height: calc(100% + 40px);
  margin-top: -20px;
  margin-bottom: -20px;
  padding-right: 20px;
}

  .fancycta__symbol--vbottom .imagescaling--verticaloverscan img
, .fancycta__symbol--vbottom .imagescaling--verticaloverscan svg
{
  width: auto;
  max-width: 130px;
  height: calc(100% + 20px);
  margin-top: -20px;
  margin-bottom: 0;
}

  .imagescaling--height_small img
, .imagescaling--height_small svg
{
  width: auto;
  max-width: 130px;
  height: 40px;
}

  .imagescaling--height_medium img
, .imagescaling--height_medium svg
{
  width: auto;
  max-width: 130px;
  height: 60px;
}





.fancycta__symbol--vtop .widget--fancycta__symbolcontainer
{
  padding-bottom: 20px;
}
  .fancycta__symbol--vmiddle .widget--fancycta__symbolcontainer
, .imagescaling--natural
{
  padding-top: 20px;
  padding-bottom: 20px;
}
.fancycta__symbol--vbottom .widget--fancycta__symbolcontainer
{
  padding-top: 20px;
}
  .widget--fancycta__symbolcontainer.imagescaling--fitwidgetheight
, .widget--fancycta__symbolcontainer.imagescaling--verticaloverscan
{
  padding-top: 0px;
  padding-bottom: 0px;
}



/* overscan without auto-stretching */
.widget--fancycta__symbolcontainer.imagescaling--verticaloverscan-withoutstretch
{
  padding-top: 0px;
  padding-bottom: 0px;
}
  .imagescaling--verticaloverscan-withoutstretch img
, .imagescaling--verticaloverscan-withoutstretch svg
{
  margin-top: -10px;
  margin-bottom: -10px;
}





  .widget--fancycta__symbolcontainer.imagescaling--fitcontentheight
, .widget--fancycta__symbolcontainer.imagescaling--fitwidgetheight
, .widget--fancycta__symbolcontainer.imagescaling--verticaloverscan
{
  align-self: stretch; /* so the img/svg can */
}



.imagescaling--natural
{
  display: flex;
}




.widget--fancycta__symbolcontainer
{
  position: relative;
  padding-right: 20px;
}
  .widget--fancycta__symbolcontainer img
, .widget--fancycta__symbolcontainer svg
{
  display: block;
}

.widget--fancycta--filled .widget--fancycta__symbolcontainer svg
{
  fill: #FFFFFF;
}




.widget--fancycta p.normal
{
  font: inherit;
  font-weight: 300;
}

.widget--fancycta b
{
  font-weight: 500;
}

.widget--fancycta__icon
{
  float: right;
  width: auto;
}
.widget--fancycta__icon.fa
{
  font-size: 65px;
}
