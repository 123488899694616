html {
  --widgetdebug-border-color: #BBBBBB;
}

.widget-debugwidget {
  border-radius: var(--widget-border-radius);
  border: 3px solid transparent;
  margin: -3px 0;
}

.widget-debugwidget hr {
  border: 0;
  border-bottom: 1px solid #AAAAAA;
  margin: 0;
  grid-column: 1/-1;
  width: 100%;
}

.widget-debugbar-wrapper {
  height: 0;
}

.widget-debugbar {
  margin: 0 -3px;
  border: 3px solid transparent;
  border-bottom: 0;
  visibility: hidden;
  background-color: #DDDDDD;
  font-size: 11px;
  word-break: break-word;
  color: #777777;
  border-radius: var(--widget-border-radius) var(--widget-border-radius) 0 0;
}

.widget-debugbar__id {
  display: grid;
  grid-template-columns: minmax(0, 1fr) min-content;
  grid-template-rows: min-content min-content;
  padding: 10px var(--widget-padding-h) 10px var(--widget-padding-h);
  font-size: 14px;
  color: inherit;
  text-decoration: none;
  background: transparent;
  transition: background 0.1s;
}

.widget-debugbar__title {
  grid-column: 1;
  grid-row: 1;
  font-size: 14px;
  font-weight: bold;
  text-wrap: pretty;
}

.widget-debugbar__path {
  grid-column: 1;
  grid-row: 2;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.widget-debugwidget a.widget-debugbar__id::after {
  grid-column: 2;
  grid-row: 1/span 2;
  align-self: center;
  margin-top: -2px;
  margin-left: 10px;
  content: var(--fa-external-link);
  font: var(--fontawesome-regular);
}

.widget-debugwidget a.widget-debugbar__id:hover {
  background: #666666;
  color: #FFFFFF;
}

.widget-debugwidget__widget > .widget {
  width: 100%;
}

.widget-debugbar-specsheet {
  padding: 10px var(--widget-padding-h) 30px var(--widget-padding-h);
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr);
}

.widget-debugbar-specsheet dl {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: subgrid;
  align-items: baseline;
  margin: 0;
}

.widget-debugbar-specsheet dd,
.widget-debugbar-specsheet dt {
  margin: 0;
  padding: 0;
  line-height: 150%;
}

.widget-debugbar-specsheet dd {
  color: #555555;
  padding-right: 5px;
}

.widget-debugbar__typetitle dt {
  font-weight: bold;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.widget-debugbar__whfspath {
  word-break: break-word;
}

.widget-debugwidget:hover {
  border-color: var(--widgetdebug-border-color);
  z-index: 1;
}
.widget-debugwidget:hover .widget-debugwidget__widget {
  position: relative;
}
.widget-debugwidget:hover .widget-debugbar {
  visibility: visible;
  transform: translate(0, calc(-100% + 15px));
  border-color: var(--widgetdebug-border-color);
  box-shadow: 0 -2px 0 2px #FFFFFF, 0 -20px 10px 3px #FFFFFF;
}