/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
html.page--partner h1 {
  /* specificly for partner pages we use a more professional font-family for <h1> */
  font-family: "TypoPRO Dosis", "Dosis", Arial;
  font-weight: bold;
}

.partners__secondary {
  margin-top: 60px;
}

.widget--custom-partnerlogo,
.widget--custom-partnerlogo-small {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.widget--custom-partnerlogo > img,
.widget--custom-partnerlogo-small > img {
  display: block;
  max-width: 100%;
}

.widget--partneractivities__items a {
  color: #000000;
  font: 14px/21px Arial;
}

.partneractivity__title {
  font-weight: bold;
}

.partneractivity__link {
  color: var(--color-green);
  text-decoration: underline;
}

.widget--partneractivities__items a:hover .partneractivity__link {
  color: #FFFFFF;
}