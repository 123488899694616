/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
.defaultpageheader {
  --button-filterbar-search-desktop: 500 20px var(--rtd-heading-fontfamily);
}

.pageheader__expandedfilters {
  grid-column: 1;
  grid-row: 3;
}

.header-filterbar__querycontainer {
  position: relative;
}

.header-filterbar__submit {
  display: inline-flex;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: var(--button-height);
  padding: 5px 20px 5px 20px;
  font: var(--button-font);
  border-radius: var(--button-borderradius);
  cursor: pointer;
  background-color: var(--button-color);
  color: #FFFFFF;
  align-self: stretch;
  outline-offset: var(--actionbutton-outline-offset);
}
.header-filterbar__submit:hover {
  background-color: var(--button-color-hover);
}

html:not(.filteredoverview--showfilters) .header-expandedfilters {
  display: none;
}

.header-filterbar__togglefilters.header-filterbar__togglefilters {
  display: grid;
  color: var(--color-green);
  color: var(--themecolor);
  cursor: pointer;
}

.header-filterbar__togglefilters > * {
  grid-column: 1;
  grid-row: 1;
}

.header-filterbar__togglefilters__open {
  visibility: visible;
}

.header-filterbar__togglefilters__close {
  visibility: hidden;
}

html.filteredoverview--showfilters {
  /*
  .page__body
  .footer
  {
    display: none;
  }
  */
}
html.filteredoverview--showfilters .header-filterbar__togglefilters__open {
  visibility: hidden;
}
html.filteredoverview--showfilters .header-filterbar__togglefilters__close {
  visibility: visible;
}

.pageheader__expandedfilters__content {
  width: 100%;
  max-width: var(--pagegrid-contentarea-width-full);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  padding-top: 7px;
  padding-bottom: 65px;
}

.header-filterbar__submit {
  flex: none;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: var(--button-height);
  padding: 5px 20px 5px 20px;
  font: var(--button-font);
  border-radius: var(--button-borderradius);
  cursor: pointer;
  background-color: var(--button-color);
  color: #FFFFFF;
}
.header-filterbar__submit:hover {
  background-color: var(--button-color-hover);
}

.header-filterbar__togglefilters {
  flex: none;
  display: inline-flex;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: var(--button-height);
  padding: 5px 20px 5px 20px;
  font: var(--button-font);
  border-radius: var(--button-borderradius);
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid var(--button-color);
  color: var(--button-color);
  font: var(--filterbar-button-font);
  height: var(--filterbar-lineheight);
  cursor: pointer;
}
.header-filterbar__togglefilters:hover {
  background-image: none;
  background-color: var(--button-color-hover);
  color: #FFFFFF;
}

.header-filterbar__togglefilters > * {
  align-items: center;
}

.header-filterbar__query.header-filterbar__query {
  height: var(--filterbar-lineheight);
  background-color: #FFFFFF;
  font: 16px var(--rtd-heading-fontfamily);
  color: #333333;
}

.header-filterbar__submit {
  font: var(--button-filterbar-search-desktop);
  height: var(--filterbar-lineheight);
  cursor: pointer;
}

@media (max-width: 549px) {
  .defaultpageheader {
    --filterbar-lineheight: 44px;
    --filterbar-button-font: var(--button-filterbar-search-mobile);
  }
  .pageheader__filterbar__content {
    display: flex;
    flex-wrap: wrap;
  }
  .header-filterbar__querycontainer {
    display: contents;
  }
  .header-filterbar__query.header-filterbar__query {
    margin-bottom: 10px;
    flex: 1 0 100%;
    border: 1px solid #929292;
  }
  .header-filterbar__query.header-filterbar__query .header-filterbar__submit {
    padding-left: 13px;
  }
  .header-filterbar__submit,
  .header-filterbar__togglefilters {
    flex: 1 0 auto;
  }
  .header-filterbar__submit {
    justify-content: space-between;
  }
  .header-filterbar__submit .fa-search {
    font-size: 20px;
  }
  .header-filterbar__togglefilters .far {
    font-size: 20px;
  }
  .header-filterbar__togglefilters > * {
    display: flex;
  }
  .header-filterbar__togglefilters__label {
    margin-right: auto;
  }
  .header-filterbar__togglefilters__icon {
    font-size: 18px;
  }
  .header-filterbar__togglefilters {
    margin-left: 12px;
    display: inline-flex;
    align-items: center;
    appearance: none;
    -webkit-appearance: none;
    padding: 0;
    border: 0;
    background-color: transparent;
    height: var(--button-height);
    padding: 5px 20px 5px 20px;
    font: var(--button-font);
    border-radius: var(--button-borderradius);
    cursor: pointer;
    background-color: #FFFFFF;
    border: 1px solid var(--button-color);
    color: var(--button-color);
    color: var(--color-green);
  }
  .header-filterbar__togglefilters:hover {
    background-image: none;
    background-color: var(--button-color-hover);
    color: #FFFFFF;
  }
  .header-filterbar__query.header-filterbar__query,
  .header-filterbar__submit,
  .header-filterbar__togglefilters {
    border-radius: 5px;
  }
}
@media (min-width: 550px) {
  .defaultpageheader {
    --filterbar-lineheight: 46px;
    --filterbar-button-font: var(--button-filterbar-search-mobile);
  }
  .header-filterbar__querycontainer {
    flex: 1 0 auto;
    display: flex;
    flex-wrap: wrap;
    background-color: #FFFFFF;
    border: 1px solid #9E9E9E;
    border-radius: 4px;
  }
  .header-filterbar__querycontainer .wh-autocomplete-container {
    width: 100%;
  }
  .header-filterbar__query.header-filterbar__query {
    flex: 1 0 0px;
    padding-left: 15px;
    border: 0;
    background-color: transparent;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0px !important;
  }
  .header-filterbar__submit {
    padding-left: 17px;
    padding-right: 17px;
    border-radius: 0;
  }
  .header-filterbar__submit .fa-search {
    margin-left: 52px;
  }
  .header-filterbar__togglefilters {
    margin-left: 20px;
  }
  .header-filterbar__togglefilters__icon {
    margin-left: 25px;
  }
}