.widget-search--events
{
  --themecolor: var(--mbo-purple-dark);
}

.widget-search--news
{
  --themecolor: var(--wig-green-normal);
}

.widget-search
{
  border: 2px solid var(--themecolor);
  box-shadow: none;
}

/* Group the searchbar and suggestions dropdown */
.widget-search__form
{
  display: flex;
  flex-wrap: wrap;
}

/* To group the input and search button */
.widget-search__form__searchbar
{
  flex: 1 0 auto;

  display: flex;
  height: 44px;

  border-radius: 6px;
}

.widget-search__form .wh-autocomplete-container
{
  top: 100%; /* below the search input */
  position: absolute;
  width: 100%; /* use the full width of the flex container */
}

.widget-search__form .wh-autocomplete-values
{
  position: static;
}

.widget-search__form__searchbar:focus-within
{
  outline: 2px dotted #212121;
  outline-offset: 6px;
  outline: -webkit-focus-ring-color auto 5px;
}

.widget-search__form__searchbar__input
{
  background: none !important;
  border: 0 !important;
  width: 100%;
  padding: 1px 0 0 13px !important;
}

.widget-search__form__searchbar__input:focus
{
  outline: 0 !important;
  box-shadow: none !important;
  border: 0 !important;
}

.widget-search__form__searchbar__button
{
  background: var(--themecolor);
  border: 0;
  width: 45px;
  height: 44px;
  color: #FFFFFF;
  font-size: 18px;
  cursor: pointer;
}

.widget-search__form__searchbar__button:hover
{
  background: var(--button-color-hover);
}



/** Widget 'News search' ***************************************************************/
/*
.widget--newssearch
{

}
@media print
{
  .widget--newssearch
  {
    display: none;
  }
}


.newssearch__period__query
{
  width: 100%;
}

@media (min-width: 551px)
{
  .newssearch__period
  {
    display: flex;
  }

  .newssearch__period select
  {
    flex: 1 1 auto;
    min-width: 0;
  }

  .newssearch__period select + select
  {
    margin-left: 10px;
  }

    .newssearch__period input
  , .newssearch__period select
  {
    padding-left: 7px;
  }
}

@media (max-width: 550px)
{
  .newssearch__period select
  {
    width: 100%;
  }

  .newssearch__period select + select
  {
    margin-top: 8px;
  }
}
*/
