/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
.pageheader--panels {
  --pageheader-image-height: clamp(152px, 25vw, 400px);
  --pageheader-image-overlap: clamp(43px, 9vw, 95px) !important;
  --pageheader-panel-header-radius: 20px 0 20px 20px;
  --pageheader-panel-header-firstline-font: 22px/36px var(--rtd-heading-fontfamily);
  --pageheader-panel-header-font: 32px/36px var(--rtd-heading-fontfamily);
}

@media (max-width: 767px) {
  .pageheader--panels {
    --pageheader-image-overlap: clamp(44px, 5vw, 95px) !important;
    --pageheader-panel-header-radius: 8px;
    --pageheader-panel-header-firstline-font: 20px/36px var(--rtd-heading-fontfamily);
    --pageheader-panel-header-font: 26px/36px var(--rtd-heading-fontfamily);
  }
}
.pageheader__content--panels {
  width: 100%;
  max-width: var(--pagegrid-contentarea-width-full);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
}

.pageheader__overlay__panel {
  border-radius: var(--pageheader-panel-header-radius);
  padding: 15px 15px 25px 15px;
  --mbo-purple-homepanel: #D4BED4;
  background: #BBBBBB;
  --rtd-text-color: var(--text-black);
  --rtd-text-font: 17px/26px var(--rtd-heading-fontfamily);
  --button-color: var(--panelthemecolor);
  --button-color-hover: #0B923E;
  --button-outline-text-color: var(--panelthemecolor);
  --button-padding: 5px 10px 5px 10px;
  background: var(--panel-background);
}
.pageheader__overlay__panel h2 {
  color: var(--panelthemecolor);
}

.pageheader__overlay__panel h2 {
  font: var(--pageheader-panel-header-font);
  margin-top: 0;
}

.pageheader__overlay__panel h2:first-line {
  font: var(--pageheader-panel-header-firstline-font);
}

.pageheader__overlay__panel.theme--po {
  --panelthemecolor: var(--po-brown-dark);
  --panel-background: var(--po-yellow-homepanel);
}

.pageheader__overlay__panel.theme--vo {
  --panelthemecolor: var(--vo-blue-dark);
  --panel-background: var(--vo-blue-bright);
}

.pageheader__overlay__panel.theme--mbo {
  --panelthemecolor: var(--mbo-purple-dark);
  --panel-background: var(--mbo-purple-homepanel);
}

.pageheader__overlay__panel.theme--wig {
  --panelthemecolor: var(--wig-green-dark);
  --panel-background: var(--wig-green-tint);
  border: 1px solid var(--color-green);
  border: 1px solid var(--color-theme-background);
}

.pageheader__overlay__panel__text {
  margin-bottom: 20px;
}

.pageheader__overlay__panel__text > * {
  color: var(--text-black);
  --rtd-text-color: var(--text-black);
}

.pageheader__overlay__panel__text > *:last-child {
  margin-bottom: 0;
}

.pageheader__overlay__panel h2 {
  margin-bottom: 15px;
}

.pageheader__overlay__panel .pageheader__overlay__panel__ctalink {
  text-decoration: none;
  outline-offset: 4px;
  font: 500 18px/24px var(--rtd-heading-fontfamily);
  margin-top: 20px;
  border-width: 2px;
  --button-borderradius: 10px 0 10px 10px;
  cursor: pointer;
}
.pageheader__overlay__panel .pageheader__overlay__panel__ctalink .icon {
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.pageheader__overlay__panel .pageheader__overlay__panel__ctalink .icon::before {
  content: var(--fa-chevron-circle-right);
  font: var(--fontawesome-regular);
  font-size: 22px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@media (max-width: 767px) {
  .pageheader__overlays {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  .pageheader__overlay__panel {
    display: grid !important;
    grid-template-columns: minmax(0, 1fr) min-content;
    column-gap: 15px;
    padding: 0;
  }
  .pageheader__overlay__panel h2,
  .pageheader__overlay__panel__ctalink {
    grid-column: 1;
    grid-row: 1;
    margin-top: 0 !important;
  }
  .pageheader__overlay__panel__ctalink {
    grid-column: 1/-1;
    grid-row: 1/-1;
    border-radius: var(--headerpanels-radius) !important;
  }
  .pageheader__overlay__panel__ctalink .icon,
  .pageheader__overlay__panel__ctalink .text {
    display: none;
  }
  .pageheader__overlay__panel h2 {
    padding: 6px 20px 9px 20px;
  }
  .pageheader__overlay__panel::after {
    grid-column: 2;
    grid-row: 1;
    align-self: center;
    padding-right: 20px;
    color: var(--panelthemecolor);
    content: var(--fa-chevron-circle-right);
    font: var(--fontawesome-regular);
    font-size: 22px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  .pageheader__overlay__panel__ctalink {
    border: 0 !important;
    background: transparent !important;
  }
  .pageheader__overlay__panel:has(.pageheader__overlay__panel__ctalink:hover) {
    background: var(--panelthemecolor);
    box-shadow: 0 0 0 1px rgb(255, 255, 255);
  }
  .pageheader__overlay__panel:has(.pageheader__overlay__panel__ctalink:hover) h2 {
    color: #FFFFFF;
  }
  .pageheader__overlay__panel h2 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .pageheader__overlay__panel__text {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .pageheader__overlays {
    grid-column: var(--pagegrid-col-verywide);
    display: flex;
    column-gap: 20px;
  }
  .pageheader__overlay__panel {
    flex: 1 0 0px;
  }
  .pageheader__overlay__panel__text {
    flex: 1 0 auto;
  }
  .pageheader__overlay__panel .pageheader__overlay__panel__ctalink {
    width: fit-content;
  }
}
.pageheader__overlay__panel {
  display: flex;
  flex-direction: column;
}

/* iOS 16+ */
@supports (display: grid) and (grid-template-columns: subgrid) {
  @media (min-width: 768px) {
    .pageheader__overlays {
      column-gap: 20px;
      display: grid;
      grid-auto-columns: 1fr;
      grid-template-rows: min-content minmax(min-content, 1fr) max-content;
    }
    .pageheader__overlay__panel {
      grid-row: 1/span 3;
      display: grid;
      grid-template-rows: subgrid;
    }
    .pageheader__overlay__panel h2 {
      grid-row: 1;
    }
    .pageheader__overlay__panel__text {
      grid-row: 2;
    }
    .pageheader__overlay__panel__ctalink {
      grid-row: 3;
      margin-top: 0 !important;
      min-height: 55px;
    }
  }
}