.timetablewidget-popup .wh-popup-closebutton {
  position: absolute;
  top: 0;
  right: 0;
  color: #FFFFFF;
  width: 41px;
  height: 41px;
  background: #000000 no-repeat scroll center center;
  background-size: 18px 18px;
  color: #FFFFFF;
  line-height: 41px;
  text-align: center;
  cursor: pointer;
}

.ttw-headerbar {
  background-color: #f5fafe;
}

.ttw-headerbar {
  border-top: 1px solid #e9f1f4;
  padding: 30px 33px 30px 33px;
}

.ttw-heading {
  margin: 0 0 30px 0;
}

.ttw-controlbar {
  margin-bottom: 30px;
}

/* do not hide .ttw-tabselect, because depending on the device it might be
   replaced by a custom pulldown */
.timetablewidget .ttw-controlbar {
  display: none;
}

.timetablewidget.hide_tabstrip .ttw-controlbar {
  display: block;
  /*
    visibility: hidden;
    height: 0px;
    overflow: hidden;
  */
}

/*
  popout, e.g. the small dark-grey "note" that opens when the users hovers
  over the small questionmark-icon in a programmelist widget
*/
.popout {
  display: none;
  max-width: 270px;
  padding: 15px 15px 15px 15px;
  background-color: #444444;
  color: #FFFFFF;
  font: italic 15px/22px Arial;
  -webkit-transform: translate3d(0, 0, 0);
  opacity: 0;
  transition: opacity 0.2s;
}

/*
.popout.visible
{
  display: block;
}
*/
.popout.active {
  opacity: 1;
}

.popout a {
  color: #FFFFFF;
}

/*
.popout .flap
{
  position: absolute;
  right: -32px;
  top: 32px;
  bottom: 0;

  width: 32px;
  background-color: #444444;
}

.popout .flap:before
{
  content: " ";

  position: absolute;
  top: -32px;
  width: 32px;
  height: 64px;
  background: transparent url("../img/common/tooltip_flap.png") no-repeat scroll right top;
}
.popout:after
{
  content: " ";

  position: absolute;
  left: 50%;
  margin-left: -8px;
  bottom: -8px;

  width: 16px;
  height: 8px;

  background: transparent url("../img/common/tooltip_arrow.png") no-repeat scroll top left;
}
*/