/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
.lesmateriaal__sidebar {
  width: 100%;
}

.lesmateriaal__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 13px 20px;
  border-radius: 8px;
  background: linear-gradient(180deg, #641C76 0%, #9420B1 100%);
  color: #FFFFFF;
  font: 500 22px/24px var(--rtd-heading-fontfamily);
  text-decoration: none;
}

.pagetheme--mbo .lesmateriaal__link,
.pagetheme--po .lesmateriaal__link,
.pagetheme--vo .lesmateriaal__link {
  background: var(--button-color);
}

.pagetheme--mbo .lesmateriaal__link:hover,
.pagetheme--po .lesmateriaal__link:hover,
.pagetheme--vo .lesmateriaal__link:hover {
  background: var(--button-color-hover);
}

.lesmateriaal__link:hover {
  color: #FFFFFF;
}

.lesmateriaal__link .fas {
  margin-left: 30px;
}

/*
.lesmateriaal__link::after
{
  @include fontawesome-solid;
  content: fa-external-link;
}
*/
.lesmateriaal__sidebar h2 {
  color: var(--rtd-heading-color);
  font: bold 18px var(--rtd-heading-fontfamily);
  margin-top: 0;
  margin-bottom: 8px;
}

.lesmateriaal__aanbieder {
  display: block;
  max-width: 100%;
}

.lesmateriaal__aanbieder + .lesmateriaal__aanbieder {
  margin-top: 22px;
}

.lesmateriaal__sidebar__part + .lesmateriaal__sidebar__part {
  margin-top: 22px;
}

.lesmateriaal__sidebar + .widget--widgetsgroup {
  margin-top: 30px;
  border-top: 1px solid #D7D7D7;
  padding-top: 26px;
}

.tagslist {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.lesmateriaal__tag,
.lesmateriaal__tag--solid {
  display: inline-flex;
  align-items: center;
  min-height: 29px;
  font: 14px Arial;
  padding: 5px 14px;
}

.lesmateriaal__tag {
  /*
  display: block;
  width: max-content;
  max-width: 100%;

  display: table;
  */
  border: 1px solid #8E8E8E;
  border-radius: 16px;
  background-color: #FFFFFF;
  color: #3F3F3F;
  text-decoration: none;
}

.lesmateriaal__tag--solid,
a.lesmateriaal__tag:hover {
  border: 1px solid var(--themecolor);
  border-radius: 16px;
  background: var(--themecolor);
  color: #FFFFFF;
}

.lesmateriaal__tag .icon,
.lesmateriaal__tag--solid .icon {
  margin-left: 12px;
}