/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
.lesmaterialen__note {
  display: flex;
  align-items: baseline;
  color: var(--widget-highlight-textcolor);
  background: var(--widget-highlight-background);
  padding: var(--widget-highlight-pad-v) var(--widget-highlight-pad-h) var(--widget-highlight-pad-v) var(--widget-highlight-pad-h);
  border: 2px solid var(--widget-highlight-border-color);
  border-radius: var(--widget-border-radius);
  --rtd-text-font: 500 18px/22px var(--rtd-heading-fontfamily);
  margin-bottom: 30px;
}

.lesmaterialen__note::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: var(--fa-chevron-right);
  font-size: 18px;
  margin-right: 17px;
}

.lesmateriaalblok {
  display: block;
  text-decoration: none;
  position: relative;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
  background: #FFFFFF;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.lesmateriaalblok:hover {
  background: linear-gradient(to bottom, #F0F0F0 0%, #FFFFFF 80px);
}

.lesmateriaalblok:hover .lesmateriaalblok__title {
  text-decoration: underline;
}

.lesmateriaalblok.notmatching {
  display: none;
}

.lesmateriaalblok + .lesmateriaalblok {
  margin-top: 23px;
}

.lesmateriaalblok__aanbieder {
  display: flex;
  color: var(--themecolor);
  text-transform: uppercase;
}

.lesmateriaalblok__aanbieder__symbol {
  margin-right: 6px;
}

.lesmateriaalblok__aanbieder + .lesmateriaalblok__title {
  margin-top: 2px;
}

.lesmateriaalblok__title {
  color: var(--themecolor);
}

.lesmateriaalblok__description {
  color: #333333;
  word-break: break-word;
  /*
    This work on Edge 17+, Chrome 14+, Safari 5+, iOS 5+, Firefox 68

  //   This work on:
  //   - Chrome 14+  (sep 2011)
  //   - Edge 17+    (apr 2018)
  //   - Safari 5+   (mar 2012)
  //   - iOS 5+      (mar 2012)
  //   - Firefox 68+ (jul 2019)

     Also see
     - https://bugzilla.mozilla.org/show_bug.cgi?id=866102 - Support for -webkit-line-clamp
     - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992 - Support for a future max-lines property
     - https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
    */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.lesmateriaalblok__specsheet {
  border-collapse: collapse;
  color: #656565;
}

.lesmateriaalblok__link {
  text-decoration: none;
  margin-left: auto;
  justify-content: space-between;
  display: inline-flex;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: var(--button-height);
  padding: 5px 20px 5px 20px;
  font: var(--button-font);
  border-radius: var(--button-borderradius);
  cursor: pointer;
  background-color: var(--button-color);
  color: #FFFFFF;
  --button-color: var(--themecolor);
  --button-font: var(--button-bekijklesmateriaal-font);
}
.lesmateriaalblok__link:hover {
  background-color: var(--button-color-hover);
}

.lesmateriaalblok__link::after {
  margin-left: 25px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f054";
}

.lesmateriaalblok:hover .lesmateriaalblok__link {
  background: var(--button-color-hover);
}

.lesmateriaalblok__specsheet td {
  padding-left: 7px;
  vertical-align: baseline;
}

/* <td> (column) with icon */
.lesmateriaalblok__specsheet th:first-child {
  padding-left: 0;
  text-align: center;
  color: var(--themecolor);
}

@media (max-width: 799px) {
  .lesmateriaalblok {
    padding: 15px 14px;
  }
  .lesmateriaalblok__costtag {
    display: none;
  }
  .lesmateriaalblok__aanbieder {
    font: 14px var(--rtd-heading-fontfamily);
  }
  .lesmateriaalblok__aanbieder + .lesmateriaalblok__title {
    margin-top: 2px;
  }
  .lesmateriaalblok__title {
    font: bold 20px/28px var(--rtd-heading-fontfamily);
  }
  .lesmateriaalblok__description {
    margin-top: 15px;
    font: 15px var(--rtd-alternative-fontfamily);
  }
  .lesmateriaalblok__specsheet {
    margin-top: 15px;
  }
  .lesmateriaalblok__link {
    display: flex;
    margin-top: 20px;
    --button-height: 44px;
    font: var(--button-bekijklesmateriaal-font);
  }
  .lesmateriaalblok__link::after {
    font-size: 18px;
  }
  .lesmateriaalblok__specsheet {
    font: 15px/22px var(--rtd-heading-fontfamily);
  }
  .lesmateriaalblok__specsheet td {
    padding-left: 7px;
  }
  .lesmateriaalblok__specsheet td:first-child {
    padding-left: 0;
    text-align: center;
  }
}
@media (min-width: 800px) {
  .lesmateriaalblok {
    padding: 20px 20px 20px 30px;
  }
  .lesmateriaalblok__costtag {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .lesmateriaalblok__aanbieder {
    font: 16px var(--rtd-heading-fontfamily);
  }
  .lesmateriaalblok__title {
    margin-top: 2px;
    font: bold 28px var(--rtd-heading-fontfamily);
  }
  .lesmateriaalblok__description {
    margin-top: 15px;
    font: 16px/24px var(--rtd-alternative-fontfamily);
  }
  .lesmateriaalblok__bottombar {
    margin-top: 25px;
    display: flex;
    align-items: flex-end;
  }
  .lesmateriaalblok__specsheet {
    font: 17px/22px var(--rtd-heading-fontfamily);
  }
  .lesmateriaalblok__specsheet__cost {
    display: none;
  }
  .lesmateriaalblok__link {
    display: inline-flex;
    font: var(--button-bekijklesmateriaal-font);
    --button-height: 44px;
  }
}