html
{
  /************************************************************
   *
   *  Page components / Navigation path (crumpath)
   */

  --component-navpath-color:           var(--color-grey-text-small); /* #656565; */
  --component-navpath-link-color:      var(--color-grey-text-small); /* #656565; */
  --component-navpath-seperator-color: var(--color-grey-text-small); /* #656565; */
  --component-navpath-font:            16px Arial;
  --component-navpath-gap-v:           4px;
  --component-navpath-prefix-margin:   10px; /* extra space between prefix and items */

  --component-navpath-item-padding-h:  4px;
  --component-navpath-item-padding-v:  5px;

  --component-navpath-seperator-content: "»";


  /************************************************************
   *
   *  Page components / Share bar
   */

  /*--component-sharepage-button-background:  linear-gradient(180deg, #FFFFFF 0%, #E7F7ED 100%);*/
  --component-sharepage-button-background:  #FFFFFF;
  --component-sharepage-button-color:       var(--themecolor);
  --component-sharepage-heading-color:      var(--themecolor);
  --component-sharepage-heading-font:       20px/26px var(--rtd-heading-fontfamily);



  --tagpanel-heading-color: var(--rtd-heading-color);
}


html.styling2025
{
  --component-navpath-color:           var(--color-aagrey);
  --component-navpath-link-color:      var(--rtd-link-color);
  --component-navpath-seperator-color: var(--rtd-link-color);
  --component-navpath-font:            13.5px var(--rtd-heading-fontfamily);
  --component-navpath-gap-v:           4px;
  --component-navpath-prefix-margin:   10px; /* niet van toepassing */

  --component-navpath-item-padding-h:  4px;
  --component-navpath-item-padding-v:  5px;

  --component-navpath-seperator-content: ">";
}
