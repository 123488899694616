/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
/* was 880px, maar met pulldown toggles hebben we meer ruimte nodig en toen een tussenoplossing voor het menu bedacht waarmee juist 768px (iPad) ook past */
/** @short The active menu selection will look like a tab from which the dropdown extends.
 *  @long Use this mixin on the .spc-menubar container
 *  !! use this AFTER the @include menu-panel-pulldown.
 */
/** @short Dropdown appears slightly below the menubar item with an arrow pointing to the menu item it belongs to
 * (FIXME: test)
*/
/** @short Dropdown appears slightly below the menubar item with the top of the dropdown having kind of a half arrow pointing to the menu item the dropdown belongs to
 * (FIXME: test)
*/
.siteheader__showsidebartoggle {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.siteheader-top .siteheader__showsidebartoggle {
  color: var(--color-green);
}

.siteheader-menubar .siteheader__showsidebartoggle {
  color: #FFFFFF;
}

.siteheader__showsidebartoggle .far {
  font-size: 20px;
}

.siteheader-top__search {
  align-self: center;
  position: absolute;
  right: 5px;
  width: 75%;
  max-width: 450px;
  pointer-events: none;
}

.siteheader-top__search__submit {
  pointer-events: auto;
}

html.headersearch--active .siteheader-top__search {
  pointer-events: auto;
}

.siteheader-top__search .wh-autocomplete-container {
  top: 100%;
  position: absolute;
  width: 100%;
}

.siteheader-top__search .wh-autocomplete-values {
  position: static;
}

.siteheader-top__search__clearfieldwrapper {
  flex: 1 0 auto;
  margin-right: -40px;
}

.siteheader-top__search__input {
  width: 100%;
}

.siteheader-top__right {
  align-self: flex-end;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
}

html.site--wvhg .siteheader-top__right {
  margin-bottom: 19px;
}

.siteheader-top__search {
  display: flex;
}

.siteheader__showsidebartoggle {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.siteheader-top__search__submit {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 38px;
  /*
    @media (min-width: $minwidth-fullheader)
    {
      border: 1px solid rgba(255, 255, 255, 0.35);
      border-radius: var(--siteheader-search-borderradius);
    }
  */
  color: #FFFFFF;
  cursor: pointer;
}

.siteheader-top__search__submit .fas {
  font-size: 20px;
}

html.headersearch--active .siteheader-top__search__submit {
  border-color: transparent;
}
html.headersearch--active .siteheader-top__search__submit .fas {
  color: var(--color-green);
}

html.styling2025 .siteheader-top__search__submit {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  width: 38px;
  height: 38px;
}
html.styling2025:not(.headersearch--active) .siteheader-top__search__submit:hover {
  background: var(--menubar-bar-item-hover-background);
  border: 1px solid transparent;
  color: var(--menubar-bar-item-hover-color);
  transition: background 0.2s, border 0.2s, color 0.2s;
}

.siteheader-top__search {
  --formcontrols-focus-border-width: 0;
  --formcontrols-focus-outline: 2px solid rgba(255,255,255,0.6);
  --formcontrols-focus-outline-offset: 3px;
}

.spccomp-searchpanel {
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s;
  position: absolute;
  top: 50%;
  transform: translate(15px, -50%);
  right: 0;
  width: 500px;
}

.spccomp-search:focus-within .spccomp-searchpanel,
html.page--search .spccomp-searchpanel {
  opacity: 1;
  pointer-events: auto;
}

/* IE11 & old Edge - keer seperate */
.spccomp-search.focus-within .spccomp-searchpanel {
  opacity: 1;
  pointer-events: auto;
}

.siteheader-top__search__input.siteheader-top__search__input {
  border-radius: var(--siteheader-search-borderradius);
  height: 100%;
  opacity: 0;
}

.headersearch--active .siteheader-top__search__input.siteheader-top__search__input,
.siteheader-top__search__input.siteheader-top__search__input:focus {
  opacity: 1;
}

.spccomp-searchpanel__query.spccomp-searchpanel__query {
  padding-left: 56px;
}

/*
.spccomp-searchpanel__query__submit
{
  @include clearbuttonelementstyling;
  font-size: 22px;
  color: $themecolor-pink;

  position: absolute;
  left: 20px;
  top: 9px;
}
*/
.spccomp-searchpanel__query__clear {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-size: 24px;
  color: #767676;
  position: absolute;
  right: 25px;
  top: 9px;
}

.siteheader-menubar {
  display: grid;
  z-index: 2;
  background-color: #13A538;
  background-color: var(--color-theme-background);
  height: var(--site-menubar-height);
}

.siteheader-menubar__content {
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  width: 100%;
  max-width: var(--pagegrid-contentarea-width-full);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
}

.siteheader-menubar__content {
  position: relative;
  display: flex;
  height: 100%;
}

.site__header__menu__items {
  height: 100%;
}

.siteheader-menubar__payoff {
  font: 400 14px "TypoPRO Dosis", "Dosis", Arial;
  color: #FFFFFF;
}

@media (min-width: 768px) {
  .siteheader__showsidebartoggle {
    display: none;
  }
  .siteheader-top__content {
    position: relative;
  }
  .siteheader-top__right {
    margin-left: auto;
  }
  a.siteheader-top__right__initiatief {
    margin-top: 15px;
    text-decoration: none;
    text-align: right;
    font: 400 14px "TypoPRO Dosis", "Dosis", Arial;
    color: var(--color-grey-text-small);
  }
  .siteheader-top__right__initiatief img {
    width: 195px;
    height: 24px;
  }
}
@media (max-width: 767px) {
  html.headersearch--active .siteheader-identity {
    visibility: hidden;
  }
  .siteheader__menu {
    display: none;
  }
  .siteheader-menubar__content {
    padding-right: 35px;
  }
  .siteheader-menubar .siteheader__showsidebartoggle {
    margin-left: auto;
    flex: none;
    margin-right: 15px;
  }
  .siteheader__showsidebartoggle .far {
    font-size: 22px;
    margin-top: -1px;
  }
  .siteheader-top {
    display: flex;
    align-items: center;
  }
  html.site--wvhg .siteheader-top__right {
    display: none;
  }
  .siteheader-top__right {
    margin-left: 30px;
    flex: 1 0 auto;
  }
  a.siteheader-top__right__initiatief {
    text-decoration: none;
    text-align: right;
    font: 400 13px "TypoPRO Dosis", "Dosis", Arial;
    color: var(--color-grey-text-small);
    margin-top: 2px;
    margin-bottom: -2px;
  }
  .siteheader-top__right__initiatief img {
    height: 18px;
  }
  .siteheader-top__search {
    width: 100%;
  }
  .siteheader-top__search__submit,
  .siteheader-top__search__input {
    height: 41px;
  }
}