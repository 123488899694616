/* FIXME: since our widgets always get into the bottom widget container we only
          want the background gradient to appear there */
.page--activity .content__widgetscolumn {
  background: none !important;
}

.body__widgetsbottom .widget--widgetsgroup {
  margin-top: 0;
  padding-top: 0;
}

/* The content of activities itself is already two column
   so the give enough space the design has the widgets at the
   bottom on mobile/tablet design
*/
@media (max-width: 639px) {
  .page--activity .layout--widgets::after {
    content: "bottom" !important; /* move widgets into .body_widgetsbottom */
    display: none;
  }
  .body__widgetsbottom {
    display: block;
  }
}
/* Activity - Mobile / Tablet-portrait */
@media (max-width: 639px) {
  .page--activity__contentcolumn {
    margin-top: 20px;
  }
  .page--activity__poster--mobile {
    display: block;
    background-color: var(--color-magenta);
    color: #FFFFFF;
    padding: 25px 25px 20px 25px;
  }
  .page--activity__posterimage {
    display: block;
    max-width: 75%;
    border: 2px solid #FFFFFF;
    margin: 0 auto;
  }
  .page--activity__posterimage + .page--activity__posterbody {
    margin-top: 5px;
  }
  .page--activity__posterbody {
    text-align: center;
  }
  .page--activity__poster--mobile .content--twocolumn {
    margin-top: 0;
  }
  /* hide the tablet/desktop version of the poster */
  .page--activity__columns .page--activity__imagecolumn {
    display: none;
  }
}
/* Activity - Desktop */
@media (min-width: 640px) {
  .page--activity__poster--mobile {
    display: none;
  }
  html.page--activity {
    --pagecontent-widgetcolumn-width: 250px;
  }
  .page--activity__columns {
    display: flex;
  }
  .page--activity__imagecolumn {
    width: 220px;
    margin-right: 20px;
  }
  .page--activity__contentcolumn {
    flex: 1;
  }
  .page--activity__poster {
    background-color: var(--color-magenta);
    color: #FFFFFF;
    border-radius: 5px;
    overflow: hidden;
  }
  .page--activity__posterimage {
    display: block;
    width: 100%;
  }
  .page--activity__posterbody {
    padding: 10px;
    text-align: right;
  }
}
.widget__activitydetail {
  border: 2px solid var(--color-purple);
  border-radius: 5px;
  background-color: #FFFFFF;
}

.widget__activitydetail__footer {
  background-color: var(--color-purple);
  color: #FFFFFF;
}

.widget__activitydetail__footer a {
  color: #FFFFFF;
}

.widget__activitydetail .label {
  font-weight: bold;
}

.widget__activity-organizers img {
  display: block;
  max-width: 100%;
}

.activityorganizer {
  /*
   shrink-wrap (empty space shouldn't trigger the <a>), but also wrap to next line after this element
   .. however IE won't have children inherit the with from a table container (but Chrome/FF/Edge will work with this)
    display: table;*/
  display: block;
  margin-top: 20px;
  width: 100%;
  /* max-height: 100px;*/
}

.activityshare__container {
  margin-top: 10px;
}

.activity__maillink {
  width: 100%;
  background-color: var(--color-magenta);
}

.activity__maillink:hover {
  background-color: var(--color-magenta-hover);
}

.activity__share {
  width: 100%;
}

.ctabutton > .far:first-child {
  font-size: 18px;
  margin-right: 10px;
}

.activityshare__buttonbar {
  display: none;
}

.activityshare__container.wh-toggledelement--active .activityshare__buttonbar {
  display: flex;
  margin-top: 3px;
}

.activityshare__buttonbar button {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: var(--color-green);
  font-size: 30px;
  line-height: 30px;
  vertical-align: middle;
}

.activityshare__buttonbar button + button {
  margin-left: 7px;
}

.dialog--mail-page {
  width: 600px;
}

.dialog--mail-page__emailsentmessage {
  display: none;
}

.dialog--mail-page--emailsent .dialog--mail-page__emailsentmessage {
  display: block;
}

.dialog--mail-page--emailsent .tellafriendform {
  display: none;
}

.flatpickr-clear {
  display: table;
  margin: 15px 10px 10px auto;
  padding: 5px 15px;
  background-color: #AAAAAA;
  color: #FFFFFF;
  cursor: pointer;
}