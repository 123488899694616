/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
/* Breakpoints for specific site/page TRAITS */
.page--activitiesoverview {
  --activity-border: 1px solid #0db24c;
  --activity-readius: 5px;
  --activityheader-symbol-width: 70px;
}

html.page--activitiesoverview .content--twocolumn > .content__contentcolumn h1,
#widget-searchactivity__heading {
  line-height: 40px;
  margin: 0 0 15px 0;
}

#widget-searchactivity__heading {
  transform: translateY(4px);
  width: 100%;
  color: #FFFFFF;
}

.activitiesoverview__columnheaders {
  background: var(--color-green-light-background);
  border: var(--activity-border);
  border-radius: var(--activity-readius);
  padding: 2px 0;
}

.activitiesoverview__ch_activity {
  padding-left: var(--activityheader-symbol-width);
}

.activitiesoverview__header {
  padding-left: 65px;
}

.widget__searchactivity select,
.widget__searchactivity input[type=text] {
  width: 100%;
  border: 0;
}

.widget__searchactivity.widget__searchactivity input:focus,
.widget__searchactivity.widget__searchactivity select:focus {
  --formcontrols-focus-boxshadow: 0 0 0 3px rgba(255, 255, 255, 0.3);
  --formcontrols-focus-outline: 2px solid #FFFFFF;
  --formcontrols-focus-outline-offset: 3px;
}

.page--activitiesoverview .content__widgetscolumn {
  background-color: transparent;
  margin-top: 0 !important; /* keep sticking to the 'Zoek jouw activiteit' header */
  padding-top: 0 !important;
}

.page--activitiesoverview .body__content {
  margin-top: 30px;
}

/* tablet has it's own design for the filter widget */
@media (min-width: 641px) and (max-width: 799px) {
  .widget__searchactivity form {
    display: flex;
    flex-wrap: wrap;
  }
  .widget__searchactivity .widget__row {
    margin-top: 0;
  }
  .widget__searchactivity .widget__row__locations,
  .widget__searchactivity .widget__row__targetaudiences,
  .widget__searchactivity .widget__row__activitytype {
    width: calc(33.33% - 6.6666666667px);
  }
  .widget__searchactivity .widget__row__locations + .widget__row__targetaudiences,
  .widget__searchactivity .widget__row__targetaudiences + .widget__row__activitytype {
    margin-left: 10px;
  }
  .widget__searchactivity .widget__row__searchquery {
    margin-top: 10px;
    width: 100%;
  }
  .widget__row__activitydate {
    display: flex;
    width: 100%;
    align-items: baseline;
    flex-wrap: wrap;
  }
  .widget__row__activitydate.widget__row__activitydate > label {
    width: 100%;
  }
  .widget__row__activitydate.widget__row__activitydate > select {
    width: auto;
  }
  .widget__row__activitydate.widget__row__activitydate {
    margin-top: 10px;
  }
  .widget__row__activitydate__dates {
    visibility: hidden;
    display: flex;
    align-items: baseline;
    margin-left: 10px;
  }
  .widget__row__activitydate__dates {
    flex: 1 1 auto;
  }
  .widget__row__activitydate__dates .widget__row {
    flex: 1 1 auto;
  }
  .widget__row__activitydate__dates .widget__row.widget__row {
    margin-top: 0;
  }
  .widget__row__activitydate__dates .widget__row.widget__row label {
    margin-right: 10px;
  }
  .widget__row__activitydate.activitydate--pickdate .widget__row__activitydate__dates {
    visibility: visible;
  }
  .widget__row__activitydate__dates .widget__row + .widget__row {
    margin-left: 10px;
  }
  .widget__row__activitydate label {
    flex: none;
  }
}
@media (max-width: 640px), (min-width: 800px) {
  .widget__row__activitydate:not(.activitydate--pickdate) .widget__row__activitydate__dates {
    display: none;
  }
  .widget__row__activitydate__dates > input {
    margin-top: 10px;
    width: 100%;
  }
}
html.page--activitiesoverview .content + .content {
  margin-top: 10px;
}

html.page--activitiesoverview .content__contentcolumn {
  order: 1;
}

/*
desktop
*/
@media screen and (min-width: 800px) {
  .page--activitiesoverview {
    --pagecontent-widgetcolumn-width: 260px;
  }
  .page--activitiesoverview .content__widgetscolumn {
    padding: 0;
    margin-right: var(--pagecontent-columns-gutter);
  }
  .page--activitiesoverview .activitiesoverview,
  .page--activitiesoverview .widget__searchactivity__buttonbar {
    padding-left: 0;
    padding-right: 0;
  }
  .widget__searchactivity__buttonbar {
    width: 100%;
  }
}
.widget__searchactivity__buttonbar {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 10px;
}

.widget__searchactivity__buttonbar > .ctabutton {
  width: auto;
}

.widget__searchactivity__buttonbar > .ctabutton + .ctabutton {
  margin-left: 20px;
}

.activity__description .ctabutton,
.activity__specs .ctabutton {
  margin-top: 15px;
}

.activityheader {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 75px;
  padding: 20px 0 10px;
}

.activityheader__symbol {
  text-align: center;
  width: var(--activityheader-symbol-width);
  flex-shrink: 0;
  color: var(--color-green-large);
}

.activityheader__symbol__icon {
  font-size: 32px;
}

.activityheader__symbol > img {
  max-width: 47px;
}

.activityheader__title,
.activitiesoverview__ch_activity {
  flex-grow: 1;
  flex-shrink: 1;
  padding-right: 20px;
}

.activityheader__toggler {
  position: absolute;
  top: 50%;
  margin-top: -11px;
  right: 15px;
  flex-shrink: 0;
}

.activity__body {
  display: flex;
}

/****/
.activity {
  box-shadow: 0 0.5px 3px rgba(0, 0, 0, 0.4);
  border-radius: var(--activity-radius);
  background-color: #FFFFFF;
  border: var(--activity-border);
}

.activity.doesnotmatch {
  display: none;
}

.activity {
  margin-bottom: 10px;
}

details.activity {
  /* Hide default arrow browsers show on <summary>

     https://chromestatus.com/feature/6730096436051968 - Notice on <summary> now having display: list-item; in Chrome (and therefore list-style-type)
  */
  /* Safari & Chrome <89 (jan 2021) */
  /* Firefox and Chrome 90+ (2021) */
  /* Firefox and Chrome */
}
details.activity summary {
  cursor: pointer;
}
details.activity summary::-webkit-details-marker {
  display: none;
}
details.activity summary {
  list-style-type: none;
}
details.activity summary::marker {
  content: "";
}
details.activity summary:focus-visible {
  outline: 3px solid var(--color-green);
  box-shadow: 0 0 8px rgb(0, 0, 0);
  border-radius: 2px;
}

details.activity[open] summary:focus-visible {
  outline: 3px solid var(--color-magenta);
  outline-offset: 3px;
  box-shadow: none;
  border-radius: 1px;
}

.activity__specs table {
  border: 0;
  border-collapse: collapse;
  margin: 0;
}

.activity__specs th {
  padding: 0;
  font-weight: bold;
  text-align: right;
  padding-right: 12px;
}

.activity__specs td {
  padding: 0;
}

.activity__specs__duration {
  display: none;
}

@media print {
  .activity {
    font-size: 13px;
    -webkit-box-shadow: none !important;
  }
  .activity__specs {
    font-size: 12px;
  }
  .activity__title {
    color: #000000 !important;
  }
  .activityheader__targetaudience {
    display: none !important;
  }
  .activityheader__duration {
    display: none !important;
  }
  .activity__description.widget--padding {
    padding: 13px 18px 18px 20px;
  }
  .activity__specs__duration {
    display: table-row;
  }
}
@media (max-width: 799px) {
  .activitiesoverview {
    margin-top: 30px;
  }
}
@media (max-width: 640px), print {
  .activitiesoverview__header,
  .activitiesoverview__columnheaders {
    display: none;
  }
  .activity__body {
    flex-direction: column;
  }
  .activityheader {
    flex-wrap: wrap;
  }
  .activityheader__title {
    /* eat up all horizontal space to force the targetaudience and duration to the next line */
    flex-basis: calc(100% - 70px);
    padding-right: 40px;
  }
  .activityheader__targetaudience {
    margin-top: 10px;
    padding-left: 70px;
    width: 50%;
  }
  .activityheader__duration {
    margin-top: 10px;
    width: 50%;
  }
  .activity__description {
    padding-bottom: 0;
  }
  .activity__specs {
    padding-top: 0;
  }
  .activity__specs th {
    text-align: left;
  }
  .activity__description .ctabutton {
    display: none;
  }
}
@media screen and (min-width: 641px) {
  .activitiesoverview__columnheaders {
    position: relative;
    display: flex;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .activityheader__targetaudience,
  .activitiesoverview__ch_targetaudience {
    /* fix to 112 px */
    width: 112px;
    flex-shrink: 0;
  }
  .activityheader__duration,
  .activitiesoverview__ch_duration {
    width: 177px;
    flex-shrink: 0;
  }
  .activity__description {
    flex-grow: 1;
    flex-shrink: 1;
  }
  .activity__specs {
    flex-shrink: 0;
    width: 289px; /* 112px + 177px */
    padding-left: 0;
  }
  .activity__specs .ctabutton {
    display: none;
  }
}
.widget__searchactivity {
  background-color: var(--color-purple);
  color: #FFFFFF;
}

.widget__searchactivity.widget--padding {
  padding: 16px 12px 18px 12px; /* 16 to compensate for line-height at the top */
}

.widget__searchactivity .widget__row > label {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 4px;
}

.widget__row__activitydate__dates > .widget__row {
  margin-top: 10px;
  display: flex;
  align-items: baseline;
}

.widget__row__activitydate__dates > .widget__row > label {
  flex: none;
  font-weight: normal;
  margin-right: 10px;
}

.widget__row__activitydate__dates > .widget__row.widget__row > input {
  min-width: 0;
  flex: auto;
}

.activityheader__symbol__imgwhite {
  display: none;
}

.activity:active,
.activity:hover,
.activity[open] {
  border-color: var(--color-magenta);
}
.activity:active .activityheader, .activity:active .activity__symbol,
.activity:hover .activityheader,
.activity:hover .activity__symbol,
.activity[open] .activityheader,
.activity[open] .activity__symbol {
  background-color: var(--color-magenta);
  color: #FFFFFF;
}
.activity:active .activityheader__symbol__imgwhite,
.activity:hover .activityheader__symbol__imgwhite,
.activity[open] .activityheader__symbol__imgwhite {
  display: inline-block;
}
.activity:active .activityheader__symbol__img,
.activity:hover .activityheader__symbol__img,
.activity[open] .activityheader__symbol__img {
  display: none;
}
.activity:active .activity__title, .activity:active .activity__subtitle,
.activity:active .activityheader__symbol__icon,
.activity:active .comp-toggle::before,
.activity:hover .activity__title,
.activity:hover .activity__subtitle,
.activity:hover .activityheader__symbol__icon,
.activity:hover .comp-toggle::before,
.activity[open] .activity__title,
.activity[open] .activity__subtitle,
.activity[open] .activityheader__symbol__icon,
.activity[open] .comp-toggle::before {
  color: #FFFFFF;
}

.activity[open] .comp-toggle::before {
  content: var(--fa-minus-square);
}

/****/
@media print {
  .content__widgetscolumn {
    display: none;
  }
  .activity {
    display: inline-block;
    width: 100%;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    position: relative;
    /*
    page-break-before: avoid !important;
    page-break-inside: avoid !important;
    page-break-after: avoid !important;
    */
  }
  .activityheader__toggler {
    display: none;
  }
  .activity__body {
    display: flex !important;
  }
  .activity__body .ctabutton {
    display: none;
  }
}
.content__widgetscolumn__ontop > .widget + .widget,
.widget--widgetsgroup + .widget--widgetsgroup {
  margin-top: 15px;
}

.widget__row__activitydate__explainwvhg,
.widget__row__activitydate__explaincustomdate {
  display: none;
}

.widget__row__activitydate__explainwvhg.show,
.widget__row__activitydate__explaincustomdate.show {
  display: block;
  margin-bottom: 25px;
}