/*
By using grid:
- we don't get the rounding issue with Flex and calc(100%/x) in IE10/11/Edge
- we don't need the negative margins hack which requires an extra wrapper with overflow: hidden;
- However, for IE11 for now whe'll need extra CSS for autopositioning
*/


@media (max-width: 599px)
{
  .widget--ctablocks > * + *
  {
    margin-top: 15px;
  }
}

@media (min-width: 600px)
{
  /* Modern browsers */
  .widget--ctablocks
  {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 15px;
    row-gap: 20px;
  }
}

.widget--ctablocks .widget--image__title
{
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget--ctablocks .widget--image__title::before
{
  /*
  \00a0 non breakable space
  \200b zero width space
  \A newline
  */
  display: inline-block;
  content: "\200b\A\200b";
  white-space: pre;
  vertical-align: top;
}
